<!--  -->
<template>
  <div ref="myDiv">
    <div class="tabtop" v-if="screenWidth >= 801">

      <el-carousel trigger="click" height="500px" :autoplay="false">
        <el-carousel-item v-for="item in banner1" :key="item">
          <img :src="item.image_text" class="tabbg" alt="">
        </el-carousel-item>
      </el-carousel>


      <div class="searchbox">
        <div class="searchbox1">
          <!-- <div class="searchtabs flex1">
            <div :class="tabtype1 == index ? 'searchtab blue' : 'searchtab write'" v-for="(item, index) in title1"
              :key="index" @click="tabclick1(item, index)">
              {{ $i18n.locale == 'en' ? item.en_name : item.name }}

            </div>

          </div> -->
          <div class="searchtabs flex1" v-if="sell_type == 1">
            <div :class="tabtype == index ? 'searchtab blue' : 'searchtab write'" v-for="(item, index) in title"
              :key="index" @click="tabclick(item, index)">
              {{ $i18n.locale == 'en' ? item.en_name : item.name }}


            </div>

          </div>
          <div class="searchtabs flex1" v-else>
            <div :class="tabtype0 == index ? 'searchtab blue' : 'searchtab write'" v-for="(item, index) in title0"
              :key="index" @click="tabclick0(item, index)">
              {{ $i18n.locale == 'en' ? item.en_name : item.name }}


            </div>

          </div>
          <div class="searchform">
            <el-form :inline="true" :model="formInline" class="searchtop demo-form-inline mtb20 flex-box">
              <GmapAutocomplete class="input-with-select demo-form-inline " @place_changed="setPlace"
                :placeholder="$t('Form.Search_location')"
                style="width:80%;height:42px;padding:0 10px;border: none; font-size: 15px;border-radius: 5px;" />
              <el-select class="" style="padding:0 " v-model="formInline.Distance" :placeholder="$t('Form.Distance')">
                <el-option :label="$i18n.locale == 'en' ? item.en_distance + 'miles' : item.distance + 'miles'"
                  v-for="item in Distance" :key="item.id" :value="item.id"></el-option>
              </el-select>

            </el-form>

            <div v-if="sell_type == 1">
              <el-form :inline="true" :model="formInline" class="demo-form-inline searchselectflex">

                <el-form-item class="searchselectform">
                  <el-select class="searchselect" v-model="formInline.Type" :placeholder="$t('Form.Type')">
                    <el-option :label="$i18n.locale == 'en' ? item.en_name : item.name" v-for="item in Type"
                      :key="item.id" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="searchselectform">
                  <el-select class="searchselect" v-model="formInline.Min_bedrooms"
                    :placeholder="$t('Form.Min_bedrooms')">
                    <el-option :label="$i18n.locale == 'en' ? item.en_name : item.name" v-for="item in Min_bedrooms"
                      :key="item.id" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="searchselectform">
                  <el-input class="searchselect" v-model="formInline.Min_Price" type='number' prefix-icon="el-icon-edit"
                    :placeholder="$t('Form.Min_Price')"></el-input>

                </el-form-item>
                <!-- <el-form-item v-else>
                <el-input class="searchselect" v-model="formInline.min_week_rent" type='number'
                  :placeholder="$t('Form.min_week_rent')"></el-input>

              </el-form-item> -->
                <el-form-item class="searchselectform">
                  <el-input class="searchselect" v-model="formInline.Max_Price" type='number' prefix-icon="el-icon-edit"
                    :placeholder="$t('Form.Max_Price')"></el-input>

                </el-form-item>
                <!-- <el-form-item v-else>
                <el-input class="searchselect" v-model="formInline.max_week_rent" type='number'
                  :placeholder="$t('Form.max_week_rent')"></el-input>

              </el-form-item> -->


              </el-form>
            </div>
            <div v-else>

              <el-form :inline="true" :model="formInline" class="demo-form-inline searchselectflex">

                <el-form-item class="searchselectform">
                  <el-input class="searchselect" v-model="formInline.min_week_rent" type='number'
                    prefix-icon="el-icon-edit" :placeholder="$t('Form.min_week_rent')"></el-input>

                </el-form-item>

                <el-form-item class="searchselectform">
                  <el-input class="searchselect" v-model="formInline.max_week_rent" type='number'
                    prefix-icon="el-icon-edit" :placeholder="$t('Form.max_week_rent')"></el-input>

                </el-form-item>
                <el-form-item class="searchselectform">
                  <el-input class="searchselect" v-model="formInline.min_month_rent" type='number'
                    prefix-icon="el-icon-edit" :placeholder="$t('Form.min_month_rent')"></el-input>

                </el-form-item>
                <el-form-item class="searchselectform">
                  <el-input class="searchselect" v-model="formInline.max_month_rent" type='number'
                    prefix-icon="el-icon-edit" :placeholder="$t('Form.max_month_rent')"></el-input>

                </el-form-item>




              </el-form>
              <el-form :inline="true" :model="formInline" class="demo-form-inline searchselectflex">

                <el-form-item class="searchselectform">
                  <el-select class="searchselect" v-model="formInline.Type" :placeholder="$t('Form.Type')">
                    <el-option :label="$i18n.locale == 'en' ? item.en_name : item.name" v-for="item in Type"
                      :key="item.id" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="searchselectform">
                  <el-select class="searchselect" v-model="formInline.Min_bedrooms"
                    :placeholder="$t('Form.Min_bedrooms')">
                    <el-option :label="$i18n.locale == 'en' ? item.en_name : item.name" v-for="item in Min_bedrooms"
                      :key="item.id" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="searchselectform">
                  <el-date-picker class="mb20  searchselect" v-model="formInline.min_available_date"
                    value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date"
                    :placeholder="$t('Form.min_available_date')">
                  </el-date-picker>

                </el-form-item>
                <el-form-item class="searchselectform">
                  <el-date-picker class="mb20 searchselect" v-model="formInline.max_available_date"
                    value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date"
                    :placeholder="$t('Form.max_available_date')">
                  </el-date-picker>


                </el-form-item>



              </el-form>
            </div>




            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-checkbox v-model="formInline.is_sell" @change='getis_sell'>{{ $t('Form.Exclude_sold_properties')
                }}</el-checkbox>

            </el-form>
            <div class="searchbtns" @click="search">
              <el-button class="searchbtn" type="primary">{{ $t('Form.SEARCH_PROPERTIES') }}</el-button>

            </div>




          </div>

        </div>

      </div>
    </div>
    <div :class="sell_type == 1 ? 'tabtop0' : 'tabtop01'" v-else>
      <el-carousel trigger="click" height="580px" :autoplay="false">
        <el-carousel-item v-for="item in banner2" :key="item">
          <img :src="item.image_text" class="tabbg" alt="">
        </el-carousel-item>
      </el-carousel>

      <!-- <img :src="banner2[0].image_text" class="tabbg" alt=""> -->
      <div class="ydsearchbox">

        <div class="searchbox1">
          <!-- <div class="searchtabs flex1">
            <div :class="tabtype1 == index ? 'searchtab blue' : 'searchtab write'" v-for="(item, index) in title1"
              :key="index" @click="tabclick1(item, index)">
              {{ $i18n.locale == 'en' ? item.en_name : item.name }}
            </div>

          </div> -->
          <div class="searchtabs flex1" v-if="tabtype1 == 0">
            <div :class="tabtype == index ? 'searchtab blue' : 'searchtab write'" v-for="(item, index) in title"
              :key="index" @click="tabclick(item, index)">
              {{ $i18n.locale == 'en' ? item.en_name : item.name }}



            </div>

          </div>
          <div class="searchtabs flex1" v-else>
            <div :class="tabtype0 == index ? 'searchtab blue' : 'searchtab write'" v-for="(item, index) in title0"
              :key="index" @click="tabclick0(item, index)">
              {{ $i18n.locale == 'en' ? item.en_name : item.name }}



            </div>

          </div>
          <div class="searchform">
            <el-form :inline="true" :model="formInline" class="searchtop demo-form-inline mtb20 flex-box">
              <GmapAutocomplete class="input-with-select demo-form-inline " @place_changed="setPlace"
                :placeholder="$t('Form.Search_location')"
                style="width:80%;height:42px;padding:0 10px;border: none; font-size: 15px;border-radius: 5px;" />
              <el-select class="" style="padding:0 " v-model="formInline.Distance" :placeholder="$t('Form.Distance')">
                <el-option :label="$i18n.locale == 'en' ? item.en_distance + 'miles' : item.distance + 'miles'"
                  v-for="item in Distance" :key="item.id" :value="item.id"></el-option>
              </el-select>

            </el-form>

            <div class="mtb20" v-if="sell_type == 1">
              <div class="prices">
                <div class="price1">£{{ formInline.MinMax_Price[0] }}</div>

                <div class="price2">{{ $t('Any_price') }}</div>
                <div class="price1">£{{ formInline.MinMax_Price[1] }}</div>
              </div>

              <el-slider v-model="formInline.MinMax_Price" range :max="10000" @change="change0">
              </el-slider>
            </div>
            <div class="mtb20" v-else>
              <div class="prices">
                <div class="price1">£{{ formInline.MinMax_Price1[0] }}</div>

                <div class="price2">{{ $t('week_price') }}</div>
                <div class="price1">£{{ formInline.MinMax_Price1[1] }}</div>
              </div>

              <el-slider v-model="formInline.MinMax_Price" range :max="10000" @change="change1">
              </el-slider>
            </div>
            <div class="mtb20" v-if="sell_type != 1">
              <div class="prices">
                <div class="price1">£{{ formInline.MinMax_Price2[0] }}</div>

                <div class="price2">{{ $t('month_price') }}</div>
                <div class="price1">£{{ formInline.MinMax_Price2[1] }}</div>
              </div>

              <el-slider v-model="formInline.MinMax_Price1" range :max="10000" @change="change2">
              </el-slider>
            </div>





            <div class="block ydsearchboxflex flex-box" v-if="sell_type != 1">

              <el-date-picker class="mb20 mr20 ydsearchboxform" v-model="formInline.min_available_date"
                value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date" :placeholder="$t('Form.min_available_date')">
              </el-date-picker>
              <el-date-picker class="mb20 ydsearchboxform" v-model="formInline.max_available_date"
                value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date" :placeholder="$t('Form.max_available_date')">
              </el-date-picker>
            </div>



            <el-form :inline="true" :model="formInline" class="demo-form-inline ydsearchboxflex">

              <el-form-item class="ydsearchboxform">
                <el-select class="searchselect" v-model="formInline.Type" :placeholder="$t('Form.Type')">
                  <el-option :label="$i18n.locale == 'en' ? item.en_name : item.name" v-for="item in Type"
                    :key="item.id" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="ydsearchboxform">
                <el-select class="searchselect" v-model="formInline.Min_bedrooms"
                  :placeholder="$t('Form.Min_bedrooms')">
                  <el-option :label="$i18n.locale == 'en' ? item.en_name : item.name" v-for="item in Min_bedrooms"
                    :key="item.id" :value="item.id"></el-option>

                </el-select>
              </el-form-item>


            </el-form>


            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-checkbox v-model="formInline.is_sell" @change='getis_sell'>{{ $t('Form.Exclude_sold_properties')
                }}</el-checkbox>

            </el-form>
            <div class="searchbtns" @click="search">
              <el-button class="searchbtn" type="primary">{{ $t('Form.SEARCH_PROPERTIES') }}</el-button>

            </div>




          </div>

        </div>
      </div>

    </div>
    <div>
      <div class=" xuanze">
        <div class="texttitle mb30">
          {{ $t('LATEST_PROPERTIES') }}
        </div>
        <!-- <div class="mum mtb50">
        122 {{ $t('PROPERTIES_FOR_SALE') }}
      </div> -->
        <div class="shaixuan flex_box">

          <div class="bottons flex1">
            <div class="botton1 flex1" @click="seave()" v-if="formInline.is_collect == 0">
              <img src="../static/index/like0.png" alt="" class="w24">
              {{ $t('Save_Search') }}
            </div>
            <div class="botton2 flex1" @click="seave()" v-else>
              <img src="../static/index/like1.png" alt="" class="w24">
              {{ $t('Save_Search') }}
            </div>
            <div class="botton1 flex1" @click="share()">
              <img src="../static/index/location.png" alt="" class="w24">

              {{ $t('Map_Search') }}
            </div>
          </div>
          <div class="bottons1">
            <el-select class="searchselect00" v-model="formInline.pricetype" slot="prepend"
              :placeholder="$t('Price_low_high')" @change="change">

              <el-option :label="$i18n.locale == 'en' ? item.en : item.zh" v-for="item in Price_low_high" :key="item.id"
                :value="item.id"></el-option>

            </el-select>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import SeverePageVue from './SeverePage.vue'
import { condition, banner, imgText } from '../request/api'
export default {

  props: {
    type: Number,
  },

  data() {
    return {
      screenWidth: document.body.clientWidth,//初始化宽度
      formInline: {
        name: '',
        Search_location: '',
        Type: '',
        Min_bedrooms: '',
        Min_Price: '',

        MinMax_Price: [0, 10000],
        MinMax_Price1: [0, 10000],
        MinMax_Price2: [0, 10000],

        Max_Price: '',

        min_week_rent: "", //周租金最低金额
        max_week_rent: "", //周租金最高金额
        min_month_rent: "", //月租金最低金额
        max_month_rent: "", //月租金最高金额
        min_available_date: "", //最小入驻日期
        max_available_date: "",//最大入驻日期
        Exclude_sold_properties: '',
        SEARCH_PROPERTIES: '',
        Distance: '',
        pricetype: '',
        location: { lat: 0, lng: 0 },
        house_type_key: '',
        sell_type: 1, //售卖分类ID
        rent_type_id: "", //租房分类id
        radio: 1,
        is_sell: '',
        is_collect: 0
      },
      title1: [],
      title: [],
      title0: [],
      Distance: [],
      Type: [],

      Price_low_high: [],
      houseid: 0,
      tabtype0: -1,
      tabtype: -1,
      tabtype1: -1,
      list: [],
      radio: 0,
      severlist: [
        {
          image: 'https://bucket-1321254592.cos.ap-beijing.myqcloud.com/uploads/20240502/19876addbc9ead5ef60e5588e0556707.jpeg',
          title1: 'Trust in the value of experience',
          title2: 'Established in 1817, White & Sons are award winning independent Estate Agents and Property Consultants with offices in Dorking, Reigate, Horley, Oxted & Leatherhead. We are a forward thinking business which uses cutting edge technology backed by 200 years of experience to assist with your property related matters.',
          title3: 'SERVICES WE OFFER',

        },
        {
          image: 'https://bucket-1321254592.cos.ap-beijing.myqcloud.com/uploads/20240502/19876addbc9ead5ef60e5588e0556707.jpeg',
          title1: 'Safest pair of hands in Surrey',
          title2: 'We are experts at managing the sales process to ensure a successful completion and our team all live in the local area. When you work with us you are backed by a team of highly skilled market leading agents. We offer a complete range of property services and have chartered planning consultants and surveyors under one roof.',
          title3: 'READ OUR REVIEWS',

        },
        {
          image: 'https://bucket-1321254592.cos.ap-beijing.myqcloud.com/uploads/20240502/19876addbc9ead5ef60e5588e0556707.jpeg',
          title1: 'Trust in the value of experience',
          title2: 'Established in 1817, White & Sons are award winning independent Estate Agents and Property Consultants with offices in Dorking, Reigate, Horley, Oxted & Leatherhead. We are a forward thinking business which uses cutting edge technology backed by 200 years of experience to assist with your property related matters.',
          title3: 'SERVICES WE OFFER',

        },
        {
          image: 'https://bucket-1321254592.cos.ap-beijing.myqcloud.com/uploads/20240502/19876addbc9ead5ef60e5588e0556707.jpeg',
          title1: 'Trust in the value of experience',
          title2: 'Established in 1817, White & Sons are award winning independent Estate Agents and Property Consultants with offices in Dorking, Reigate, Horley, Oxted & Leatherhead. We are a forward thinking business which uses cutting edge technology backed by 200 years of experience to assist with your property related matters.',
          title3: 'SERVICES WE OFFER',

        },

      ],
      center: { lat: 10.0, lng: 10.0 }, // 中心位置
      center_: { lat: 10.0, lng: 10.0 }, // 保存当前点位置
      currentPlace: null,
      markers: [],
      places: [],
      placeName: "",
      dialogVisible: true,
      googlemap: "",
      hasSetPin: false,
      icon: '',
      zoom: 1,
      banner1: [{
        image_text: 'https://nelsons.buzzegg.cn/uploads/20240607/aae1b14215292967bfd6ef3a6bd9e338.png'
      }],
      banner2: [{
        image_text: 'https://nelsons.buzzegg.cn/uploads/20240607/aae1b14215292967bfd6ef3a6bd9e338.png'
      }],
      our_service: {},
      contact_us: {},
      about_us: {},
      our_team: {},
      join_team: {},
      tenement: {},

      buy_house: {},
      rent_house: {},
      flat: {},
      fitment: {},
      sell_house: {},
      loan: {},
      team_member: {},
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      this.screenWidth = val
      this.timer = true
    },
    'formInline.pricetype': {
      handler: function (newValue, oldValue) {
        // 当nestedData或其内部属性变化时，会调用这个函数
        console.log('oldValue', oldValue)
        console.log('newValue', newValue)
        this.$store.commit('updateformInline', this.formInline)
      },
      deep: true // 开启深度监听
    }

  }
  ,
  async created() {
    this.getdata()
    this.geolocate()
    this.getcondition()
    this.initMap()
    this.getbanner1()
    this.getbanner2()
    this.getimgtext()
    if (this.$route.path == '/TabTwoVue') {
      this.sell_type = 1
    } else {
      this.sell_type = 2
    }
    // console.log(this.list,'haaaaaaaaaaaaaaaaaaaaaaaaaaaa')




  },
  methods: {
    share() {
      console.log('this.list', this.list)
      this.$router.push({ path: '/GoogleMapVue' });
      document.documentElement.scrollTop = 0
      // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
      document.body.scrollTop = 0
      //这是关键代码
      // 在组件中
      // this.$router.push({
      //   name: 'GoogleMap',
      //   query: {
      //     arrayParam: this.list.join(',') // 将数组转换为字符串
      //   }
      // });
    },
    seave() {
      if (this.formInline.is_collect) {
        this.formInline.is_collect = 0
      }
      else {
        this.formInline.is_collect = 1
      }
      this.$store.commit('updateformInline', this.formInline)
    },
    getis_sell(e) {
      console.log('e', e)
    },
    initMap() {
      var that = this
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            that.location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            console.log('location', that.location)
            //  if(that.formInline.location){
            //   // that.getdata()
            //  }
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },
    change(e) {
      console.log('e', e)
      this.$store.commit('updateformInline', this.formInline)
      console.log('formInline', this.formInline)
    },
    async getbanner1() {
      await banner({
        type: 5,
        device: 1
      }).then(res => {
        console.log('resbannerbannerbanner', res)
        if (res.data.length > 0) {
          this.banner1 = res.data
        }



      })
    },
    async getbanner2() {
      await banner({
        type: 5,
        device: 2
      }).then(res => {
        if (res.data.length > 0) {
          this.banner2 = res.data
        }

      })
    },
    async getcondition() {
      await condition().then(res => {
        // console.log('res', res)
        this.title0 = res.data.rent_type
        this.title = res.data.house_type
        this.title1 = res.data.sell_type
        this.Distance = res.data.house_distance
        this.Type = res.data.house_pattern
        this.Min_bedrooms = res.data.house_size
        this.Price_low_high = res.data.house_order_type


        console.log(res, '登录数据')
        console.log(res, '登录数据')
      })
    },
    async getimgtext() {
      await imgText().then(res => {
        if (res.data) {
          this.our_service = res.data.our_service
          this.contact_us = res.data.contact_us
          this.about_us = res.data.about_us
          this.our_team = res.data.our_team
          this.join_team = res.data.join_team
          this.tenement = res.data.tenement
          this.buy_house = res.data.buy_house
          this.rent_house = res.data.rent_house
          this.flat = res.data.flat
          this.fitment = res.data.fitment
          this.sell_house = res.data.sell_house
          this.loan = res.data.loan
          this.team_member = res.data.team_member



        }

      })
    },
    handleClose() { },
    setPlace(place) {
      console.log('place', place)

      this.currentPlace = place;
      // this.formInline = place.formatted_address
      // console.log('formInline', this.formInline)
      this.formInline.location.lat = this.currentPlace.geometry.location.lat()
      this.formInline.location.lng = this.currentPlace.geometry.location.lng()
      this.addMarkerFun();
    },
    addMarker() {
      this.addMarkerFun();
    },
    addMarkerFun() {
      if (this.currentPlace) {
        this.hasSetPin = true
        this.zoom = 10
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        console.log('marker', marker)
        this.markers = [];
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.center_ = marker;
        this.placeName = this.currentPlace.name;
        this.currentPlace = null;
      }
    },
    change0(e) {
      console.log('e', e)
      this.formInline.Min_Price = e[0]
      this.formInline.Max_Price = e[1]
    },
    change1(e) {
      console.log('e', e)
      this.formInline.min_week_rent = e[0]
      this.formInline.max_week_rent = e[1]
    },
    change2(e) {
      console.log('e', e)
      this.formInline.min_month_rent = e[0]
      this.formInline.max_month_rent = e[1]
    },
    geolocate: function () {
      let vm = this

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          if (position && position.coords && position.coords.latitude) {
            // alert("获取地理位置："+position.coords.latitude+","+position.coords.longitude)
            vm.hasSetPin = true
            vm.zoom = 10
            vm.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            vm.center_ = vm.center
            // vm.markers.push({ position: vm.center });
          }
        }, (error) => {  // html5 默认调用的谷歌的接口，会有安全限制
          switch (error.code) {
            case error.PERMISSION_DENIED: // 许可拒绝,用户选了不允许
              // alert("您拒绝对获取地理位置的请求");
              // alert(error.message);
              break;
            case error.POSITION_UNAVAILABLE: // 连不上GPS卫星，或者网络断了
              // alert("位置信息是不可用的");
              // alert(error.message);
              break;
            case error.TIMEOUT:  // /超时了
              // alert("请求您的地理位置超时");
              // alert(error.message);
              break;
            case error.UNKNOWN_ERROR:
              // alert("未知错误");
              // alert(error.message);
              break;
          }
        });
      } else {
        // alert("navigator.geolocation未获取获取到地理位置");
        // vm.markers.push({ position: vm.center });
      }
    },
    clickMap(e) {
      this.hasSetPin = true
      let longlat = e.latLng.lat() + "," + e.latLng.lng();
      console.log('longlat', longlat)
      this.center_ = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
      this.markers = [];
      this.markers.push({ position: this.center_ });
    },
    clickMarker(val) {
      this.center = val;
    },
    submitMap() {
      if (!this.hasSetPin) {
        this.msgError(this.$t("googlemap.searchAndAdd"));
        return
      }
      let obj = Object.assign({}, this.center_);
      obj.name = `${this.center_.lat.toFixed(5)},${this.center_.lng.toFixed(5)}`;
      this.$emit("setMap", obj);
    },
    cancelMap() {
      this.$emit("closeMap");
    },


    tabclick0(item, index) {
      this.tabtype0 = index
      this.formInline.rent_type_id = item.id
    },
    tabclick(item, index) {
      this.tabtype = index
      this.formInline.house_type_key = item.key
    },
    tabclick1(item, index) {
      this.tabtype1 = index
      console.log('this.tabtype1', this.tabtype1)
      console.log('item', item)
      this.formInline.sell_type = item.id
      console.log('this.formInline.sell_type', this.formInline.sell_type)
      if (this.formInline.sell_type == 1) {
        this.formInline.rent_type_id = ''
      }
      else {
        this.formInline.house_type_key = ''
      }
      this.$store.commit('updateformInline', this.formInline)

      console.log(' this.formInline', this.formInline)

    },
    getHouseId(data) {
      console.log('父组件接收到的数据：' + data)
      this.houseid = data
      // if (this.houseid) {
      //   this.currentComponent = 'HouseDetailVue'
      // }
    },
    search() {
      console.log('formInline', this.formInline)
      this.$store.commit('updateformInline', this.formInline)
      const divHeight = this.$refs.myDiv.offsetHeight;
      console.log('Div的高度是：', divHeight);
      window.scrollTo(0, divHeight);

    },
    async getdata() {
      //   await Gethourselist({
      //     search: {
      //       area: [],
      //       area_id: '',
      //       city_id: '456',
      //       community_id: '',
      //       // community_id: '',
      //       price: [0, 1000000000000000],
      //       week_price: [0, 1000000000000000],
      //       region_id: '',
      //       // region_id: '',
      //       extra: [],
      //       school: '',
      //       commuting: ''
      //     }
      //   }).then(res => {
      //     this.list = res.data.data.data
      //     console.log(this.list)
      //   })

    }

  },

}

</script>
<style scoped>
.blue {
  color: #51B1FF;
}

.write {
  color: #fff;

}









.searchtabs {
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.searchform {
  width: 90%;
  margin: 0 auto;
}

.searchtab {
  padding: 12px;
  cursor: pointer;
}

.searchbtns {
  margin-top: 10px;
  text-align: center;
}

.searchbtn {
  min-width: 100px;
}

.tablist_t {
  margin: 20px 0;

  justify-content: space-between;
}

.w24 {
  width: 24px;
  height: 24px;
}

.botton1 {
  padding: 10px 16px;
  text-align: center;
  margin-right: 20px;
  background: #F5F5F5;
  border-radius: 8px 8px 8px 8px;
  align-items: center;
  font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
  font-weight: 500;
  font-size: 22px;
  color: #111111;
  line-height: 48px;
  font-style: normal;
  text-transform: none;
}

.botton2 {
  padding: 10px 16px;
  border: #51B1FF 1px solid;
  text-align: center;
  margin-right: 20px;
  background: #F5F5F5;
  border-radius: 8px 8px 8px 8px;
  align-items: center;
  font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
  font-weight: 500;
  font-size: 22px;
  color: #111111;
  line-height: 48px;
  font-style: normal;
  text-transform: none;
}


.severbox {
  margin-top: 120px;
}

.w1000 {
  width: 1000px;
  height: 620px;
}

.el-form-item {
  text-align: center;
}

.s_b_r {
  margin: 80px;
}

.sb_r_t {

  font-family: DM Serif Display, DM Serif Display;
  font-weight: 400;
  font-size: 56px;
  color: #111111;
  line-height: 72px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.sb_r_m {
  margin: 20px 0;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #666666;
  line-height: 32px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.sb_r_b {
  /* width: 260px;
  height: 60px; */
  background: #1495FF;

  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 60px;
  text-align: center;

}

.prices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.price1 {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 34px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

@media (max-width: 800px) {

  .tabtop0 {
    height: 650px;
  }

  .tabtop01 {
    height: 750px;
  }


  .tabbg {
    width: 100%;
    height: 450px;
    border-radius: 0px 0px 0px 0px;
    position: absolute;
    left: 0;
    /* top: 100px; */
  }

  .ydsearchboxform {
    width: 95%;
  }

  .ydsearchboxflex {
    display: grid;
    text-align: center;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20rpx;
    grid-column-gap: 20rpx;
  }

  .w1000 {
    width: 90%;
    height: auto;
  }

  .hide-on-mobile {
    display: none;
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .ydsearchbox {
    /* top: 0; */
    background: rgba(0, 0, 0, 0.85);
    margin: 20px;
    top: 100px;
    left: 0;
    position: absolute;
    width: 90%;
    z-index: 100;

  }

  .searchbox1 {
    margin: 24px auto;
    width: 100%;
    text-align: center;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 500;
    font-size: 16px;
    color: #51B1FF;
    line-height: 16px;
    text-align: left;
  }

  .searchinput {
    width: 180px;
  }

  .searchselect0 {
    width: 22%;
  }

  .searchselect00 {
    width: 200px;
  }

  .searchselect {}

  /* 
.searchselect2 {
  width: 130px;
} */
}

/* 针对桌面端的样式 */
@media (min-width: 801px) {
  .tabbg {
    width: 100%;
    height: 550px;
    border-radius: 0px 0px 0px 0px;
    position: absolute;
    left: 0;

  }

  .tabtop {
    position: relative;
    height: 550px;
    /* margin-bottom: 100px; */
  }


  .searchtop {
    width: 98%;
    /* height: 46px; */
    border-radius: 5px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  .xuanze {

    margin: 20px 100px;

  }

  .searchbox1 {
    margin: 24px auto;
    width: 100%;
    text-align: center;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 500;
    font-size: 16px;
    color: #51B1FF;
    line-height: 16px;
    text-align: left;
  }

  .searchbox {
    top: 90px;
    left: 25%;
    right: 50%;
    width: 50%;
    /* height: 379px; */
    background: rgba(0, 0, 0, 0.85);
    margin: 0 auto;
    /* margin-top: -230px; */
    position: absolute;
    z-index: 100;

  }

  .searchinput {
    width: 700px;
  }

  .searchselect0 {
    width: 19%;
    border: none;
    border-left: 1px solid #E0E0E0;
  }

  .searchselectform {
    flex-grow: 1;
    flex: 1;
    /* width: 90%; */
  }

  .searchselect {
    /* width: 200px; */
    width: 100%;
  }

  .searchselectdate {
    width: 100%;

  }


  .searchselectflex {
    display: flex;
    /* text-align: center;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 20px; */
    /* flex: 1; */
    justify-content: space-between;
    width: 100%;

  }

  .searchselect2 {
    width: 220px;
  }


  .name {
    max-height: 60px;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 20px;
    color: #888888;
    /* line-height: 28px; */
    text-align: left;
    font-style: normal;
    text-transform: none;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .texttitle {
    margin: 20px auto;
    width: 217px;
    height: 24px;
    font-family: Montserrat, Montserrat;
    font-weight: 700;
    font-size: 20px;
    color: #111111;
    /* line-height: 24px; */
    text-align: center;
    font-style: normal;
    text-transform: none;
  }

}
</style>
<style scoped>
.more {
  margin: 10px auto;
  width: 262px;
  height: 60px;
  text-align: center;
  border: 1px solid #1495FF;
  font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
  font-weight: 500;
  font-size: 24px;
  color: #1495FF;
  line-height: 60px;
  cursor: pointer;
  /* text-align: left; */
  font-style: normal;
  text-transform: none;
  margin-bottom: 100px;
}

.w24 {
  width: 24px;
  height: 24px;
}

/* 针对移动端的样式 */
@media (max-width: 800px) {
  body {
    /* background-color: lightblue; */
  }

  .bottons {
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .botton1 {
    cursor: pointer;
    padding: 5px;
    text-align: center;
    margin-right: 5px;
    background: #F5F5F5;
    border-radius: 8px 8px 8px 8px;
    align-items: center;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 500;
    font-size: 22px;
    color: #111111;
    line-height: 48px;

    font-style: normal;
    text-transform: none;
  }

  .botton2 {
    /* padding: 10px 16px; */
    border: #51B1FF 1px solid;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    margin-right: 5px;
    background: #F5F5F5;
    border-radius: 8px 8px 8px 8px;
    align-items: center;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 500;
    font-size: 22px;
    color: #111111;
    line-height: 48px;

    font-style: normal;
    text-transform: none;
  }


  .tablist {}

  .listboxs {
    text-align: center;
  }

  .listbox {
    margin: 20px;
    cursor: pointer;
  }

  .hide-on-mobile {
    display: none;
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .mid {
    /* width: 436px; */
    margin: 16px 0;
    align-items: center;
    justify-content: space-between;
  }


  .topimg {
    width: 100%;
    height: auto;
    text-align: center;
    border-radius: 8px 8px 8px 8px;
  }

  .price {
    width: 166px;
    height: 36px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 32px;
    color: #111111;
    /* line-height: 36px; */
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .like {
    width: 28px;
    height: 28px;
  }

  .bottom {
    text-align: left;
    margin-bottom: 20px;
  }

  .name {
    max-height: 60px;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 20px;
    color: #888888;
    /* line-height: 28px; */
    text-align: left;
    font-style: normal;
    text-transform: none;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .texttitle {
    margin: 20px auto;
    width: 217px;
    height: 24px;
    font-family: Montserrat, Montserrat;
    font-weight: 700;
    font-size: 20px;
    color: #111111;
    /* line-height: 24px; */
    text-align: center;
    font-style: normal;
    text-transform: none;
  }

  .shaixuan {
    margin: 20px;
  }
}

/* 针对桌面端的样式 */
@media (min-width: 801px) {
  .shaixuan {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottons {
    width: 50%;
    margin-bottom: 20px;
    /* justify-content: space-between; */
  }

  .bottons1 {
    width: 30%;
    margin-bottom: 20px;
    text-align: right;
    /* justify-content: space-between; */
  }

  .botton1 {
    cursor: pointer;
    padding: 10px 16px;
    text-align: center;
    margin-right: 20px;
    background: #F5F5F5;
    border-radius: 8px 8px 8px 8px;
    align-items: center;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 500;
    font-size: 22px;
    color: #111111;
    line-height: 48px;
    font-style: normal;
    text-transform: none;
  }

  .listbox {
    display: grid;
    width: 100%;
    text-align: center;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
  }

  .listbox11 {
    /* margin: 20px; */
    cursor: pointer;
  }

  .mid {
    width: 100%;
    margin: 16px 0;
    align-items: center;
    justify-content: space-between;
  }

  .top,
  .topimg {
    width: 100%;
    height: 236px;
    border-radius: 3px 3px 3px 3px;
  }

  .price {
    width: 166px;
    height: 36px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 32px;
    color: #111111;
    /* line-height: 36px; */
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .like {
    width: 28px;
    height: 28px;
  }

  .bottom {
    text-align: left;
    margin-bottom: 20px;
  }

  .name {
    max-height: 60px;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 20px;
    color: #888888;
    /* line-height: 28px; */
    text-align: left;
    font-style: normal;
    text-transform: none;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .texttitle {
    margin: 20px auto;
    width: 217px;
    height: 24px;
    font-family: Montserrat, Montserrat;
    font-weight: 700;
    font-size: 20px;
    color: #111111;
    /* line-height: 24px; */
    text-align: center;
    font-style: normal;
    text-transform: none;
  }

}
</style>
