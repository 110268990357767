<template>
    <div>
        <!-- {{ $store.state.typetab }} -->
        <div class="topss" v-if="screenWidth >= 801">
            <div class="top00 top0 flex-box flex-end ">
                <div class="tobtab">

                    <!-- {{ $store.state.typetab }} -->

                    <div class="flex1 tobtab1">
                        <div v-for="(item, index) in $t('Tab1')" :key="index"
                            :class="$store.state.typetab == item ? 'tabbox2 pr30' : 'tabbox1 pr30'"
                            @click="onClick1(index, item)">{{
            item }}
                        </div>
                        <!-- <router-link to="/TabTwo">TabTwo</router-link> -->

                    </div>

                </div>
                <el-select style="margin: 20px 0px;" @change="langChange" :placeholder="$t('placeholder')"
                    v-model="languageVal">
                    <el-option style="height: 30px;line-height: 30px;" v-for="item in options" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <div class="login flex-box" v-if="!$store.state.userinfo.nickname">

                    <el-button type="text" class="tabbox0" @click="dialogFormVisible = true"> {{ $t('login_in')
                        }}</el-button>
                    <el-button type="text" class="tabbox0" @click="dialogFormVisible1 = true"> {{ $t('register_in')
                        }}</el-button>

                </div>

                <div class="login flex-box" v-else>
                    <img :src="$store.state.userinfo.avatar" class="w48" alt="">

                    <div class="tabbox0">{{ $store.state.userinfo.nickname }}</div>

                </div>
            </div>
            <div class="top01">
                <div class="tops">
                    <div class="weblog" @click="shouyepage">
                        <img src="../static/jx.png" class="weblogo" alt="">

                    </div>
                    <div class="tobtab">


                        <!-- <van-tabs v-model="active" @click="onClick" title-active-color="#1495FF " color="#1495FF"
                        title-inactive-color="#111">
                        <van-tab v-for="(item, index) in $t('Tab')" :key="index" :title="item" title-class="tabbox"
                            title-style="ont-weight: 700;text-align: left;font-style: normal;text-transform: none; ">
                        </van-tab>
                      
                    </van-tabs> -->
                        <div class="flex1">
                            <div v-for="(item, index) in $t('Tab')" :key="index"
                                :class="$store.state.typetab == item ? 'tabbox2 pr30' : 'tabbox0 pr30'"
                                @click="onClick1(index, item)">{{
            item }}
                            </div>
                            <!-- <router-link to="/TabTwo">TabTwo</router-link> -->

                        </div>


                    </div>

                    <!-- <el-button type="text" @click="open"> {{ $t('login_in') }}</el-button> -->
                </div>
            </div>




        </div>
        <div class="topss" v-else>
            <div class="top0 ptb10 flex-box flex-between">


                <el-dropdown trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        <img src="../static/index/menu.png" class="w38" alt="">
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item, index) in $t('Tab')" :key="index" :command="item"> <span
                                :class="active == item ? 'activelink' : ''">{{ item
                                }}</span></el-dropdown-item>
                        <el-dropdown-item v-for="(item1, index1) in $t('Tab1')" :key="index1" :command="item1"> <span
                                :class="active == item1 ? 'activelink' : ''">{{ item1
                                }}</span></el-dropdown-item>
                        <el-select style="margin: 20px 0px;padding: 20px;" @change="langChange"
                            :placeholder="$t('placeholder')" v-model="languageVal">
                            <el-option style="height: 30px;line-height: 30px;" v-for="item in options" :key="item.value"
                                :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>

                    </el-dropdown-menu>
                </el-dropdown>

                <img src="../static/jx.png" class="weblogo" alt="">

                <!-- <el-dropdown trigger="hover">
                    <span class="el-dropdown-link">
                        <img src="../static/index/tel.png" class="w38" alt="">
                    </span>
                    <el-dropdown-menu slot="dropdown">

                        <el-dropdown-item>123456789</el-dropdown-item>

                    </el-dropdown-menu>
                </el-dropdown> -->
                <div class="loginbtn ml10" v-if="!$store.state.userinfo.nickname">
                    <el-button type="text" @click="dialogFormVisible = true"> {{ $t('login_in') }}</el-button>
                    <el-button type="text" @click="dialogFormVisible1 = true"> {{ $t('register_in') }}</el-button>
                </div>
                <div class="loginbtn flex-box mr20" v-else>
                    <img :src="$store.state.userinfo.avatar" class="w48" alt="">

                    <div class="tabbox0">{{ $store.state.userinfo.nickname }}</div>

                </div>
            </div>
            <div class="menulist">

            </div>



        </div>

        <el-dialog :title="$t('login_in')" :visible.sync="dialogFormVisible">
            <el-form :model="form">
                <el-form-item :label="$t('email')" :label-width="formLabelWidth">
                    <el-input v-model="form.name" autocomplete="off" class="login_input"></el-input>
                </el-form-item>
                <el-form-item :label="$t('password')" :label-width="formLabelWidth">
                    <el-input v-model="form.password" autocomplete="off" show-password class="login_input"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="showdialogFormVisible1">{{ $t('register_in') }}</el-button>
                <el-button @click="dialogFormVisible = false"> {{ $t('cancel') }}</el-button>
                <el-button type="primary" @click="login"> {{ $t('login_in') }}</el-button>
            </div>
        </el-dialog>
        <el-dialog :title="$t('register_in')" :visible.sync="dialogFormVisible1">
            <el-form :model="form">

                <el-form-item :label="$t('email')" :label-width="formLabelWidth">
                    <el-input v-model="form1.name" autocomplete="off" class="login_input"></el-input>
                </el-form-item>
                <el-form-item :label="$t('tel')" :label-width="formLabelWidth" :inline="true">


                    <el-input :placeholder="$t('tel')" v-model="form1.tel" class="login_input">

                        <el-select v-model="form1.quyu" slot="prepend" class="login_input_select"
                            :placeholder="$t('tel')" @change="quyuChange">
                            <el-option v-for="(value, key, index) in myObject" :key="index" :label="key + value.code"
                                class="login_input" :value="key + value.code"></el-option>

                        </el-select>

                    </el-input>

                </el-form-item>
                <el-form-item :label="$t('password')" :label-width="formLabelWidth">
                    <el-input v-model="form1.password" autocomplete="off" show-password class="login_input"></el-input>
                </el-form-item>
                <el-form-item :label="$t('password1')" :label-width="formLabelWidth">
                    <el-input v-model="form1.password1" autocomplete="off" show-password class="login_input"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="showdialogFormVisible"> {{ $t('login_in') }}</el-button>
                <el-button @click="dialogFormVisible1 = false"> {{ $t('cancel') }}</el-button>
                <el-button type="primary" @click="register">{{ $t('register_in') }}</el-button>

            </div>
        </el-dialog>
    </div>

</template>

<script>
import { login, register } from '../request/api'
import myObject from './countries.json'
export default {
    name: 'HomeVue',
    data() {
        return {
            myObject,
            dialogFormVisible: false,
            form: {
                name: '',
                password: ''
            },
            dialogFormVisible1: false,
            form1: {
                name: '',
                password: '',
                password1: '',
                tel: '',
                quyu: ''
            },
            formLabelWidth: '20%',
            menushow: false,
            screenWidth: document.body.clientWidth,//初始化宽度
            refresh: this.$t('refresh'), // 第三种用法，不过这种用法在切换预言时，需要刷新页面才会生效
            dialogVisible: false,
            languageVal: this.$i18n.locale,
            options: [{
                value: 'cn',
                label: '中文'
            }, {
                value: 'en',
                label: 'English'
            }],
            tabtype: this.$t('Tab')[0],
            activeName: this.$t('Tab')[0],
            active: 0,
            timer: false
        }
    },
    methods: {
        showdialogFormVisible() {
            this.dialogFormVisible = true
            this.dialogFormVisible1 = false

        },
        showdialogFormVisible1() {
            this.dialogFormVisible1 = true
            this.dialogFormVisible = false

        },
        menushowclick() {
            this.menushow = true
        },
        handleCommand(command) {
            console.log(command)
            this.tabtype = command
            this.$emit('TabEvent', this.tabtype)
        },
        quyuChange(e) {
            console.log(e, '区域')
            this.form1.quyu = e
            console.log(' this.form1', this.form1)
        },
        langChange(e) {
            console.log(e, '语言')

            this.$i18n.locale = e;
            localStorage.setItem('lang', e);
            // window.location.reload() // 第三种用法刷新页面
            // this.$router.go(0)
            this.$store.commit('updatelang', e)
            // console.log('this.tabtype', this.tabtype)
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        onClick(name, title) {

            this.tabtype = title

            // this.$emit('TabEvent', this.tabtype)
            this.$store.commit('updatetypetab', title)

        },
        onClick1(index, title) {
            console.log('index', index)
            this.tabtype = title
            console.log('titletitletitletitletitletitletitletitletitletitletitletitletitletitletitletitletitletitletitletitle', title)
            // this.$emit('TabEvent', this.tabtype)

            this.$store.commit('updatetypetab', title)


        },
        async login() {
            this.dialogFormVisible = false

            await login({
                account: this.form.name, //账号
                password: this.form.password//密码
            }).then(res => {
                // console.log('res', res)
                if (res.data) {
                    this.$message({
                        message: 'Congratulations, login successful',
                        type: 'success'
                    });
                    this.$store.commit('updateuserinfo', res.data.userinfo)
                    if (this.$store.state.userinfo) {
                        localStorage.setItem('token', this.$store.state.userinfo.token);
                    }
                    // this.$message({
                    // message: '恭喜你，注册成功',
                    // type: 'success'

                }

            })

        },
        async register() {
            this.dialogFormVisible1 = false
            if (this.form1.password == this.form1.password1) {
                await register({
                    email: this.form1.name, //账号
                    password: this.form1.password, //密码
                    mobile: this.form1.tel, //手机号
                    area_code: this.form1.quyu, //区号

                }).then(res => {
                    console.log('res', res)
                    if (res) {
                        this.$message({
                            message: 'Congratulations, registration successful',
                            type: 'success'
                        });
                        this.dialogFormVisible = true
                     
                    }


                })
            }
            else {
                this.$message({
                    message: 'Inconsistent passwords before and after',
                    type: 'error'
                });
            }

        },
        shouyepage() {
            this.$router.push({ path: '/' });
            this.$store.commit('updatetypetab', this.$t('Tab')[0])
        },

        open() {
            this.$alert('<strong>这是 <i>HTML</i> 片段</strong>', 'HTML 片段', {
                dangerouslyUseHTMLString: true
            });
            // this.$prompt('请输入邮箱', '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
            //     inputErrorMessage: '邮箱格式不正确'
            // }).then(({ value }) => {
            //     this.$message({
            //         type: 'success',
            //         message: '你的邮箱是: ' + value
            //     });
            // }).catch(() => {
            //     this.$message({
            //         type: 'info',
            //         message: '取消输入'
            //     });
            // });
            // this.$prompt('请输入邮箱', '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
            //     inputErrorMessage: '邮箱格式不正确'
            // }).then(({ value }) => {
            //     this.$message({
            //         type: 'success',
            //         message: '你的邮箱是: ' + value
            //     });
            // }).catch(() => {
            //     this.$message({
            //         type: 'info',
            //         message: '取消输入'
            //     });
            // });

        }

    },
    watch: {
        '$route': {
            handler: function (to, from) {
                // 路由切换时会触发这个方法
                // to 和 from 分别代表切换后和切换前的路由对象
                console.log('Route changed from', from.name, 'to', to.name);
                var oldVal = to.name
                console.log(typeof oldVal, oldVal)
                if (oldVal == 'TabTwoVue') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab')[1])

                }
                if (oldVal == 'TabThreeVue') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab')[2])

                }
                if (oldVal == 'TabFour') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab')[3])

                }
                if (oldVal == 'TabFive') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab')[4])

                }
                if (oldVal == 'TabSix') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab')[5])

                }
                if (oldVal == 'TabSeven') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab')[6])

                }
                if (oldVal == 'TabEight') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab1')[0])

                }
                if (oldVal == 'TabNine') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab1')[1])

                }
                if (oldVal == 'TabTen') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab1')[2])

                } if (oldVal == 'TabEleven') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab1')[3])

                }

                console.log(this.tabtype, ' this.tabtype  this.tabtype  this.tabtype  this.tabtype ')
                // 在这里可以根据路由变化执行相应的逻辑
            },
            // 如果需要深度监听路由对象中的所有属性，可以设置deep: true
            deep: true,
            immediate: true,
        },
        lang: {
            handler: function (to, from) {
                // 路由切换时会触发这个方法
                // to 和 from 分别代表切换后和切换前的路由对象
                console.log('Route changed from', from, 'to', to);
                var oldVal = this.$route.name;
                console.log(typeof oldVal, oldVal)
                if (oldVal == 'TabTwoVue') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab')[1])

                }
                if (oldVal == 'TabThreeVue') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab')[2])

                }
                if (oldVal == 'TabFour') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab')[3])

                }
                if (oldVal == 'TabFive') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab')[4])

                }
                if (oldVal == 'TabSix') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab')[5])

                }
                if (oldVal == 'TabSeven') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab')[6])

                }
                if (oldVal == 'TabEight') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab1')[0])

                }
                if (oldVal == 'TabNine') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab1')[1])

                }
                if (oldVal == 'TabTen') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab1')[2])

                } if (oldVal == 'TabEleven') {
                    console.log("haaaaaaaaaaaaaaaaaaaaaa")
                    // this.currentPath = this.$t('Tab')[1]
                    this.$store.commit('updatetypetab', this.$t('Tab1')[3])

                }

                console.log(this.tabtype, ' this.tabtype  this.tabtype  this.tabtype  this.tabtype ')
                // 在这里可以根据路由变化执行相应的逻辑
            },
            // 如果需要深度监听路由对象中的所有属性，可以设置deep: true
            deep: true,
            immediate: true,
        },
        screenWidth(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            this.screenWidth = val
            this.timer = true
        }
    },
    computed: {
        lang() {
            return this.$i18n.locale;
        },
        Path() {
            return this.$route.path // 获取当前路由路径
        },

        getuserinfo() {
            return this.$store.state.userinfo;
        },

    },

    created() {
        console.log(this.tabtype, ' this.tabtype this.tabtype this.tabtype')
        console.log(this.$store.state.typetab, ' this.tabtype this.tabtype this.tabtype')
        console.log(this.$store.state.userinfo, 'userinfouserinfouserinfouserinfouserinfouserinfouserinfouserinfouserinfouserinfouserinfouserinfouserinfouserinfouserinfo')
        if (this.$store.state.userinfo) {
            localStorage.setItem('token', this.$store.state.userinfo.token);
        }
        // this.$emit('TabEvent', this.tabtype)

    },
    mounted() {
        const that = this
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                that.screenWidth = window.screenWidth
            })()
        }
    },


}
</script>
<style scoped>
.login {
    cursor: pointer;
    margin-left: 40px;
}

.login_input {
    width: 70%;
}

.login_input1 {
    width: 30%;
}

.w48 {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    border-radius: 50%;
}

/* 针对移动端的样式 */
@media (max-width: 800px) {
    body {
        font-size: 12px !important;
        /* background-color: lightblue; */
    }

    ::v-deep .el-dialog {
        width: 80% !important;
    }

    .w1000 {
        width: 90%;
        height: auto;
    }

    .hide-on-mobile {
        display: none;
    }

    .container {
        display: flex;
        flex-direction: column;
    }

    .top00 {
        width: 100%;
    }

    .top0 {
        width: 100%;
        /* padding-right: 280px; */
        /* font-family: Montserrat, Montserrat; */
        font-weight: 400;
        font-size: 16px;
        color: #666666;
        line-height: 20px;
        text-align: left;

    }

    .weblogo {
        width: 180px;
        height: 26px;
        border-radius: 0px 0px 0px 0px;
    }
}

/* 针对桌面端的样式 */
@media (min-width: 801px) {
    .tabbox0 {
        cursor: pointer;
        /* font-family: Montserrat, Montserrat; */
        font-weight: 500;
        font-size: 16px;
        color: #111111;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .login_input_select {
        width: 150px;
    }

    .tabbox1 {
        cursor: pointer;
        /* font-family: Montserrat, Montserrat; */
        font-weight: 500;
        font-size: 16px;
        color: #666666;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .tabbox2 {
        cursor: pointer;
        /* font-family: Montserrat, Montserrat; */
        font-weight: 500;
        font-size: 16px;
        color: #0084FF;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .activelink {

        /* font-family: Montserrat, Montserrat; */
        font-weight: 600;
        font-size: 36px;
        color: #1495FF;

        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .top01 {
        /* width: 100%; */
        margin: 10px 10vw;
    }

    .tops {
        display: flex;
        width: 100%;
        /* width: 1920px;
        height: 80px; */
        background: #FFFFFF;

        align-items: center;
        margin-top: 0;
        justify-content: space-between;
    }

    .weblog {
        cursor: pointer;
    }

    .van-tab {
        width: 200px !important;

    }

    .tobtab {
        margin-left: 20px;
        flex-wrap: wrap;
        /* width: 1000px; */
    }

    .tobtab1 {}

    ::v-deep .el-input__inner {
        height: 30px;
    }


    .weblogo {
        width: 180px;
        height: 26px;
        border-radius: 0px 0px 0px 0px;
    }

    .top0 {
        width:95%;
        /* padding-right: 280px; */
        /* font-family: Montserrat, Montserrat; */
        font-weight: 400;
        font-size: 16px;
        color: #666666;
        line-height: 20px;
        text-align: left;

    }
}
</style>
