<template>
  <div id="app" :style="{'font-family': fontfamily,'width': maxScreenWidth+'px'}">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- {{maxScreenWidth  }} -->
    <div class="top" :style="{'width': maxScreenWidth+'px'}">
      <TopVue @TabEvent="TabType"></TopVue>
    </div>
    <!-- <div class="min" style="min-height:80vh">
      <div class="minbox" v-if="typename == $t('Tab')[0]">
        <TabOneVue></TabOneVue>
      </div>
      <div class="minbox" v-if="typename == $t('Tab')[1]">
        <TabTwoVue></TabTwoVue>
      </div>
      <div class="minbox" v-if="typename == $t('Tab')[2]">
        <TabThreeVue></TabThreeVue>
      </div>
      <div class="minbox" v-if="typename == $t('Tab')[3]">
        <TabFourVue></TabFourVue>
      </div>
      <div class="minbox" v-if="typename == $t('Tab')[4]">
        <TabFiveVue></TabFiveVue>
      </div>
      <div class="minbox" v-if="typename == $t('Tab')[5]">
        <TabSixVue></TabSixVue>
      </div>
      
 
    </div> -->
    <!-- {{ $store.state.typetab }} -->
    <div>
      <router-view></router-view>
    </div>
    <!-- <google-map 
                    :lng="144.9558"
                    :lat="-37.8136"
                    ref="googleMap"
                  ></google-map> -->

    <!-- <router-link to="/TabTwo" @click="handleNavigate('/TabTwo')">TabTwo</router-link> -->
    <div class="bottom">
      <BottomVue @TabEvent="TabType"></BottomVue>
    </div>
  </div>
</template>

<script>
// import TabOneVue from './Tabs/TabOne.vue'
// import GoogleMap from './components/GoogleMap.vue';
import BottomVue from './views/Bottom.vue'

import TopVue from './views/Top.vue'
// import TabTwoVue from './Tabs/TabTwo.vue'

// import TabThreeVue from './Tabs/TabThree.vue'

// import TabFourVue from './Tabs/TabFour.vue'
// import TabFiveVue from './Tabs/TabFive.vue'

// import TabSixVue from './Tabs/TabSix.vue'
export default {
  name: 'App',
  components: {
    BottomVue,
    TopVue,
    // GoogleMap
    // TabOneVue,
    // TabTwoVue,
    // TabThreeVue,
    // TabFourVue,
    // TabFiveVue,
    // TabSixVue
  },
  data() {
    return {
      // currentPath: this.$route.path,
      fontfamily:'PingFang SC',
      screenWidth: 0,
      typename: this.$t('Tab')[1],
      options: [{
        value: 'cn',
        label: '中文'
      }, {
        value: 'en',
        label: 'English'
      }],
      isPortrait: this.isPortraitOrientation(),
      maxScreenWidth: window.screen.width,
      isLandscape1: this.isLandscape()
    }
  },

  computed: {
    currentPath() {
      return this.$store.state.typetab;
    },
    Path() {
      return this.$route.path // 获取当前路由路径
    }
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val
        this.timer = true
        let that = this
        setTimeout(function () {
          // 打印screenWidth变化的值
      location.reload()

          console.log(that.screenWidth)
          that.timer = false
        }, 400)
      }
    },
    isPortrait(val) {
          console.log("haaaaaaaaaaaaaaaaaaaaaa",val)

      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      location.reload()

    },
    // 监听路由对象$route的变化
    '$route': {
      handler: function (to, from) {
        // 路由发生变化时的处理逻辑
        console.log('Route changed from', from.path, 'to', to.path);
        var oldVal = to.path
        console.log(oldVal, 'oldValoldValoldValoldValoldValoldValoldValoldValoldValoldValoldValoldValoldValoldValoldValoldValoldVal')
        // if (oldVal == '/TabTwoVue') {
        //   // this.currentPath = this.$t('Tab')[1]
        //   this.$store.commit('updatetypetab', this.$t('Tab')[1])

        // }
        // if (oldVal == '/TabThreeVue') {
        //   this.$store.commit('updatetypetab', this.$t('Tab')[2])
        //   // this.currentPath = this.$t('Tab')[2]
        // }
        // if (oldVal == '/TabFourVue') {
        //   this.$store.commit('updatetypetab', this.$t('Tab')[3])
        //   // this.currentPath = this.$t('Tab')[3]
        // }
        // if (oldVal == '/TabFiveVue') {
        //   this.$store.commit('updatetypetab', this.$t('Tab')[4])
        // }
        // if (oldVal == '/TabSixVue') {
        //   this.$store.commit('updatetypetab', this.$t('Tab')[5])
        // } if (oldVal == '/TabSevenVue') {
        //   this.$store.commit('updatetypetab', this.$t('Tab')[6])
        // } if (oldVal == '/TabEightVue') {
        //   this.$store.commit('updatetypetab', this.$t('Tab1')[0])
        // } if (oldVal == '/TabNineVue') {
        //   this.$store.commit('updatetypetab', this.$t('Tab1')[1])
        // } if (oldVal == '/TabTenVue') {
        //   this.$store.commit('updatetypetab', this.$t('Tab1')[2])
        // } if (oldVal == '/TabElevenVue') {
        //   this.$store.commit('updatetypetab', this.$t('Tab1')[3])
        // }
        // else {
        //   console.log("haaaaaaaaaaaaaaaaaaaaaa")
        //   this.$store.commit('updatetypetab', this.$t('Tab')[0])
        // }
      },
      // 设置为深度监听
      deep: true
    },
    Path(val, oldVal) {
      console.log(val, oldVal, 'person对象已更改1111111111');
      // if (oldVal == '/TabTwoVue') {
      //   // this.currentPath = this.$t('Tab')[1]
      //   this.$store.commit('updatetypetab', this.$t('Tab')[1])

      // }
      // if (oldVal == '/TabThreeVue') {
      //   this.$store.commit('updatetypetab', this.$t('Tab')[2])
      //   // this.currentPath = this.$t('Tab')[2]
      // }
      // if (oldVal == '/TabFourVue') {
      //   this.$store.commit('updatetypetab', this.$t('Tab')[3])
      //   // this.currentPath = this.$t('Tab')[3]
      // }
      // if (oldVal == '/TabFiveVue') {
      //   this.$store.commit('updatetypetab', this.$t('Tab')[4])
      // }
      // if (oldVal == '/TabSixVue') {
      //   this.$store.commit('updatetypetab', this.$t('Tab')[5])
      // } if (oldVal == '/TabSevenVue') {
      //   this.$store.commit('updatetypetab', this.$t('Tab')[6])
      // } if (oldVal == '/TabEightVue') {
      //   this.$store.commit('updatetypetab', this.$t('Tab1')[0])
      // } if (oldVal == '/TabNineVue') {
      //   this.$store.commit('updatetypetab', this.$t('Tab1')[1])
      // } if (oldVal == '/TabTenVue') {
      //   this.$store.commit('updatetypetab', this.$t('Tab1')[2])
      // } if (oldVal == '/TabElevenVue') {
      //   this.$store.commit('updatetypetab', this.$t('Tab1')[3])
      // }
      // else {
      //   this.$store.commit('updatetypetab', this.$t('Tab')[0])
      // }
    },

    currentPath: {
      handler: function (val, oldVal) {
        // 路由发生变化时的处理逻辑
        console.log(val, oldVal, 'person对象已更改');
        this.typename = val
        console.log(this.$t('Tab1')[1])
        if (this.typename == this.$t('Tab')[0]) {
          this.$router.replace('/');
        }
        if (this.typename == this.$t('Tab')[1]) {
          this.$router.replace('/TabTwoVue');
        }
        if (this.typename == this.$t('Tab')[2]) {
          this.$router.replace('/TabThreeVue');
        }
        if (this.typename == this.$t('Tab')[3]) {
          this.$router.replace('/TabFourVue');
        }
        if (this.typename == this.$t('Tab')[4]) {
          this.$router.replace('/TabFiveVue');
        }
        if (this.typename == this.$t('Tab')[5]) {
          this.$router.replace('/TabSixVue');
        }
        if (this.typename == this.$t('Tab')[6]) {
          this.$router.replace('/TabSevenVue');
        }
        if (this.typename == this.$t('Tab1')[0]) {
          this.$router.replace('/TabEightVue');
        }
        if (this.typename == this.$t('Tab1')[1]) {
          this.$router.replace('/TabNineVue');
        }
        if (this.typename == this.$t('Tab1')[2]) {
          this.$router.replace('/TabTenVue');
        }
        if (this.typename == this.$t('Tab1')[3]) {
          this.$router.replace('/TabElevenVue');
        }
      },
      // 设置为深度监听
      deep: true
    }
  },


  // this.$store.commit('updatetypetab', this.typename)




  // computed: {
  //   myVariable() {
  //     return this.$store.state.myVariable
  //   }
  // },


  methods: {
    isLandscape() {
      return window.innerWidth > window.innerHeight;
    },
    handleOrientationChange() {
      this.isLandscape1 = this.isLandscape();
      console.log('Current orientation is ' + (this.isLandscape1 ? 'landscape' : 'portrait'));
      // 在此处根据isLandscape的值进行相应的处理
    },
    isPortraitOrientation() {
      return window.innerWidth < window.innerHeight;
    },
   
    // 创建一个方法来处理点击事件
    handleNavigate(path) {
      // 使用this.$router.push()来进行路由跳转
      this.$router.push(path);
    },
    TabType(data) {
      console.log('父组件接收到的数据：' + data)


      this.typename = data
      console.log(this.$t('Tab1')[1])
      if (this.typename == this.$t('Tab')[0]) {
        this.$router.push('/');
      }
      if (this.typename == this.$t('Tab')[1]) {
        this.$router.push('/TabTwoVue');
      }
      if (this.typename == this.$t('Tab')[2]) {
        this.$router.push('/TabThreeVue');
      }
      if (this.typename == this.$t('Tab')[3]) {
        this.$router.push('/TabFourVue');
      }
      if (this.typename == this.$t('Tab')[4]) {
        this.$router.push('/TabFiveVue');
      }
      if (this.typename == this.$t('Tab')[5]) {
        this.$router.push('/TabSixVue');
      }
      if (this.typename == this.$t('Tab')[6]) {
        this.$router.push('/TabSevenVue');
      }
      if (this.typename == this.$t('Tab1')[0]) {
        this.$router.push('/TabEightVue');
      }
      if (this.typename == this.$t('Tab1')[1]) {
        this.$router.push('/TabNineVue');
      }
      if (this.typename == this.$t('Tab1')[2]) {
        this.$router.push('/TabTenVue');
      }
      if (this.typename == this.$t('Tab1')[3]) {
        this.$router.push('/TabElevenVue');
      }
      this.$store.commit('updatetypetab', this.typename)
      // if(this.typename ==this.$t('Tab')[6]){
      //   this.$router.push('./TabTwoVue');
      // }
    }
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.handleOrientationChange);
    window.removeEventListener('resize', this.handleOrientationChange);
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
    that.currentPath = this.$route.path; // 获取当前路由路径
    console.log(that.currentPath)

    this.maxScreenWidth = window.screen.width;
    console.log(' this.maxScreenWidth',  this.maxScreenWidth)
    window.addEventListener('resize', this.handleResize);
    console.log('this.$i18n.locale', this.$i18n.locale)
    if( this.$i18n.locale=='en'){
      this.fontfamily='PingFang SC'
    }
    else{
      this.fontfamily=' AlibabaPuHuiTi, AlibabaPuHuiTi';
    }
    window.addEventListener('orientationchange', this.handleOrientationChange);
    // 对于移动设备，可能还需要监听resize事件以处理屏幕方向变化
    window.addEventListener('resize', this.handleOrientationChange);
 

  },
 

  created() {
    // 对resize事件进行浏览器兼容处理
    // if (document.createEvent) {
    //   var event = document.createEvent("HTMLEvents");
    //   event.initEvent("resize", true, true);
    //   window.dispatchEvent(event);
    // } else if (document.createEventObject) {
    //   window.fireEvent("onresize");
    // }

    // // 监听页面resize事件，重新设置rem的根字体大小
    // window.onresize = function () {
    //   let width = window.innerWidth;
    //   width = width <= 1200 ? 1200 : width;
    //   htmlObj.style.fontSize = width / 76.8 + "px";
    // };
    

  }


}
</script>
<style>
/*每个页面公共css */
@import "@/static/style.css";
</style>

<style>
/* 全局样式文件，比如在App.vue中 */
/* src/assets/styles/main.css */
@media (min-width: 801px) {
  body {
    font-size: 16px;
    /* max-width: 1920px !important;
    min-width: 1900px !important;  */
    /* font-family: PingFang SC; */
    /* 设置默认的字体大小为16px */
  }
}
@media (max-width: 80px) {
  body {
    font-size: 16px;
    
    /* font-family: PingFang SC; */
    /* 设置默认的字体大小为16px */
  }
}


#app {
margin: 0 auto;

  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}

.bottom {
  /* margin-top: 30px; */
  /* 
  position: absolute;
  bottom: 0;
  z-index: 1000;
  width: 1920px;
  height: 392px; */
  /* border-radius: 0px 0px 0px 0px; */
}

.blue {
  color: #51B1FF;
}

.write {
  color: #fff;

}

.w48 {
  width: 48px;
  height: 48px;
}

.w38 {
  width: 38px;
  height: 38px;
}
</style>
