<template>
    <div class="bottom1s">
        <div class="bottom1 ">
            <div class="bottom11 ">
                {{ $t('bottom.tab1') }}
                <div class="text flex1" v-for="(item, index) in $t('Tab')" :key="index" @click="onClick(item)">
                    <div class="name">{{ item }}</div>
                </div>
            </div>
            <div class="bottom13 ">
                {{ $t('bottom.tab2') }}
                <div class="text flex1">
                    <!-- {{ $t('bottom.name') }}: -->
                    <div class="name">{{room.company.address}}</div>
                </div>
                <div class="text flex1">
                    <!-- {{ $t('bottom.tel') }}: -->
                    <div class="name">T {{room.webFooterPhone}}</div>
                </div>
                <div class="text flex1">
                    <!-- {{ $t('bottom.email') }}: -->
                    <div class="name">E {{room.webFooterEmail}}</div>
                </div>

            </div>
            <div class="bottom12  flex1">
                {{ $t('bottom.code') }}:
                    <div class="name">{{room.webFooterRegisterCode}}</div>
            </div>

        </div>



    </div>
</template>

<script>
import { webConfig } from '../request/api'
export default {
    name: 'bottomVue',
    data() {
        return {
            refresh: this.$t('refresh'), // 第三种用法，不过这种用法在切换预言时，需要刷新页面才会生效
            dialogVisible: false,
            // languageVal: this.$i18n.locale,
            options: [{
                value: 'cn',
                label: '中文'
            }, {
                value: 'en',
                label: 'English'
            }],
            tabtype: this.$t('bottom.tabs1')[0],
            activeName: this.$t('Tab')[0],
            active: 0,
            code: "9999999",
            room:{}
        }
    },
    methods: {
        langChange(e) {
            console.log(e)
            localStorage.setItem('lang', e);
            this.$i18n.locale = e;
            //   window.location.reload() // 第三种用法刷新页面
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        onClick(title) {
            console.log(title)
            // Toast(title);
            this.tabtype = title
            this.$emit('TabEvent', this.tabtype)
            this.$store.commit('updatetypetab', title)
               // 获取到前面打过标签的元素，将其scrollTop属性设置为0
               document.documentElement.scrollTop = 0
            // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
            document.body.scrollTop = 0

        },
        async webConfig() {
            

            await webConfig().then(res => {
                console.log('companycompanycompanycompanycompany', res)
               this.room=res.data

            })
        },


    },
    created() {
        console.log(this.tabtype, ' this.tabtype this.tabtype this.tabtype')
        this.$emit('TabEvent', this.tabtype)
        this.webConfig()
    },


}
</script>
<style scoped>
@media (max-width: 800px) {
    .bottom1 {
        /* display: flex; */
        justify-content: space-between;
        padding: 20px;
        flex-wrap: wrap;

    }

    .flex1 {
        flex-wrap: wrap;
    }

    .bottom1s {
        width: 100%;
        
        justify-content: space-between;
        /* height: 347px; */
        background: #131A2C;
        color: #D6D6D6;
        border-radius: 0px 0px 0px 0px;

    }


    .bottom12 {
        margin-top: 20px;
        align-items: center;
    }

    .text {
        margin-top: 20px;
        font-size: 16px;
        align-items: center;
    }

    .name {
        cursor: pointer;
        /* font-family: AlibabaPuHuiTi, AlibabaPuHuiTi; */
        font-weight: 400;
        font-size: 16px;
        color: #9E9E9E;
        /* line-height: 28px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-left: 10px;
    }

    .bottom13 {
        margin-top: 20px;
        width: 100%;
    }
}

/* 针对桌面端的样式 */
@media (min-width: 801px) {
    .bottom1 {
        display: flex;
        justify-content: space-between;
        align-items: start;
        padding: 60px 100px;
        flex-wrap: wrap;

    }
    .bottom1s {
        width: 100%;
        justify-content: space-between;
        /* height: 347px; */
        /* align-items: start; */
        background: #131A2C;
        color: #D6D6D6;
        border-radius: 0px 0px 0px 0px;

    }


    .bottom12 {
        align-items: center;
    }

    .text {
        margin-top: 20px;
        font-size: 16px;
        /* align-items: center; */
    }

    .name {
        cursor: pointer;
        /* font-family: AlibabaPuHuiTi, AlibabaPuHuiTi; */
        font-weight: 400;
        font-size: 16px;
        color: #9E9E9E;
        /* line-height: 28px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-left: 10px;
    }
    .bottom11 {
        width: 25%;
    }

    .bottom13 {
        width: 45%;
    }

}
</style>
