<!--  -->
<template>
    <div>
        <div v-if="screenWidth >= 801">
            <div class="about_us_t">
                <el-carousel trigger="click" height="360px" :autoplay="false">
                    <el-carousel-item v-for="item in banner1" :key="item">
                        <img :src="item.image_text" class="tabbg" alt="">
                    </el-carousel-item>
                </el-carousel>
            </div>

        </div>
        <div v-else>
            <div class="about_us_t" >
                <el-carousel trigger="click" height="360px" :autoplay="false">
                    <el-carousel-item v-for="item in banner2" :key="item">
                        <img :src="item.image_text" class="tabbg" alt="">
                    </el-carousel-item>
                </el-carousel>
            </div>
           
        </div>
        <div class="about_us_m ">
            <!-- <img src="../static/index/title4.png" class="tabbg" alt=""> -->
            <div class="d_u_m_t">
                {{ fitment.title }}
            </div>
            <div class="d_u_m_l">
                {{ fitment.intro }}
            </div>
            <div class="d_u_m_l" v-html="fitment.content">


            </div>
            <!-- <div class="d_u_m_r p20">
                <div class="about_title">
                    {{$t('about_us.Our_Departments')}}
                </div>
                <div class="about_boxs">
                   
                    <div class="about_box mtb10 "  v-for="(item, index) in $t('about_us.Our_Department')" :key="index">
                        {{ item }}
                    </div>
                   
                </div>
            </div> -->
        </div>
        <div class="about_us_b">
            <SeverePageVue :newdata="team_member" :buttontitle="'team_member'" :type="1"></SeverePageVue>
            <SeverePageVue :newdata="join_team" :buttontitle="'join_team'" :type="2"></SeverePageVue>

            <EqueryFormVue></EqueryFormVue>
        </div>

    </div>
</template>

<script>

import SeverePageVue from '@/components/SeverePage.vue';

import EqueryFormVue from '@/components/EqueryForm.vue';
import { imgText, banner } from '../request/api'
export default {
    name: 'TabFive',
    components: {
        EqueryFormVue,
        SeverePageVue,

    },
    mounted() {
        const that = this
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                that.screenWidth = window.screenWidth
            })()
        }
    },
    watch: {
        screenWidth(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            this.screenWidth = val
            this.timer = true
        }
    }
    ,

    data() {
        return {
            screenWidth: document.body.clientWidth,//初始化宽度
            timer: '',
            pagelist: {},
            banner1: [{
                image_text: 'https://nelsons.buzzegg.cn/uploads/20240607/aae1b14215292967bfd6ef3a6bd9e338.png'
            }],
            banner2: [{
                image_text: 'https://nelsons.buzzegg.cn/uploads/20240607/aae1b14215292967bfd6ef3a6bd9e338.png'
            }],
            our_service: {},
            contact_us: {},
            about_us: {},
            our_team: {},
            join_team: {},
            tenement: {},

            buy_house: {},
            rent_house: {},
            flat: {},
            fitment: {},
            sell_house: {},
            loan: {},
            team_member: {},
        }

    },
    methods: {
        async getbanner1() {
            await banner({
                type:8,
                device: 1
            }).then(res => {
                console.log('resbannerbannerbanner', res)
                if(res.data.length>0){
                    console.log('first')

                    this.banner1= res.data
                }


            })
        },
        async getbanner2() {
            await banner({
                type:8,
                device: 2
            }).then(res => {
                console.log('resbannerbannerbanner', res)
                if(res.data.length>0){
                    console.log('first')
                    this.banner2 = res.data
                }
             

            })
        },
        async getdata() {
            await imgText().then(res => {

                this.our_service = res.data.our_service
                    this.contact_us = res.data.contact_us
                    this.about_us = res.data.about_us
                    this.our_team = res.data.our_team
                    this.join_team = res.data.join_team
                    this.tenement = res.data.tenement
                    this.buy_house = res.data.buy_house
                    this.rent_house = res.data.rent_house
                    this.flat = res.data.flat
                    this.fitment = res.data.fitment
                    this.sell_house = res.data.sell_house
                    this.loan = res.data.loan
                    this.team_member = res.data.team_member

            })
            // await banner({
            //     type: 0
            // }).then(res => {

            //     console.log('res', res)

            // })
        }
    },
    async created() {
        // this.initMap()
        this.getdata()
        this.getbanner1()
        this.getbanner2()
        // console.log(this.list,'haaaaaaaaaaaaaaaaaaaaaaaaaaaa')
    }
}

</script>
<style scoped>

.tabbg {
    width: 100%;
    height: 360px;
}
@media (max-width: 800px) {
  
    .about_us_m {

        /* height: 1302rpx; */
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 28rpx;
        color: #666666;
        line-height: 42rpx;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin: 40px 20px;
    }
}

/* 针对桌面端的样式 */
@media (min-width: 801px) {
    
    .about_us_m {
        margin: 20px 10vw;
    }

}

.d_u_m_t {
    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
}

.d_u_m_l {
    /* width: 680px;
    height: 616px; */
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    /* line-height: 28px; */
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.d_u_m_r {
    /* width: 370px; */
    /* height: 692px; */
    background: #F7FBFF;
    border: 1px solid #DDDDDD;
}

.about_title {

    font-family: Montserrat, Montserrat;
    font-weight: 700;
    font-size: 18px;
    color: #111111;
    /* line-height: 22px; */
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.about_boxs {
    margin-top: 30px;
}

.about_box {
    padding: 15px 10px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #DDDDDD;

    font-size: 16px;
    color: #1495FF;
    /* line-height: 78px; */
    text-align: center;

}
</style>
