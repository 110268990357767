<!--  -->
<template>
    <div>
        <div v-if="screenWidth >= 801">
            <div class="about_us_t">
                <el-carousel trigger="click" height="360px" :autoplay="false">
                    <el-carousel-item v-for="item in banner1" :key="item">
                        <img :src="item.image_text" class="tabbg" alt="">
                    </el-carousel-item>
                </el-carousel>
            </div>

        </div>
        <div v-else>
            <div class="about_us_t" >
                <el-carousel trigger="click" height="360px" :autoplay="false">
                    <el-carousel-item v-for="item in banner2" :key="item">
                        <img :src="item.image_text" class="tabbg" alt="">
                    </el-carousel-item>
                </el-carousel>
            </div>
           
        </div>
        <div class="map" v-if="mapshow">
            <div class="back" @click="mapshow = false"><img src="../static/back.png" class="back_img" alt=""></div>
            <GmapMap :center="{ lat: lat, lng: lat }" :zoom="12" style="width: 100%; height: 100%;">
                <GmapMarker :position="{ lat: lat, lng: lat }" />
            </GmapMap>

        </div>
        <div class="about_us_m" v-else>
            <div class="title">
                {{ $t('Contact_us.OUR_BRANCHES') }}
                <div class="line">
                </div>
                <div class="title1"> {{ $t('Contact_us.Enter') }}</div>
                <el-input :placeholder="$t('Contact_us.Enter')" class="title_input"
                    v-model="input2">
                    <el-button @click="initMap" slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </div>
            <div class="listboxs " v-if="screenWidth >= 801">

                <div class="listbox" v-if="contactlist.length > 0">
                    <div class="listbox1" v-for="(item, index) in contactlist" :key="index">

                        <div class="top">
                            {{ item.section }}

                        </div>
                        <div class="mid flex1">
                            <div class="mid_box">
                                <div class="min_text1">
                                    {{ $t('Contact_us.SALES') }}: <div class="minspan" @click="gettel(item.telephone)">
                                        {{ item.telephone }}</div>
                                </div>
                                <div class="min_text2"> {{ $t('Contact_us.LETTINGS') }}:<div class="minspan"
                                        @click="gettel(item.telephone)">
                                        {{ item.telephone }}</div>
                                </div>
                                <div class="min_text3"> {{ $t('Contact_us.DEPARTMENT') }}:<div class="minspan">
                                        {{ item.name }}</div>
                                </div>

                            </div>
                            <div class="cllent">
                                <img :src="item.avatar_text" alt="" class="cllent_img">
                                <!-- <img src="../static/index/like.png" v-else alt="" class="like"> -->
                            </div>
                        </div>
                        <div class="bottom">
                            <!-- <div class="name">{{ item.name }}</div> -->
                            <div class="bottom_btn1" @click="getmap(item)">{{ $t('Contact_us.Company_Address') }}</div>
                            <div class="bottom_btn2" @click="gettel(item.email)">{{
            $t('Contact_us.EMAIL_COMMERCIAL_PROPERTY') }}</div>
                        </div>


                    </div>
                </div>
                <div v-else>
                    <el-empty description="Empty"></el-empty>
                </div>

            </div>
            <div class="listboxs " v-else>

                <div  v-if="contactlist.length > 0">
                    <div class="listbox" v-for="(item, index) in contactlist" :key="index" @click="houseDetail(item)">
                        <div class="top">
                            {{ item.section }}

                        </div>
                        <div class="mid flex1">
                            <div class="mid_box">
                                <div class="min_text1">
                                    {{ $t('Contact_us.SALES') }}: <div class="minspan" @click="gettel(item.telephone)">
                                        {{ item.telephone }}</div>
                                </div>
                                <div class="min_text2"> {{ $t('Contact_us.LETTINGS') }}:<div class="minspan"
                                        @click="gettel(item.telephone)">
                                        {{ item.telephone }}</div>
                                </div>
                                <div class="min_text3"> {{ $t('Contact_us.DEPARTMENT') }}:<div class="minspan">
                                        {{ item.name }}</div>
                                </div>

                            </div>
                            <div class="cllent">
                                <img :src="item.avatar_text" alt="" class="cllent_img">
                                <!-- <img src="../static/index/like.png" v-else alt="" class="like"> -->
                            </div>
                        </div>
                        <div class="bottom">
                            <!-- <div class="name">{{ item.name }}</div> -->
                            <div class="bottom_btn1" @click="getmap(item)">{{ $t('Contact_us.Company_Address') }}</div>
                            <div class="bottom_btn2" @click="gettel(item.email)">{{
            $t('Contact_us.EMAIL_COMMERCIAL_PROPERTY') }}</div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <el-empty description="Empty"></el-empty>
                </div>

            </div>
            <div class="title">
                {{ $t('Contact_us.Company_Address') }}
                <div class="line">

                </div>


            </div>
            <div class="map">
                <GmapMap :center="{ lat: Number(company.lat), lng: Number(company.lng) }" :zoom="12"
                    style="width: 100%; height: 100%;">
                    <GmapMarker :position="{ lat: Number(company.lat), lng: Number(company.lng) }" />
                </GmapMap>

            </div>

        </div>
        <div class="about_us_b">
            <EqueryFormVue></EqueryFormVue>
            <SeverePageVue :newdata="our_service" :buttontitle="'our_service'" :type="2"></SeverePageVue>

        </div>

    </div>
</template>

<script>

import SeverePageVue from '@/components/SeverePage.vue';
import { teamMem, banner, imgText, webConfig } from '../request/api'

import EqueryFormVue from '@/components/EqueryForm.vue';
export default {
    name: 'TabEleven',
    components: {

        SeverePageVue,
        EqueryFormVue

    },


    data() {
        return {
            banner1: [{
                image_text: 'https://nelsons.buzzegg.cn/uploads/20240607/aae1b14215292967bfd6ef3a6bd9e338.png'
            }],
            banner2: [{
                image_text: 'https://nelsons.buzzegg.cn/uploads/20240607/aae1b14215292967bfd6ef3a6bd9e338.png'
            }],
            our_service: {},
            contact_us: {},
            about_us: {},
            our_team: {},
            join_team: {},
            tenement: {},

            buy_house: {},
            rent_house: {},
            flat: {},
            fitment: {},
            sell_house: {},
            loan: {},
            team_member: {},
            mapshow: false,
            enquiry: {

                First_name: 'First name',
                Last_name: 'Last name',
                Phone: 'Phone',
                Email: 'Email',
                Message: 'Message',
                agree1: true,
                agree2: true,

            },
            lat: '',
            lng: '',
            screenWidth: document.body.clientWidth,//初始化宽度
            contactlist: [{
                SALES: ' 01306 743344',
                LETTINGS: ' 01306 743344',
                DEPARTMENT: 'Tamsyn Richards',
                image_text: '../static/index/t1.png'
            }, {
                SALES: ' 01306 743344',
                LETTINGS: ' 01306 743344',
                DEPARTMENT: 'Tamsyn Richards',
                image_text: '../static/index/t1.png'
            }, {
                SALES: ' 01306 743344',
                LETTINGS: ' 01306 743344',
                DEPARTMENT: 'Tamsyn Richards',
                image_text: '../static/index/t1.png'
            }],
            input2: '',
            company: {}
        }
    },
    mounted() {
        const that = this
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                that.screenWidth = window.screenWidth
            })()
        }
    },
    watch: {
        screenWidth(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            this.screenWidth = val
            this.timer = true
        }
    },
    async created() {
        this.initMap()
        this.getbanner1()
        this.getbanner2()
        this.getdata()
        this.getourcompany()

    },
    methods: {
        async getbanner1() {
            await banner({
                type: 5,
                device: 1
            }).then(res => {
                console.log('resbannerbannerbanner', res)
                if (res.data.length > 0) {
                    this.banner1 = res.data
                }


            })
        },
        async getourcompany() {

            await webConfig().then(res => {
                console.log('rescompanycompanycompanycompany', res)
                if (res.data.company) {
                    this.company = res.data.company
                }


            })
        },
        async getbanner2() {
            await banner({
                type: 5,
                device: 2
            }).then(res => {
                console.log('resbannerbannerbanner', res)
                if (res.data.length > 0) {
                    this.banner2 = res.data
                }

            })
        },
        getmap(item) {
            console.log('item', item)
            this.lat = Number(item.lat)
            this.lng = Number(item.lng)
            // 获取到前面打过标签的元素，将其scrollTop属性设置为0
            document.documentElement.scrollTop = 0
            // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
            document.body.scrollTop = 0
            this.mapshow = true
        },
        gettel(tel) {


            var input = document.createElement('input') // 创建input标签
            input.value = tel; // 将input的值设置为需要复制的内容
            document.body.appendChild(input) // 添加input标签
            input.select()  // 选中input标签
            document.execCommand('copy') // 执行复制
            // this.$message.success('success!') // 成功提示信息
            document.body.removeChild(input) // 移除input标签

            this.$notify({
                title: ' Copy successful',
                message: tel,
                type: 'success'
            });
        },
        async initMap() {
            await teamMem(
                {
                    email: this.input2
                }
            ).then(res => {

                console.log('res', res)
                this.contactlist = res.data

            })
        },
        async getdata() {
            await imgText().then(res => {
                if (res.data) {
                    this.our_service = res.data.our_service
                    this.contact_us = res.data.contact_us
                    this.about_us = res.data.about_us
                    this.our_team = res.data.our_team
                    this.join_team = res.data.join_team
                    this.tenement = res.data.tenement
                    this.buy_house = res.data.buy_house
                    this.rent_house = res.data.rent_house
                    this.flat = res.data.flat
                    this.fitment = res.data.fitment
                    this.sell_house = res.data.sell_house
                    this.loan = res.data.loan
                    this.team_member = res.data.team_member



                }

            })
            // await banner({
            //     type: 0
            // }).then(res => {

            //     console.log('res', res)

            // })
        }
    }
}

</script>
<style scoped>
@media (max-width: 800px) {
    .tabbg {
        width: 100%;
        height: 360px;
    }

    body {
        /* background-color: lightblue; */
    }

    .back_img {
        width: 32px;
        height: 32px;
    }

    .tablist {}

    .listboxs {
        text-align: center;
    }


    .listbox {

        margin: 20px;
        padding: 20px;
        border: 1px solid #E5E5E5;
        text-align: left;
    }

    .hide-on-mobile {
        display: none;
    }

    .container {
        display: flex;
        flex-direction: column;
    }

    .mid {
        width: 100%;
        margin: 16px 0;
        /* align-items: s; */
        justify-content: space-between;
    }

    .mid_box {
        width: 55%;
    }


    .topimg {
        width: 100%;
        height: auto;
        text-align: center;
        border-radius: 8px 8px 8px 8px;
    }

    .top {

        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 18px;
        color: #111111;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .minspan {
        font-size: 16px;
        color: #111111;
        line-height: 32px;
        text-align: left;
        text-decoration: underline;
    }


    .price {
        width: 166px;
        height: 36px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        color: #111111;
        /* line-height: 36px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .like {
        width: 28px;
        height: 28px;
    }

    .bottom {
        text-align: center;
        margin-bottom: 10px;
    }

    .bottom_btn1 {
        margin: 10px auto;
        width: 100%;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
        color: #FFFFFF;
        line-height: 22px;
        letter-spacing: 1px;
        padding: 10px 0;
        /* height: 52px; */

        background: #1495FF;
    }

    .bottom_btn2 {
        margin: 10px auto;
        width: 100%;
        font-weight: 700;
        color: #1495FF;
        border: 1px solid #1495FF;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
        line-height: 22px;
        letter-spacing: 1px;
        padding: 10px 0;
        /* height: 52px; */
        background: #FFFFFF;
    }

    .name {
        max-height: 60px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 20px;
        color: #888888;
        /* line-height: 28px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .texttitle {
        margin: 20px auto;
        /* width: 217px;
    height: 24px; */
        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 20px;
        color: #111111;
        /* line-height: 24px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .cllent {
        width: 45%;
    }

    .cllent_img {
        width: 100%;
        height: auto;
    }

    .texttitle {
        margin: 20px auto;
        /* width: 217px;
    height: 24px; */
        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 20px;
        color: #111111;
        /* line-height: 24px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .line {
        margin: 10px auto;
        width: 25px;
        height: 3px;
        background: #1495FF;
    }

    .title_input {
        margin: 20px auto;
        width: 200px;
    }

    .title1 {
        margin: 20px auto;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #333333;
        line-height: 36px;
        text-align: center;
    }

    .map {
        width: 100%;
        height: 352px;
        /* background-color: #333333; */
    }

}

/* 针对桌面端的样式 */
@media (min-width: 801px) {
    .tabbg {
        width: 100%;
        height: 360px;
    }

    .back_img {
        width: 40px;
        height: 40px;
    }

    .title1 {
        margin: 20px auto;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #333333;
        line-height: 36px;
        text-align: center;
    }

    .map {
        width: 100%;
        height: 452px;
        /* background-color: #333333; */
    }

    .listbox {
        display: grid;
        /* width: 80%; */
        margin: 20px auto;
        text-align: center;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 20px;
        /* grid-column-gap: 20px; */

    }

    .listbox1 {
        margin: 20px;
        padding: 20px;
        border: 1px solid #E5E5E5;
        text-align: left;
    }

    .mid {
        width: 100%;
        margin: 16px 0;
        /* align-items: s; */
        justify-content: space-between;
    }

    .mid_box {
        width: 55%;
    }

    .min_text1 {
        font-size: 16px;
        color: #111111;
        line-height: 32px;
        text-align: left;
    }

    .minspan {
        font-size: 16px;
        color: #111111;
        line-height: 32px;
        text-align: left;
        text-decoration: underline;
    }

    .cllent {
        width: 45%;
    }

    .cllent_img {
        width: 100%;
        height: auto;
    }

    .top {

        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 18px;
        color: #111111;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }


    .topimg {
        width: 100%;
        height: 236px;
        border-radius: 3px 3px 3px 3px;
    }

    .price {
        width: 166px;
        height: 36px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        color: #111111;
        /* line-height: 36px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .like {
        width: 28px;
        height: 28px;
    }

    .bottom {
        text-align: center;
        margin-bottom: 10px;
    }

    .bottom_btn1 {
        margin: 10px auto;
        width: 100%;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
        color: #FFFFFF;
        line-height: 22px;
        letter-spacing: 1px;
        padding: 10px 0;
        /* height: 52px; */
        background: #1495FF;
    }

    .bottom_btn2 {
        margin: 10px auto;
        width: 100%;
        font-weight: 700;
        color: #1495FF;
        border: 1px solid #1495FF;
        font-size: 16px;
        text-align: center;
        cursor: pointer;


        line-height: 22px;
        letter-spacing: 1px;
        padding: 10px 0;
        /* height: 52px; */
        background: #FFFFFF;
    }

    .name {
        max-height: 60px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 20px;
        color: #888888;
        /* line-height: 28px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .texttitle {
        margin: 20px auto;
        /* width: 217px;
    height: 24px; */
        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 20px;
        color: #111111;
        /* line-height: 24px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .line {
        margin: 10px auto;
        width: 25px;
        height: 3px;
        background: #1495FF;
    }

    .title_input {
        margin: 20px auto;
        width: 200px;
    }

}

@media (max-width: 800px) {
    .about_us_m {

        /* height: 1302rpx; */
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 28rpx;
        color: #666666;
        line-height: 42rpx;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin: 40px 20px;
    }
}

/* 针对桌面端的样式 */
@media (min-width: 801px) {
    .about_us_m {
        margin: 20px 10vw;
    }


}

.title {
    text-align: center;
    margin: 20px auto;
    font-family: Montserrat, Montserrat;
    font-weight: 700;
    font-size: 18px;
    color: #111111;
    line-height: 22px;

}

.d_u_m_l {
    /* width: 680px;
    height: 616px; */
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    /* line-height: 28px; */
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.d_u_m_r {
    /* width: 370px; */
    /* height: 692px; */
    background: #F7FBFF;
    border: 1px solid #DDDDDD;
}

.about_title {

    font-family: Montserrat, Montserrat;
    font-weight: 700;
    font-size: 18px;
    color: #111111;
    /* line-height: 22px; */
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.about_boxs {
    margin-top: 30px;
}

.about_box {
    padding: 15px 10px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #DDDDDD;

    font-size: 16px;
    color: #1495FF;
    /* line-height: 78px; */
    text-align: center;

}
</style>
