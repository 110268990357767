<!--  -->
<template>
    <div>
        <div class="title">
            {{ $t('News.Title') }}
        </div>
        <div class="listboxs " v-if="screenWidth >= 801">

            <div class="listbox" v-if="contactlist.length > 0">
                <div class="listbox1" :style="{ background: `url(${item.image_text})`, 'background-size': '100% 100%' }"
                    v-for="(item, index) in contactlist" :key="index" @click="houseDetail(item)">

                    <div class="top">
                        {{ item.title }}

                    </div>
                    <div class="mid flex1">
                        {{ item.createtime | formatDate }}
                    </div>
                    <div class="bottom flex1">
                        <!-- <div class="name">{{ item.name }}</div> -->
                        {{ $t('News.READ_ARTICLE') }}
                        <img src="../static/index/arrow-right.png" class="like" alt="">
                    </div>


                </div>

            </div>
            <div v-else>
                <el-empty description="Empty"></el-empty>
            </div>

        </div>
        <div class="listboxss " v-else>
            <div v-if="contactlist.length > 0">
                <div class="listbox0" :style="{ background: `url(${item.image_text})`, 'background-size': '100% 100%' }"
                    v-for="(item, index) in contactlist" :key="index" @click="houseDetail(item)">
                    <div class="top">
                        {{ item.title }}
                    </div>
                    <div class="mid flex1">
                        {{ item.createtime | formatDate }}

                    </div>
                    <div class="bottom flex1">
                        <!-- <div class="name">{{ item.name }}</div> -->
                        {{ $t('News.READ_ARTICLE') }}
                        <img src="../static/index/arrow-right.png" class="like" alt="">
                    </div>
                </div>
             
            </div>
            <div v-else>
                <el-empty description="Empty"></el-empty>
            </div>


        </div>
        <div v-if="dialogTableVisible" class="dialog">
            <el-dialog title="New" :visible.sync="dialogTableVisible">
            <div class="dialog" v-if="screenWidth >= 801">
                <div class="">
                    <div class="title">
                        {{ housedetail.title }}
                    </div>
                    <div class="time">{{ housedetail.createtime | formatDate }}</div>
                    <img :src="housedetail.image_text" class="image" alt="">
                    <div class="intro">
                        {{ housedetail.intro }}
                    </div>

                    <div v-html="housedetail.content"></div>
                </div>
            </div>
            <div class="dialog1" v-else>
                <div class="">
                    <div class="title">
                        {{ housedetail.title }}
                    </div>
                    <div class="time">{{ housedetail.createtime | formatDate }}</div>
                    <img :src="housedetail.image_text" class="image" alt="">
                    <div class="intro">
                        {{ housedetail.intro }}
                    </div>

                    <div v-html="housedetail.content"></div>
                </div>
            </div>

        </el-dialog>
        </div>
       
    </div>
</template>

<script>
import { newsList } from '../request/api'
export default {
    name: 'NewList',
    props: {
        msg: String,
        hourelist: Array
    },



    data() {
        return {
            dialogTableVisible: false,
            housedetail: {},
            page: 1,
            value: '',
            houseid: 0,
            screenWidth: document.body.clientWidth,//初始化宽度
            contactlist: [],

            picture: 'https://bucket-1321254592.cos.ap-beijing.myqcloud.com/uploads/20240514/d7aabb04cdfa5994a3816dad54606284.jpg',
        }
    },
    async created() {
        // this.initMap()
        this.getdata()

        // console.log(this.list,'haaaaaaaaaaaaaaaaaaaaaaaaaaaa')
    },
    mounted() {
        const that = this
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                that.screenWidth = window.screenWidth
            })()
        }
        // console.log(this.$route.params.houseid,'hhhhhhhhhhhhhhhhhhhhhhhhhhhhh'); // 输出路由参数
    },

    watch: {
        screenWidth(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            this.screenWidth = val
            this.timer = true
        }
    },
    methods: {

        houseDetail(item) {
            console.log(item)
            this.housedetail = item
            this.dialogTableVisible = true
            // this.houseid = item.id
            // this.$emit('HouseId', this.houseid)
            // // 获取到前面打过标签的元素，将其scrollTop属性设置为0
            // document.documentElement.scrollTop = 0
            // // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
            // document.body.scrollTop = 0
            // window.open(item.jump_site, "_blank");



        },
        async getdata() {
            await newsList({
                news_type_id: this.value, //新闻分类ID

                page: this.page, //页码

                limit: 12 //每页显示条数
            }).then(res => {

                var pagelist = []
                pagelist = res.data.news_list
                if (this.page == 1) {
                    this.contactlist = pagelist
                }
                else {
                    this.contactlist = this.list.concat(pagelist)
                }


            })
        },
        onClick(name, title) {

            this.tabtype = title
            this.$emit('HouseId', this.houseid)
        },

    }
}

</script>

<style scoped >
.tabbg {
    width: 100%;
    height: 40vh;
}

/deep/ .el-dialog {
    width: 80%;
}



@media (max-width: 800px) {
    body {
        /* background-color: lightblue; */
    }

    .tablist {}

    .listboxs {
        text-align: center;
        display: grid;
        text-align: center;
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 20rpx;
        grid-column-gap: 20rpx;
    }



    .listbox {
        width: 80%;
        margin: 20px;
        padding: 20px;
        border: 1px solid #E5E5E5;
        text-align: left;
        cursor: pointer;
    }

    .listbox0 {
        width: 80%;
        margin: 20px;
        padding: 20px;
        border: 1px solid #E5E5E5;
        text-align: left;
        cursor: pointer;
    }

    .hide-on-mobile {
        display: none;
    }

    .container {
        display: flex;
        flex-direction: column;
    }

    .mid {
        width: 100%;
        margin: 16px 0;
        /* align-items: s; */
        justify-content: space-between;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.9);
        line-height: 18px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .mid_box {
        width: 55%;
    }


    .topimg {
        width: 100%;
        height: auto;
        text-align: center;
        border-radius: 8px 8px 8px 8px;
    }

    .top {

        font-family: DM Serif Display, DM Serif Display;
        font-weight: 400;
        font-size: 25px;
        color: #FFFFFF;
        line-height: 30px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .minspan {
        font-size: 16px;
        color: #111111;
        line-height: 32px;
        text-align: left;
        text-decoration: underline;
    }


    .price {
        width: 166px;
        height: 36px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        color: #111111;
        /* line-height: 36px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .like {
        width: 28px;
        height: 28px;
    }

    .bottom {
        /* text-align: center; */
        align-items: center;
        margin-bottom: 10px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #1495FF;
        line-height: 18px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-top: 150px;
    }

    .bottom_btn1 {
        margin: 10px auto;
        width: 100%;
        font-weight: 700;
        font-size: 16px;
        text-align: center;

        color: #FFFFFF;
        line-height: 22px;
        letter-spacing: 1px;
        padding: 10px 0;
        /* height: 52px; */
        background: #1495FF;
    }

    .bottom_btn2 {
        margin: 10px auto;
        width: 100%;
        font-weight: 700;
        color: #1495FF;
        border: 1px solid #1495FF;
        font-size: 16px;
        text-align: center;


        line-height: 22px;
        letter-spacing: 1px;
        padding: 10px 0;
        /* height: 52px; */
        background: #FFFFFF;
    }

    .name {
        max-height: 60px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 20px;
        color: #888888;
        /* line-height: 28px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .texttitle {
        margin: 20px auto;
        /* width: 217px;
    height: 24px; */
        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 20px;
        color: #111111;
        /* line-height: 24px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .cllent {
        width: 45%;
    }

    .cllent_img {
        width: 100%;
        height: auto;
    }

    .texttitle {
        margin: 20px auto;
        /* width: 217px;
    height: 24px; */
        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 20px;
        color: #111111;
        /* line-height: 24px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .line {
        margin: 10px auto;
        width: 25px;
        height: 3px;
        background: #1495FF;
    }

    .title_input {
        margin: 20px auto;
        width: 200px;
    }

    .title1 {
        margin: 20px auto;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #333333;
        line-height: 36px;
        text-align: center;
    }

    .map {
        width: 100%;
        height: 352px;
        background-color: #333333;
    }

}

/* 针对桌面端的样式 */
@media (min-width: 801px) {

    .listboxs {
        margin: 10px 100px;
    }

    .title1 {
        margin: 20px auto;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #333333;
        line-height: 36px;
        text-align: center;
    }

    .map {
        width: 100%;
        height: 452px;
        background-color: #333333;
    }

    .listbox {
        display: grid;
        /* width: 80%; */
        margin: 20px auto;
        text-align: center;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 20px;
        grid-column-gap: 20px;

    }

    .listbox1 {
        margin: 20px;
        padding: 20px;
        border: 1px solid #E5E5E5;
        text-align: left;
        width: 75%;
        cursor: pointer;
        background-size: cover;
        /* 可选，根据需要调整 */
        background-repeat: no-repeat;
        /* 可选，根据需要调整 */
        background-position: center;
        /* 可选，根据需要调整 */

    }

    .mid {
        width: 100%;
        margin: 16px 0;
        /* align-items: s; */
        justify-content: space-between;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.9);
        line-height: 18px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .mid_box {
        width: 55%;
    }

    .min_text1 {
        font-size: 16px;
        color: #111111;
        line-height: 32px;
        text-align: left;
    }

    .minspan {
        font-size: 16px;
        color: #111111;
        line-height: 32px;
        text-align: left;
        text-decoration: underline;
    }

    .cllent {
        width: 45%;
    }

    .cllent_img {
        width: 100%;
        height: auto;
    }

    .top {

        font-family: DM Serif Display, DM Serif Display;
        font-weight: 400;
        font-size: 25px;
        color: #FFFFFF;
        line-height: 30px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }


    .topimg {
        width: 100%;
        height: 236px;
        border-radius: 3px 3px 3px 3px;
    }

    .price {
        width: 166px;
        height: 36px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        color: #111111;
        /* line-height: 36px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .like {
        width: 28px;
        height: 28px;
    }

    .bottom {
        /* text-align: center; */

        align-items: center;
        margin-bottom: 10px;
        margin-top: 200px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #1495FF;
        line-height: 18px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .bottom_btn1 {
        margin: 10px auto;
        width: 100%;
        font-weight: 700;
        font-size: 16px;
        text-align: center;

        color: #FFFFFF;
        line-height: 22px;
        letter-spacing: 1px;
        padding: 10px 0;
        /* height: 52px; */
        background: #1495FF;
    }

    .bottom_btn2 {
        margin: 10px auto;
        width: 100%;
        font-weight: 700;
        color: #1495FF;
        border: 1px solid #1495FF;
        font-size: 16px;
        text-align: center;


        line-height: 22px;
        letter-spacing: 1px;
        padding: 10px 0;
        /* height: 52px; */
        background: #FFFFFF;
    }

    .name {
        max-height: 60px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 20px;
        color: #888888;
        /* line-height: 28px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .texttitle {
        margin: 20px auto;
        /* width: 217px;
    height: 24px; */
        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 20px;
        color: #111111;
        /* line-height: 24px; */
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .line {
        margin: 10px auto;
        width: 25px;
        height: 3px;
        background: #1495FF;
    }

    .title_input {
        margin: 20px auto;
        width: 200px;
    }

}


@media (max-width: 800px) {
    .about_us_m {

        /* height: 1302rpx; */
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 28rpx;
        color: #666666;
        line-height: 42rpx;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin: 40px 20px;
    }

    .title {
        text-align: center;
        margin: 20px auto;
        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 18px;
        color: #111111;
        line-height: 22px;

    }


}

/* 针对桌面端的样式 */
@media (min-width: 801px) {
    .about_us_m {
        margin: 80px 280px;
    }

    .title {
        text-align: center;
        margin: 20px auto;
        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 18px;
        color: #111111;
        line-height: 22px;

    }

}

.d_u_m_l {
    /* width: 680px;
    height: 616px; */
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    /* line-height: 28px; */
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.d_u_m_r {
    /* width: 370px; */
    /* height: 692px; */
    background: #F7FBFF;
    border: 1px solid #DDDDDD;
}

.about_title {

    font-family: Montserrat, Montserrat;
    font-weight: 700;
    font-size: 18px;
    color: #111111;
    /* line-height: 22px; */
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.about_boxs {
    margin-top: 30px;
}

.about_box {
    padding: 15px 10px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #DDDDDD;

    font-size: 16px;
    color: #1495FF;
    /* line-height: 78px; */
    text-align: center;

}

.time {
    width: 100%;

    text-align: right;
    margin: 20px auto;
    margin-right: 30px;
}

.image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.dialog {
    height: 60vh;
    /* 设置一个固定高度 */
    overflow-y: auto;
    background-color: #fff;
}

.dialog1 {
    width: 90%;
    height: 60vh;
    /* 设置一个固定高度 */
    overflow-y: auto;
    background-color: #fff;
}
</style>