<template>
  <div class="map">

    <HomePageVue :type="1"></HomePageVue>
    <div class="back mt50" @click="pageback()"><img src="../static/back.png" class="back_img" alt=""></div>
    <div class="GmapMapBox">
    
      <GmapMap @click="clickMap" :center="{ lat: Number(location.lat), lng: Number(location.lng) }" :zoom="12"
        style="width: 100%; height: 80vh">
        <!-- <GmapMarker :key="index" v-for="(m, index) in list" :position="{ lat: Number(m.lat), lng: Number(m.lng) }"
          :icon="customIcon" :zIndex="index" :title="m.house_name" :id="m.id" @click="clickMarker(m)" />
        <GmapMarker :key="index1"  :zIndex="index1" v-for="(m1, index1) in list1" :position="{ lat: Number(m1.lat), lng: Number(m1.lng) }"
          :title="m1.house_name" :id="m1.id" @click="clickMarker(m1)" /> -->
          <GmapMarker :key="index" v-for="(m, index) in list" :position="getOffsetPosition({lat: Number(m.lat), lng: Number(m.lng)},  { x: index, y:0})"
          :icon="customIcon" :zIndex="index" :title="m.house_name" :id="m.id" @click="clickMarker(m)"  />
        <GmapMarker :key="index1"  :zIndex="index1" v-for="(m1, index1) in list1"  :position="getOffsetPosition({lat: Number(m1.lat), lng: Number(m1.lng)},  { x: index1, y:0 })"
          :title="m1.house_name" :id="m1.id" @click="clickMarker(m1)" />
      </GmapMap>
    </div>
    <SeverePageVue :newdata="team_member" :buttontitle="'team_member'" :type="1"></SeverePageVue>
    <div v-if="screenWidth >= 801">
      <el-drawer :visible.sync="drawer" direction="ltr">
        <!-- <span>我来啦!</span> -->
        <div v-if="drawer">
          <div class="tiaozhuan" @click="houseDetail1(housedetal)">
            {{$t('ABOUT_THIS_PROPERTY')}}
          </div>
          <div class="detail ">
            <div class="detail_l">
              <div class="d_l_t">
                <el-carousel trigger="click" height="321px" :autoplay="false" indicator-position="none">
                  <el-carousel-item v-for="item in housedetal.house_image_text" :key="item">
                    <img :src="item" class="w650" alt="">
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="d_l_m">
                <div class="d_l_m_t ">
                  <div v-if="house_stats == 1">
                    <div class="price" v-if="$i18n.locale == 'en'">
                      £{{ housedetal.en_house_price }}

                    </div>
                    <div class="price" v-else>
                      ￥{{ housedetal.house_price }}

                    </div>
                  </div>
                  <div v-else>
                    <div class="price" v-if="$i18n.locale == 'en'">
                      £{{ housedetal.en_week_rent }}/week

                    </div>
                    <div class="price" v-else>
                      ￥{{ housedetal.week_rent }}/week

                    </div>
                    <div>
                      <div class="price" v-if="$i18n.locale == 'en'">
                        £{{ housedetal.en_month_rent }}/month

                      </div>
                      <div class="price" v-else>
                        ￥{{ housedetal.month_rent }}/month

                      </div>
                    </div>
                  </div>
                  <div class="bottons flex1">
                    <div class="botton1 flex1" @click="save(1)" v-if="housedetal.is_collect == 0">
                      <img src="../static//index/like.png" alt="" class="w20">
                      {{ $t('Save') }}
                    </div>
                    <div class="botton1 flex1" @click="save(2)" v-else>
                      <img src="../static//index/like1.png" alt="" class="w20">
                      {{ $t('Save') }}
                    </div>
                    <div class="botton1 flex1" @click="share()">
                      <img src="../static//index/share.png" alt="" class="w20">

                      {{ $t('Share') }}
                    </div>
                  </div>
                </div>
                <div class="d_l_m_t1">
                  <div class="name"> {{ $i18n.locale == 'en' ? housedetal.en_house_name : housedetal.house_name }}
                    </div>
                </div>

                <!-- <div class="d_l_m_t2">
                  {{ $t('ABOUT_THIS_PROPERTY') }}
                </div> -->
                <div v-html="housedetal.content"></div>
                <div class="types" v-if="house_stats == 1">
                  <div class="type">
                    <div class="type_name">
                      {{ $t('PROPERTY_TYPE') }}
                    </div>
                    <div class="type_num flex-box">
                      <img src="../static/home.png" class="w20" alt="">
                      {{ $i18n.locale == 'en' ? housedetal.hp_en_name :
      housedetal.hp_name }}
                    </div>
                  </div>
                  <div class="type">
                    <div class="type_name">
                      {{ $t('BEDROOMS') }}
                    </div>
                    <div class="type_num flex-box">
                      <img src="../static/home1.png" class="w20" alt="">
                      {{ $i18n.locale == 'en' ? housedetal.room_number :
      housedetal.room_number }}

                    </div>

                  </div>

                  <div class="type">
                    <div class="type_name">
                      {{ $t('BATHROOMS') }}
                    </div>
                    <div class="type_num flex-box">
                      <img src="../static/home2.png" class="w20" alt="">
                      {{ $i18n.locale == 'en' ? housedetal.lavatory_number :
      housedetal.lavatory_number }}

                    </div>

                  </div>

                  <div class="type">
                    <div class="type_name">
                      {{ $t('SIZE') }}
                    </div>
                    <div class="type_num flex-box">
                      <img src="../static/home3.png" class="w20" alt="">
                      {{ $i18n.locale == 'en' ? housedetal.en_area :
      housedetal.zh_area }}

                    </div>

                  </div>
                </div>
                <div class="types" v-else>
                  <div class="type">
                    <div class="type_name">
                      {{ $t('PROPERTY_TYPE') }}
                    </div>
                    <div class="type_num flex-box">
                      <img src="../static/home.png" class="w20" alt="">
                      {{ $i18n.locale == 'en' ? housedetal.hp_en_name :
      housedetal.hp_name }}
                    </div>
                  </div>
                  <div class="type">
                    <div class="type_name">
                      {{ $t('BEDROOMS') }}
                    </div>
                    <div class="type_num flex-box">
                      <img src="../static/home1.png" class="w20" alt="">
                      {{ $i18n.locale == 'en' ? housedetal.room_number :
      housedetal.room_number }}

                    </div>

                  </div>

                  <div class="type">
                    <div class="type_name">
                      {{ $t('BATHROOMS') }}
                    </div>
                    <div class="type_num flex-box">
                      <img src="../static/home2.png" class="w20" alt="">
                      {{ $i18n.locale == 'en' ? housedetal.lavatory_number :
      housedetal.lavatory_number }}

                    </div>

                  </div>

                  <div class="type">
                    <div class="type_name">
                      {{ $t('SIZE') }}
                    </div>
                    <div class="type_num flex-box">
                      <img src="../static/home3.png" class="w20" alt="">
                      {{ $i18n.locale == 'en' ? housedetal.en_area :
      housedetal.zh_area }}

                    </div>

                  </div>
                </div>
                <!-- <div class="d_l_m_t2">
                            {{ $t('Map') }}
                        </div> -->
                <div class="fliess ">
                  <div class="files11">
                    <div class="files" v-if="housedetal.file_arr.length < 4">
                      <div class="flie flex-box" v-for="(item, index) in housedetal.file_arr" :key="index">
                        <!-- <div class="flie flex-box" v-for="(item, index) in 3" :key="index" > -->
                        {{ item.name }}

                        <img src="../assets/downlond.png" class="w21" alt="" @click="downloadFile(item.url, item.name)">
                      </div>
                      <!-- <div class="flie1 "  @click="downloadFile1()">
                                        {{ $t('DOWNLOAD_ALL') }}
                                    </div> -->
                    </div>
                    <div class="files" v-else>
                      <div class="flie flex-box" v-for="(item, index) in housedetal.file_arr.slice(0, 3)" :key="index">
                        <!-- <div class="flie flex-box" v-for="(item, index) in 3" :key="index" > -->
                        {{ item.name }}

                        <img src="../assets/downlond.png" class="w21" alt="" @click="downloadFile(item.url, item.name)">
                      </div>
                      <div class="flie1 " @click="downloadFile1()">
                        {{ $t('DOWNLOAD_ALL') }}
                      </div>
                    </div>
                  </div>

                  <div class="images " v-if="housedetal.house_image_text.length < 8">
                    <div class="image1" v-for="(item, index) in housedetal.house_image_text" :key="index">
                      <el-image style="width: 88px; height:88px" class="w88" :src="item"
                        :preview-src-list="housedetal.house_image_text">
                      </el-image>

                    </div>


                  </div>
                  <div class="images " v-else>
                    <div class="image1" v-for="(item, index) in housedetal.house_image_text.slice(0, 7)" :key="index">
                      <el-image style="width: 88px; height:88px" class="w88" :src="item"
                        :preview-src-list="housedetal.house_image_text">
                      </el-image>

                    </div>


                    <div class="image1">

                      +{{ housedetal.house_image_text.length }}
                    </div>
                  </div>

                </div>


                <!-- <div class="d_l_m_t2">
                            {{ $t('TRAIN_STATIONS') }}
                        </div>
                        <div class="fujinlist" v-for="(item, index) in fujinlist" :key="index">
                            <li>{{ item.name }}
                                <span class="miles"> ({{ item.miles }})miles</span>
                            </li>
                        </div> -->

              </div>
              <div class="look_full" @click="houseDetail1(housedetal)">
                {{ $t('See_full_property_details') }}
              </div>
              <div class="line_b"></div>
              <div class="simi">
                <div class="simititle">
                  Nearby properties matching your criteria
                </div>
                <div class="simiboxs">
                  <div class="simibox" v-for="(item, index) in listSimi" :key="index" @click="houseDetail1(item)">
                    <div class="sb_l">
                      <img :src="item.house_image_text[0]" class="w120" alt="">
                    </div>
                    <div class="sb_r">
                      <div class="title1" v-if="$i18n.locale == 'en'">
                        {{ item.en_house_name }}
                      </div>
                      <div class="title1" v-else>
                        {{ item.house_name }}
                      </div>
                      <div class="title2">
                        {{ item.address }}
                      </div>
                      <div v-if="house_stats == 1">
                        <div class="title3" v-if="$i18n.locale == 'en'">
                          £{{ item.en_house_price }}

                        </div>
                        <div class="title3" v-else>
                          ￥{{ item.house_price }}

                        </div>
                      </div>
                      <div v-else>
                        <div class="title3" v-if="$i18n.locale == 'en'">
                          £{{ item.en_week_rent }}/week

                        </div>
                        <div class="title3" v-else>
                          ￥{{ item.week_rent }}/week

                        </div>
                        <div>
                          <div class="title3" v-if="$i18n.locale == 'en'">
                            £{{ item.en_month_rent }}/month

                          </div>
                          <div class="title3" v-else>
                            ￥{{ item.month_rent }}/month

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>



            </div>

          </div>
        </div>
      </el-drawer>
    </div>
    <div v-else>
      <el-drawer  :visible.sync="drawer" direction="btt" size='80%'>
        <!-- <span>我来啦!</span> -->
      
        <div v-if="drawer">
          <div class="tiaozhuan" @click="houseDetail1(housedetal)">
            {{$t('ABOUT_THIS_PROPERTY')}}
          </div>
          <div class="detail ">
            <div class="detail_l">
              <div class="d_l_t">
                <el-carousel trigger="click" height="321px" :autoplay="false" indicator-position="none">
                  <el-carousel-item v-for="item in housedetal.house_image_text" :key="item">
                    <img :src="item" class="w650" alt="">
                  </el-carousel-item>
                </el-carousel>
                <!-- <img :src="housedetal.house_image_text" alt="" class="w920"> -->
              </div>
              <div class="d_l_m">
                <div class="d_l_m_t ">
                  <div v-if="house_stats == 1">
                    <div class="price" v-if="$i18n.locale == 'en'">
                      £{{ housedetal.en_house_price }}

                    </div>
                    <div class="price" v-else>
                      ￥{{ housedetal.house_price }}

                    </div>
                  </div>
                  <div v-else>
                    <div class="price" v-if="$i18n.locale == 'en'">
                      £{{ housedetal.en_week_rent }}/week

                    </div>
                    <div class="price" v-else>
                      ￥{{ housedetal.week_rent }}/week

                    </div>
                    <div>
                      <div class="price" v-if="$i18n.locale == 'en'">
                        £{{ housedetal.en_month_rent }}/month

                      </div>
                      <div class="price" v-else>
                        ￥{{ housedetal.month_rent }}/month

                      </div>
                    </div>
                  </div>
                  <div class="bottons flex1">
                    <div class="botton1 flex1" @click="save(1)" v-if="housedetal.is_collect == 0">
                      <img src="../static//index/like.png" alt="" class="w20">
                      {{ $t('Save') }}
                    </div>
                    <div class="botton1 flex1" @click="save(2)" v-else>
                      <img src="../static//index/like1.png" alt="" class="w20">
                      {{ $t('Save') }}
                    </div>
                    <div class="botton1 flex1" @click="share()">
                      <img src="../static//index/share.png" alt="" class="w20">

                      {{ $t('Share') }}
                    </div>
                  </div>
                </div>
                <div class="d_l_m_t1">
                  {{ housedetal.alias_name }}
                </div>
                <!-- <div class="d_l_m_t2">
                  {{ $t('ABOUT_THIS_PROPERTY') }}
                </div> -->
                <div v-html="housedetal.content"></div>
                <div class="types" v-if="house_stats == 1">
                  <div class="type">
                    <div class="type_name">
                      {{ $t('PROPERTY_TYPE') }}
                    </div>
                    <div class="type_num flex-box">
                      <img src="../static/home.png" class="w20" alt="">
                      {{ $i18n.locale == 'en' ? housedetal.hp_en_name :
      housedetal.hp_name }}
                    </div>
                  </div>
                  <div class="type">
                    <div class="type_name">
                      {{ $t('BEDROOMS') }}
                    </div>
                    <div class="type_num flex-box">
                      <img src="../static/home1.png" class="w20" alt="">
                      {{ $i18n.locale == 'en' ? housedetal.room_number :
      housedetal.room_number }}

                    </div>

                  </div>

                  <div class="type">
                    <div class="type_name">
                      {{ $t('BATHROOMS') }}
                    </div>
                    <div class="type_num flex-box">
                      <img src="../static/home2.png" class="w20" alt="">
                      {{ $i18n.locale == 'en' ? housedetal.lavatory_number :
      housedetal.lavatory_number }}

                    </div>

                  </div>

                  <div class="type">
                    <div class="type_name">
                      {{ $t('SIZE') }}
                    </div>
                    <div class="type_num flex-box">
                      <img src="../static/home3.png" class="w20" alt="">
                      {{ $i18n.locale == 'en' ? housedetal.en_area :
      housedetal.zh_area }}

                    </div>

                  </div>
                </div>
                <div class="types" v-else>
                  <div class="type">
                    <div class="type_name">
                      {{ $t('PROPERTY_TYPE') }}
                    </div>
                    <div class="type_num flex-box">
                      <img src="../static/home.png" class="w20" alt="">
                      {{ $i18n.locale == 'en' ? housedetal.hp_en_name :
      housedetal.hp_name }}
                    </div>
                  </div>
                  <div class="type">
                    <div class="type_name">
                      {{ $t('BEDROOMS') }}
                    </div>
                    <div class="type_num flex-box">
                      <img src="../static/home1.png" class="w20" alt="">
                      {{ $i18n.locale == 'en' ? housedetal.room_number :
      housedetal.room_number }}

                    </div>

                  </div>

                  <div class="type">
                    <div class="type_name">
                      {{ $t('BATHROOMS') }}
                    </div>
                    <div class="type_num flex-box">
                      <img src="../static/home2.png" class="w20" alt="">
                      {{ $i18n.locale == 'en' ? housedetal.lavatory_number :
      housedetal.lavatory_number }}

                    </div>

                  </div>

                  <div class="type">
                    <div class="type_name">
                      {{ $t('SIZE') }}
                    </div>
                    <div class="type_num flex-box">
                      <img src="../static/home3.png" class="w20" alt="">
                      {{ $i18n.locale == 'en' ? housedetal.en_area :
      housedetal.zh_area }}

                    </div>

                  </div>
                </div>
                <!-- <div class="d_l_m_t2">
                            {{ $t('Map') }}
                        </div> -->
                <div class="fliess ">
                  <div class="files11">
                    <div class="files" v-if="housedetal.file_arr.length < 4">
                      <div class="flie flex-box" v-for="(item, index) in housedetal.file_arr" :key="index">
                        <!-- <div class="flie flex-box" v-for="(item, index) in 3" :key="index" > -->
                        {{ item.name }}

                        <img src="../assets/downlond.png" class="w21" alt="" @click="downloadFile(item.url, item.name)">
                      </div>
                      <!-- <div class="flie1 "  @click="downloadFile1()">
                                        {{ $t('DOWNLOAD_ALL') }}
                                    </div> -->
                    </div>
                    <div class="files" v-else>
                      <div class="flie flex-box" v-for="(item, index) in housedetal.file_arr.slice(0, 3)" :key="index">
                        <!-- <div class="flie flex-box" v-for="(item, index) in 3" :key="index" > -->
                        {{ item.name }}

                        <img src="../assets/downlond.png" class="w21" alt="" @click="downloadFile(item.url, item.name)">
                      </div>
                      <div class="flie1 " @click="downloadFile1()">
                        {{ $t('DOWNLOAD_ALL') }}
                      </div>
                    </div>
                  </div>

                  <div class="images " v-if="housedetal.house_image_text.length < 8">
                    <div class="image1" v-for="(item, index) in housedetal.house_image_text" :key="index">
                      <el-image style="width: 88px; height:88px" class="w88" :src="item"
                        :preview-src-list="housedetal.house_image_text">
                      </el-image>

                    </div>


                  </div>
                  <div class="images " v-else>
                    <div class="image1" v-for="(item, index) in housedetal.house_image_text.slice(0, 7)" :key="index">
                      <el-image style="width: 88px; height:88px" class="w88" :src="item"
                        :preview-src-list="housedetal.house_image_text">
                      </el-image>

                    </div>


                    <div class="image1">

                      +{{ housedetal.house_image_text.length }}
                    </div>
                  </div>

                </div>


                <!-- <div class="d_l_m_t2">
                            {{ $t('TRAIN_STATIONS') }}
                        </div>
                        <div class="fujinlist" v-for="(item, index) in fujinlist" :key="index">
                            <li>{{ item.name }}
                                <span class="miles"> ({{ item.miles }})miles</span>
                            </li>
                        </div> -->

              </div>
              <div class="look_full" @click="houseDetail1(housedetal)">
                {{ $t('See_full_property_details') }}
              </div>
              <div class="line_b"></div>
              <div class="simi">
                <div class="simititle">
                  Nearby properties matching your criteria
                </div>
                <div class="simiboxs">
                  <div class="simibox" v-for="(item, index) in listSimi" :key="index" @click="houseDetail1(item)">
                    <div class="sb_l">
                      <img :src="item.house_image_text[0]" class="w120" alt="">
                    </div>
                    <div class="sb_r">
                      <div class="title1" v-if="$i18n.locale == 'en'">
                        {{ item.en_house_name }}
                      </div>
                      <div class="title1" v-else>
                        {{ item.house_name }}
                      </div>
                      <div class="title2">
                        {{ item.address }}
                      </div>
                      <div v-if="house_stats == 1">
                        <div class="title3" v-if="$i18n.locale == 'en'">
                          £{{ item.en_house_price }}

                        </div>
                        <div class="title3" v-else>
                          ￥{{ item.house_price }}

                        </div>
                      </div>
                      <div v-else>
                        <div class="title3" v-if="$i18n.locale == 'en'">
                          £{{ item.en_week_rent }}/week

                        </div>
                        <div class="title3" v-else>
                          ￥{{ item.week_rent }}/week

                        </div>
                        <div>
                          <div class="title3" v-if="$i18n.locale == 'en'">
                            £{{ item.en_month_rent }}/month

                          </div>
                          <div class="title3" v-else>
                            ￥{{ item.month_rent }}/month

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>



            </div>

          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>

const markerSvg = window.btoa(`
    <svg width="25" height="35" viewBox="0 0 32 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 0C7.1648 0 0 7.032 0 15.704C0 24.512 7 30.5328 16 38.4C25 30.5328 32 24.512 32 15.704C32 7.032 24.8352 0 16 0Z" fill="#0063CC"/>
<circle cx="16" cy="17" r="8" fill="white"/>
</svg>
`)

import HomePageVue from '@/components/HomePage.vue';

import SeverePageVue from '@/components/SeverePage.vue';

import { houseDetail, renthouseDetail, houseList, renthouseList, houseSimi, renthouseSimi, houseCollect, houseConsult, houseCompute, imgText } from '../request/api'
export default {
  name: "GoogleMap",
  components: {
    HomePageVue,

    SeverePageVue,

  },
  data() {
    return {
      screenWidth: document.body.clientWidth,//初始化宽度
      show1: true,
      show2: true,
      show3: true,
      zixunshow: false,
      houseid: 0,
      houeseid: 0,
      housedetal: {

      },
      center: { lat: 37.7749, lng: -122.4194 },
      zoom: 12,
      // mapOptions: {
      //   // mapTypeId: gmapApi.MapTypeId.ROADMAP,
      //   streetViewControl: false,
      // },
      fujinlist: [
        { name: 'Caterham', miles: '0.3' },
        { name: 'Whyteleafe South', miles: '1.2' },
        { name: 'Woldingham', miles: '1.2' },
        { name: 'Whyteleafe South- Main Road', miles: '1.2' },
        { name: 'Upper Warlingham', miles: '1.2' },
        // {name:'Woldingham',miles:'1.2'},

      ],
      list1: [],
      Mortgage: {
        Price: '',
        Deposit: '',
        Loan_term: '',
        Interest_rate: '',

      },
      Yield: {

        Property_value: 0,
        Annual_costs: 0,
        Monthly_rent: 0,

      },
      Stamp: {

        Property_value: '',
        q1: true,
        q2: false

      },
      Stampprice: 0,
      // Mortgageprice:0,
      putenquiryshow: false,
      enquiry: {
        First_name: '',
        Last_name: '',
        Phone: '',
        Email: '',


      },
      drawer: false,
      direction: 'ltr',
      location: {}, // 中心位置
      center_: { lat: 10.0, lng: 10.0 }, // 保存当前点位置
      currentPlace: null,
      list: [],
      places: [],
      placeName: "",
      dialogVisible: true,
      googlemap: "",
      hasSetPin: false,
      icon: '',

      page: 1,
      our_service: {},
      contact_us: {},
      about_us: {},
      our_team: {},
      join_team: {},
      tenement: {},

      buy_house: {},
      rent_house: {},
      flat: {},
      fitment: {},
      sell_house: {},
      loan: {},
      team_member: {},
      house_stats: 0,
      customIcon: {
        url: `data:image/svg+xml;base64,${markerSvg}`, // 图标的路径
        size: { width: 25, height: 35 }, // 图标的大小

      },
      mapOptions: {
        mapTypeId: 'terrain',
        zoom: 8,
        fullscreenControl: false
      },
      listSimi: [],
      bounds: null

    };
  },
  props: ['gpsName'],
  // 在目标路由的组件中
  created() {

    console.log('this.$route.query.name', this.$route.query.name)
    this.getdata()
    this.getdata1()
    this.initMap()

  },
  computed: {
    Net() {
      return (this.Yield.Monthly_rent * 12 - this.Yield.Annual_costs*100) / this.Yield.Property_value ? (this.Yield.Monthly_rent * 12 - this.Yield.Annual_costs*100) / this.Yield.Property_value : 0
    },
    Gross() {
      return (this.Yield.Monthly_rent * 12) / this.Yield.Property_value ? (this.Yield.Monthly_rent * 12) / this.Yield.Property_value : 0
    },
    Mortgageprice() {
      return Math.floor((this.Mortgage.Price - this.Mortgage.Deposit) * (((this.Mortgage.Interest_rate * 0.01) / 12)) * Math.pow((1 + (((this.Mortgage.Interest_rate * 0.01) / 12))), this.Mortgage.Loan_term * 12) / (Math.pow((1 + (((this.Mortgage.Interest_rate * 0.01) / 12))), this.Mortgage.Loan_term * 12) - 1))
    },

    getformInline() {
      return this.$store.state.formInline;
    },


  }
  ,

  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()

    }
    console.log(that.$route.params.houseid, 'hhhhhhhhhhhhhhhhhhhhhhhhhhhhh'); // 输出路由参数
    that.houseid = that.$route.params.houseid
    let newContent = this.housedetal.content.replace(/<img[^>]*>/gi, function (match, capture) {
      match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
      match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
      match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
      console.log(capture)
      return match;
    });
    newContent = newContent.replace(/style="[^"]+"/gi, function (match, capture) {
      match = match.replace(/width:[^;]+;/gi, 'width:100%;').replace(/width:[^;]+;/gi, 'width:100%;');
      console.log(capture)

      return match;
    });
    newContent = newContent.replace(/<br[^>]*\/>/gi, '');
    newContent = newContent.replace(/<img/gi, '<img style="width:100%;height:auto;"');
    this.housedetal.content = newContent
  },

  // props: {
  //     houseid: Number,

  // },
  watch: {
    // 监听message数据的变化
    // houseid(newValue, oldValue) {
    //   console.log(`message changed from ${oldValue} to ${newValue}`);
    //   // 在这里可以根据新的message值执行相应的操作
    //   this.getdatail(newValue)
    //   this.gethouseSimi(newValue)

    // },
    Yield: {
      handler: function (newValue, oldValue) {
        // 当nestedData或其内部属性变化时，会调用这个函数
        console.log('oldValue', oldValue)
        console.log('newValue', newValue)
        this.$store.commit('updateformInline', this.formInline)
      },
      deep: true // 开启深度监听
    },
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      this.screenWidth = val
      this.timer = true
    },
    Stampprice(value) {
      console.log('value', value)
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2)
      console.log('realVal', realVal)
      this.Stampprice = realVal
    },
    getMarkersInsideBounds() {
      if (this.bounds) {
        const insideMarkers = this.markers.filter(marker => {
          return this.bounds.contains(marker.position);
        });
        console.log(insideMarkers); // 这里你会得到在当前视图范围内的所有标记点
      }
    },
    getformInline: {
      handler: function (newValue, oldValue) {
        // 当nestedData或其内部属性变化时，会调用这个函数
        console.log('oldValue', oldValue)
        console.log('newValue', newValue)
        this.getdata()
        // this.$store.commit('updateformInline', newValue)
      },
      deep: true // 开启深度监听
    }


  },
  methods: {
    
    async getdata1() {
      await imgText().then(res => {

        if (res.data) {
          this.our_service = res.data.our_service
          this.contact_us = res.data.contact_us
          this.about_us = res.data.about_us
          this.our_team = res.data.our_team
          this.join_team = res.data.join_team
          this.tenement = res.data.tenement
          this.buy_house = res.data.buy_house
          this.rent_house = res.data.rent_house
          this.flat = res.data.flat
          this.fitment = res.data.fitment
          this.sell_house = res.data.sell_house
          this.loan = res.data.loan
          this.team_member = res.data.team_member



        }

      })
      // await banner({
      //     type: 0
      // }).then(res => {

      //     console.log('res', res)

      // })
    },
    pageback() {
      this.$router.go(-1);
    },
    async getdata() {
      var that = this
      console.log('getformInline', that.getformInline)
      if (that.getformInline.sell_type != 1) {
        await renthouseList({
          limit: 1000, //每页展示的条数
          page: that.page, //页码
          lng: that.getformInline.location ? that.getformInline.location.lng : that.location.lng, //经度
          lat: that.getformInline.location ? that.getformInline.location.lat : that.location.lat, //纬度
          distance_id: that.getformInline.Distance ? that.getformInline.Distance : '', //距离Id
          house_type_key: that.getformInline.house_type_key ? that.getformInline.house_type_key : '', //房屋分类key
          house_pattern_id: that.getformInline.Type ? that.getformInline.Type : '', //房屋房型ID
          house_size_id: that.getformInline.Min_bedrooms ? that.getformInline.Min_bedrooms : '', //房屋户型ID
          house_min_price: that.getformInline.Min_Price ? that.getformInline.Min_Price : '', //最低售价
          house_max_price: that.getformInline.Max_Price ? that.getformInline.Max_Price : '', //最高售价
          rent_type_id: that.getformInline.rent_type_id ? that.getformInline.rent_type_id : '', //出租
          sell_type: that.getformInline.sell_type ? that.getformInline.sell_type : '', //售价
          is_sell: that.getformInline.is_sell ? Number() : '', //售价
          house_order_type: that.getformInline.pricetype ? that.getformInline.pricetype : 1,//排序方式ID
          is_collect: that.getformInline.is_collect ? that.getformInline.is_collect : '',//排序方式ID
          min_week_rent: that.getformInline.min_week_rent ? that.getformInline.min_week_rent : '', //周租金最低金额
          max_week_rent: that.getformInline.max_week_rent ? that.getformInline.max_week_rent : '', //周租金最高金额
          min_month_rent: that.getformInline.min_month_rent ? that.getformInline.min_month_rent : '', //月租金最低金额
          max_month_rent: that.getformInline.max_month_rent ? that.getformInline.max_month_rent : '', //月租金最高金额
          min_available_date: that.getformInline.min_available_date ? that.getformInline.min_available_date : '', //最小入驻日期
          max_available_date: that.getformInline.max_available_date ? that.getformInline.max_available_date : '',//最大入驻日期
        }).then(res => {
          this.list1 = []
          // console.log(this.list)
          var pagelist = []
          pagelist = res.data.house_list
          if (this.page == 1) {
            this.list = pagelist
          }
          else {
            this.list = this.list.concat(pagelist)
          }
          // this.$message({
          //   message: '房源已更新',
          //   type: 'success'
          // });
        })
      }
      else {
        await houseList({
          limit: 1000, //每页展示的条数
          page: this.page, //页码
          lng: that.getformInline.location ? that.getformInline.location.lng : that.location.lng, //经度
          lat: that.getformInline.location ? that.getformInline.location.lat : that.location.lat, //纬度
          distance_id: that.getformInline.Distance ? that.getformInline.Distance : '', //距离Id
          house_type_key: that.getformInline.house_type_key ? that.getformInline.house_type_key : '', //房屋分类key
          house_pattern_id: that.getformInline.Type ? that.getformInline.Type : '', //房屋房型ID
          house_size_id: that.getformInline.Min_bedrooms ? that.getformInline.Min_bedrooms : '', //房屋户型ID
          house_min_price: that.getformInline.Min_Price ? that.getformInline.Min_Price : '', //最低售价
          house_max_price: that.getformInline.Max_Price ? that.getformInline.Max_Price : '', //最高售价
          rent_type_id: that.getformInline.rent_type_id ? that.getformInline.rent_type_id : '', //出租
          sell_type: that.getformInline.sell_type ? that.getformInline.sell_type : '', //售价
          is_sell: that.getformInline.is_sell ? Number() : '', //售价
          house_order_type: that.getformInline.pricetype ? that.getformInline.pricetype : 1,//排序方式ID
          is_collect: that.getformInline.is_collect ? that.getformInline.is_collect : ''//排序方式ID
        }).then(res => {
          // this.list = res.data.house_list
          // console.log(this.list)
          this.list = []

          var pagelist = []
          pagelist = res.data.house_list
          if (this.page == 1) {
            this.list1 = pagelist
          }
          else {
            this.list1 = this.list.concat(pagelist)
          }
          // this.$message({
          //   message: '房源已更新',
          //   type: 'success'
          // });
        })
      }


    },
    async getdata0() {
      var that = this
      console.log('getformInline', this.getformInline)
      await houseList({
        limit: 10, //每页展示的条数
        page: this.page, //页码
        lng: that.location.lng, //经度
        lat: that.location.lat, //纬度
        house_type_key: '', //房屋分类key
        house_pattern_id: '', //房屋房型ID
        house_size_id: '', //房屋户型ID
        house_min_price: '', //最低售价
        house_max_price: '', //最高售价
        rent_type_id: '', //出租
        sell_type: '', //售价
        is_sell: '', //售价
        house_order_type: 1,//排序方式ID
        is_collect: ''//排序方式ID

      }).then(res => {
        // this.list = res.data.house_list
        console.log(res)
        var pagelist = []
        pagelist = res.data.house_list
        if (that.page == 1) {
          that.list = pagelist
        }
        else {
          that.list = that.list.concat(pagelist)
        }
        // this.$message({
        //   message: '房源已更新',
        //   type: 'success'
        // });
      })

    },
    async getdatail(id) {
      await houseDetail({
        house_id: id
      }).then(res => {
        this.housedetal = res.data
        console.log(this.housedetal)
        this.drawer = true
      })
    },
    async getdatail1(id) {
      await renthouseDetail({
        house_id: id
      }).then(res => {
        this.housedetal = res.data
        console.log(this.housedetal)
        this.drawer = true
      })
    },
    initMap() {
      var that = this
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            that.location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            console.log('location', that.location)
            if (that.location) {
              // that.getdata()
            }
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },
    handleClose() { },
    setPlace(place) {
      this.currentPlace = place;
      this.addMarkerFun();
    },
    addMarker() {
      this.addMarkerFun();
    },
    addMarkerFun() {
      if (this.currentPlace) {
        this.hasSetPin = true
        this.zoom = 10
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        // this.list = [];
        // this.list.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.center_ = marker;
        this.placeName = this.currentPlace.name;
        this.currentPlace = null;
      }
    },


    clickMap(e) {
      this.hasSetPin = true
      let longlat = e.latLng.lat() + "," + e.latLng.lng();
      console.log('longlat哈啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊', longlat)
      console.log('e', e)
      this.center_ = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
      // this.list = [];
      // this.list.push({ position: this.center_ });
    },
    clickMarker(val) {
      console.log('val', val)
      this.center = val.position;
      this.house_stats = val.house_stats
      this.houseid = val.id
      if (val.house_stats == 1) {

        this.getdatail(val.id)
        this.gethouseSimi(val.id, val.house_stats)
      }
      else {
        this.getdatail1(val.id)
        this.gethouseSimi(val.id, val.house_stats)

      }

    },
    submitMap() {
      if (!this.hasSetPin) {
        this.msgError(this.$t("googlemap.searchAndAdd"));
        return
      }
      let obj = Object.assign({}, this.center_);
      obj.name = `${this.center_.lat.toFixed(5)},${this.center_.lng.toFixed(5)}`;
      this.$emit("setMap", obj);
    },
    cancelMap() {
      this.$emit("closeMap");
    },
    async getimgtext() {
      await imgText().then(res => {
        if (res.data) {
          this.our_service = res.data.our_service
          this.contact_us = res.data.contact_us
          this.about_us = res.data.about_us
          this.our_team = res.data.our_team
          this.join_team = res.data.join_team
          this.tenement = res.data.tenement
          this.buy_house = res.data.buy_house
          this.rent_house = res.data.rent_house
          this.flat = res.data.flat
          this.fitment = res.data.fitment
          this.sell_house = res.data.sell_house
          this.loan = res.data.loan
          this.team_member = res.data.team_member



        }

      })
    },
    async save(type) {
      var action
      if (type == 1) {
        action = 'collect'
      }
      else {
        action = 'uncollect'
      }
      await houseCollect({
        action: action,
        house_id_arr: this.housedetal.id
      }).then(res => {
        if (type == 1) {
          this.$message({
            message: 'Collection successful',
            type: 'success'
          });
        }
        else {
          this.$message({
            message: 'Collection canceled',
            type: 'success'
          });
        }

        this.getdatail(this.housedetal.id)
        console.log(res, 'resresresres')
      })
    },
    zixun() {
      this.zixunshow = true
    },
    async zixun1() {
      if (this.enquiry.First_name && this.enquiry.Last_name && this.enquiry.Phone && this.enquiry.Email) {
        await houseCompute({
          name: this.enquiry.First_name, //名
          surname: this.enquiry.Last_name, //姓
          mobile: this.enquiry.Phone, //手机号
          email: this.enquiry.Email, //邮箱
          house_id: this.housedetal.id, //房源ID

          total_price: this.Mortgage.Price, //房源总价
          down_payment: this.Mortgage.Deposit, //首付金额

          loan_life: this.Mortgage.Loan_term, //贷款年限

          interest: this.Mortgage.Interest_rate, //利息


          annual_costs: this.Yield.Annual_costs, //年度成本

          monthly_rent: this.Yield.Monthly_rent, //每月租金

          first_buy: this.Stamp.q1 ? 1 : 2,//是否第一次买房
          is_native: this.Stamp.q2 ? 1 : 2 //是否第一次买房
        }).then(res => {

          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.zixunshow = false
          this.enquiry = {
            First_name: '',
            Last_name: '',
            Phone: '',
            Email: '',
          }
          console.log(res, 'resresresres')
        })
      }
      else {
        if (this.$i18n.locale == 'en') {
          this.$message({
            message: 'Required fields cannot be empty',
            type: 'success'
          });
        }
        else {
          this.$message({
            message: '必填项不能为空',
            type: 'success'
          });
        }

      }

    },
    // getHouseId(data) {
    //   console.log('父组件接收到的数据：' + data)
    //   this.houseid = data
    //   this.$router.push({ name: 'HouseDetail', params: { houseid: this.houseid } })
    // },
    getpage(data) {
      console.log('父组件接收到的数据：' + data)
      if (data) {
        this.page = this.page + 1
        this.gethouseSimi(this.housedetal.id)
      }

    },
    open1() {
      this.$notify({
        title: '成功',
        message: '这是一条成功的提示消息',
        type: 'success'
      });
    },
    share() {

      var fullUrl = window.location.href;
      console.log(fullUrl);
      var input = document.createElement('input') // 创建input标签
      input.value = fullUrl; // 将input的值设置为需要复制的内容
      document.body.appendChild(input) // 添加input标签
      input.select()  // 选中input标签
      document.execCommand('copy') // 执行复制
      // this.$message.success('success!') // 成功提示信息
      document.body.removeChild(input) // 移除input标签

      this.$notify({
        title: 'Copy successful',
        message: fullUrl,
        type: 'success'
      });
    },
    houseDetail1(data) {
      console.log(this.housedetal, this.house_stats, "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&")

      var houseid11, house_stats

      houseid11 = data.id
      house_stats = this.house_stats
      // this.$emit('HouseId', { houseid: this.housedetal.id, house_stats:  this.house_stats })
      console.log(houseid11, house_stats, '+++++++++++++++++++++++++haaaaaaaaaaaaaaaaaaaa')
      this.$router.push({
        name: 'HouseDetail', params: {
          houseid: {
            houseid: houseid11, house_stats: house_stats
          }
        }
      })

    },
    gettel(tel) {


      var input = document.createElement('input') // 创建input标签
      input.value = tel; // 将input的值设置为需要复制的内容
      document.body.appendChild(input) // 添加input标签
      input.select()  // 选中input标签
      document.execCommand('copy') // 执行复制
      // this.$message.success('success!') // 成功提示信息
      document.body.removeChild(input) // 移除input标签

      this.$notify({
        title: 'Copy successful',
        message: tel,
        type: 'success'
      });
    },

    async gethouseSimi(id, house_stats) {
      if (house_stats == 2) {
        await renthouseSimi({
          limit: 6,
          page: this.page,
          house_id: id
        }).then(res => {
          var pagelist = []
          pagelist = res.data.house_list
          if (this.page == 1) {
            this.listSimi = pagelist
          }
          else {
            this.listSimi = this.listSimi.concat(pagelist)
          }

          console.log(this.housedetal)
        })

      }
      else {
        await houseSimi({
          limit: 1000,
          page: this.page,
          house_id: id
        }).then(res => {
          var pagelist = []
          pagelist = res.data.house_list
          if (this.page == 1) {
            this.listSimi = pagelist
          }
          else {
            this.listSimi = this.listSimi.concat(pagelist)
          }

          console.log(this.listSimi)
        })
      }

    },
    async downloadFile(url, name) {
      let response = await fetch(url)
      // 内容转变成blob地址
      let blob = await response.blob()
      // 创建隐藏的可下载链接
      let objectUrl = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.style.display = "none";
      //地址
      a.href = objectUrl
      //修改文件名
      a.download = name
      // 触发点击
      document.body.appendChild(a)
      a.click()
      //移除
      URL.revokeObjectURL(a.href);
    },
    getOffsetPosition(position, offset) {
      return {
        lat: position.lat + offset.y / 111320, // 1度纬度约等于111320米
        lng: position.lng + offset.x / (111320 * Math.cos(position.lat * Math.PI / 180))
      };
    },

    async putenquiry() {
      this.putenquiryshow = true
    },
    async putenquiry1() {
      console.log(this.enquiry)

      if (this.enquiry.First_name && this.enquiry.Last_name && this.enquiry.Phone && this.enquiry.Email) {
        await houseConsult({
          house_id: this.housedetal.id,
          name: this.enquiry.First_name, //名
          surname: this.enquiry.Last_name, //姓
          mobile: this.enquiry.Phone, //手机号
          email: this.enquiry.Email, //邮箱
          content: this.enquiry.Message //咨询内容
        }).then(res => {
          console.log('res', res)
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: 'success'
            });
            // this.zixunshow = false
            this.enquiry = {
              First_name: '',
              Last_name: '',
              Phone: '',
              Email: '',
            }
            console.log(res, 'resresresres')
          }

          console.log('res', res)
        })

      }
      else {
        if (this.$i18n.locale == 'en') {
          this.$message({
            message: 'Required fields cannot be empty',
            type: 'success'
          });
        }
        else {
          this.$message({
            message: '必填项不能为空',
            type: 'success'
          });
        }

      }
    },
    handlerChange(e) {
      console.log(e)
      // this.Mortgage.Price = this.Mortgage.Price ? this.Mortgage.Price : 1
      // this.Mortgage.Loan_term = this.Mortgage.Loan_term ? this.Mortgage.Loan_term : 1
      // this.Mortgage.Deposit = this.Mortgage.Deposit ? this.Mortgage.Deposit : 1
      // this.Mortgage.Interest_rate = this.Mortgage.Interest_rate ? this.Mortgage.Interest_rate : 1
      // var value
      // this.Mortgageprice = ((this.Mortgage.Price * (1 - this.Mortgage.Loan_term * 0.01)) * (1 + this.Mortgage.Interest_rate)) / this.Mortgage.Deposit * 12 ? ((this.Mortgage.Price * (1 - this.Mortgage.Loan_term * 0.01)) * (1 + this.Mortgage.Interest_rate)) / this.Mortgage.Deposit * 12 : 0
    },
    handlerChange1(e) {

      console.log(e)
      console.log('this.q1', this.Stamp.q1)
      console.log('this.q1', this.Stamp.q2)
      if (this.Stamp.Property_value <= 425000 && this.Stamp.q1) {

        if (this.Stamp.q2) {
          this.Stampprice = 0
        }
        else {
          this.Stampprice = this.Stamp.Property_value * 0.02
        }

      }
      else if (this.Stamp.Property_value <= 625000 && this.Stamp.Property_value > 425000 && this.Stamp.q1) {
        if (this.Stamp.q2) {
          console.log('11')
          this.Stampprice = (this.Stamp.Property_value - 425000) * 0.05

        }
        else {
          this.Stampprice = (this.Stamp.Property_value - 425000) * 0.05 + this.Stamp.Property_value * 0.02
        }

      }
      else if (this.Stamp.Property_value <= 925000 && this.Stamp.Property_value > 625000 && this.Stamp.q1) {
        if (this.Stamp.q2) {
          this.Stampprice = 7500 + (this.Stamp.Property_value - 250000) * 0.08

        }
        else {
          this.Stampprice = 7500 + (this.Stamp.Property_value - 250000) * 0.08 + this.Stamp.Property_value * 0.02
        }
      }
      else if (this.Stamp.Property_value <= 1500000 && this.Stamp.Property_value > 925000 && this.Stamp.q1) {
        if (this.Stamp.q2) {
          this.Stampprice = 61500 + (this.Stamp.Property_value - 925000) * 0.13

        }
        else {
          this.Stampprice = 61500 + (this.Stamp.Property_value - 925000) * 0.13 + this.Stamp.Property_value * 0.02
        }
      }
      else if (this.Stamp.Property_value > 1500000 && this.Stamp.q1) {
        if (this.Stamp.q2) {
          this.Stampprice = 134250 + (this.Stamp.Property_value - 1500000) * 0.15

        }
        else {
          this.Stampprice = 134250 + (this.Stamp.Property_value - 1500000) * 0.15 + this.Stamp.Property_value * 0.02
        }
      }
      else if (this.Stamp.Property_value <= 250000 && !this.Stamp.q1) {

        if (this.Stamp.q2) {
          this.Stampprice = this.Stamp.Property_value * 0.03
        }
        else {
          this.Stampprice = this.Stamp.Property_value * 0.03 + this.Stamp.Property_value * 0.02
        }

      }
      else if (this.Stamp.Property_value <= 925000 && this.Stamp.Property_value > 250000 && !this.Stamp.q1) {
        if (this.Stamp.q2) {
          this.Stampprice = 7500 + (this.Stamp.Property_value - 250000) * 0.08

        }
        else {
          this.Stampprice = 7500 + (this.Stamp.Property_value - 250000) * 0.08 + this.Stamp.Property_value * 0.02
        }
      }
      else if (this.Stamp.Property_value <= 1500000 && this.Stamp.Property_value > 925000 && !this.Stamp.q1) {
        if (this.Stamp.q2) {
          this.Stampprice = 61500 + (this.Stamp.Property_value - 925000) * 0.13

        }
        else {
          this.Stampprice = 61500 + (this.Stamp.Property_value - 925000) * 0.13 + this.Stamp.Property_value * 0.02
        }
      }
      else if (this.Stamp.Property_value > 1500000 && !this.Stamp.q1) {
        if (this.Stamp.q2) {
          this.Stampprice = 134250 + (this.Stamp.Property_value - 1500000) * 0.15

        }
        else {
          this.Stampprice = 134250 + (this.Stamp.Property_value - 1500000) * 0.15 + this.Stamp.Property_value * 0.02
        }
      }






      // this.isRewiriteEditor = true;
    }

  },
};
</script>
<style scoped>
.map {
  position: relative;
  /* height: 85vh; */
  /* padding-top: 10vh; */
}

.w650 {
  width: 100%;
  height: 321px;
  border-radius: 0px 0px 0px 0px;
}

.w20 {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.back_img {
  position: absolute;
  top: 10px;
  z-index: 100;
  left: 10px;
  width: 32px;
  height: 32px;
}

.add-btn {
  width: 60px;
  margin-left: 2%;
  height: 40px;
  background: #409eff;
  color: #fff;
  border: 0;
  border-radius: 5px;
}

.search-box input {
  height: 40px;
  width: 50%;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-left: 7px;
  outline: none;
}

/* 底部两个按钮 */
.btn-box {
  margin-top: 2vh;
}

.btn-box .el-button {
  padding: 8px 30px;
  border-radius: 30px;
  width: 140px;
  height: 40px;
}

.confirm {
  color: #fff;
  background-color: #0778bc;
}

.w21 {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
}

@media (min-width: 801px) {
  .d_l_m {
    margin: 10px 30px;
  }

  .d_l_t {
    margin: 10px auto;
    padding: 10px auto;
  }

  .types {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px auto;
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
    padding: 40px 10px;

  }

  .type {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .type_num {
    margin-top: 10px;

    font-weight: 500;
    font-size: 18px;
    color: #111111;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
  .tiaozhuan{
    /* margin-top: -20px; */
    position: absolute;
    top: 20px;
    cursor: pointer;
    left: 20px;
  }

  .d_l_m_t {
    margin-top: 20px;
    justify-content: space-between;
    width: 100%;
    display: flex;
  }

  .bottons {
    justify-content: space-between;
  }

  .botton1 {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    margin-right: 20px;
    background: #F5F5F5;
    border-radius: 8px 8px 8px 8px;
    align-items: center;
    font-style: normal;
    text-transform: none;
    margin-top: 10px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 20px;
    color: #111111;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .price {
    margin-top: 10px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 20px;
    color: #111111;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .d_l_m_t1 {
    margin: 26px 0;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    line-height: 26px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .images {
    /* margin-bottom: 20px; */
    display: grid;
    width: 100%;
    text-align: center;
    grid-template-columns: repeat(4, 1fr);

  }

  .image1 {
    width: 88px;
    height: 88px;
    text-align: center;
    line-height: 88px;
    background: #F3F4F6;
    margin-bottom: 20px;
    border-radius: 4px 4px 4px 4px;
  }


  .image1 {
    cursor: pointer;
    margin-right: 10px;
  }

  .filess {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D8D8D8;
  }

  .look_full {
    margin: 20px auto;
    width: 80%;
    /* margin-bottom: 0px; */
    /* padding-bottom: 20px; */
    /* border-bottom: 1px solid #D8D8D8; */
    /* width: 205px;
height: 25px; */
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 60px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    height: 60px;
    background: #1495FF;
  }

  .line_b {
    width: 90%;
    height: 0px;
    border: 1px solid #D8D8D8;
    margin: 20px auto;

  }

  .simi {
    padding: 30px;
    padding-top: 0;
  }

  .sb_r {
    margin-left: 10px;
  }

  .simibox {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin: 16px 0px;
  }

  .title1 {
    margin-bottom: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #1495FF;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .title2 {
    margin-bottom: 12px;
    height: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #999999;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .title3 {

    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #111111;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;

  }

  .simititle {
    margin: 20px 0;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 20px;
    color: #111111;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .w120 {
    width: 150px;
    height: 120px;

  }
}

@media (max-width: 800px) {
  .d_l_m {
    margin: 10px 30px;
  }

  .d_l_t {
    margin: 10px auto;
    padding: 10px auto;
  }

  .types {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px auto;
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
    padding: 40px 10px;

  }

  .type {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .type_num {
    margin-top: 10px;

    font-weight: 500;
    font-size: 18px;
    color: #111111;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }


  .d_l_m_t {
    margin-top: 20px;
    justify-content: space-between;
    /* width: 100%;
    display: flex; */
  }

  .bottons {
    justify-content: space-between;
  }

  .botton1 {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    margin-right: 20px;
    background: #F5F5F5;
    border-radius: 8px 8px 8px 8px;
    align-items: center;
    font-style: normal;
    text-transform: none;
    margin-top: 10px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 20px;
    color: #111111;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .price {
    margin-top: 10px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 20px;
    color: #111111;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .d_l_m_t1 {
    margin: 26px 0;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    line-height: 26px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .images {
    /* margin-bottom: 20px; */
    display: grid;
    width: 100%;
    text-align: center;
    grid-template-columns: repeat(3, 1fr);

  }

  .image1 {
    width: 88px;
    height: 88px;
    text-align: center;
    line-height: 88px;
    background: #F3F4F6;
    margin-bottom: 20px;
    border-radius: 4px 4px 4px 4px;
  }


  .image1 {
    cursor: pointer;
    margin-right: 10px;
  }

  .filess {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D8D8D8;
  }
  .look_full {
    margin: 20px auto;
    width: 80%;
    /* margin-bottom: 0px; */
    /* padding-bottom: 20px; */
    /* border-bottom: 1px solid #D8D8D8; */
    /* width: 205px;
height: 25px; */
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 60px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    height: 60px;
    background: #1495FF;
  }

  .line_b {
    width: 90%;
    height: 0px;
    border: 1px solid #D8D8D8;
    margin: 20px auto;

  }
  .tiaozhuan{
    /* margin-top: -20px; */
    position: absolute;
    top: 20px;
    cursor: pointer;
    left: 20px;
  }

  .simi {
    padding: 30px;
    padding-top: 0;
  }

  .sb_r {
    margin-left: 10px;
  }

  .simibox {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin: 16px 0px;
  }

  .title1 {
    margin-bottom: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #1495FF;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .title2 {
    margin-bottom: 12px;
    height: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #999999;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .title3 {

    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #111111;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;

  }

  .simititle {
    margin: 20px 0;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #111111;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .w120 {
    width: 150px;
    height: 120px;

  }
}


.cancel {
  color: #0778bc;
  border: 1px solid #0778bc;
}

.cursor {
  cursor: pointer;
}

@media only screen and (max-width: 820px) {
  .img-box .el-image {
    height: 4vh;
  }

  .search-box input {
    height: 5vh;
  }

  .add-btn {
    height: 5vh;
  }
}


.map .GmapMapBox {
  /* background-image: url(../assets/img/loading.gif); */
  margin-top: 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
</style>