<!--  -->
<template>

    <div>
        <div v-if="screenWidth >= 801">
            <div class="detail flex">
                <div class="detail_l">
                    <div class="d_l_t">
                        <el-carousel trigger="click" height="50vh" :autoplay="false" indicator-position="none">
                            <el-carousel-item v-for="item in housedetal.house_image_text" :key="item">
                                <img :src="item" class="w920" alt="">
                            </el-carousel-item>
                        </el-carousel>
                        <!-- <img :src="housedetal.house_image_text" alt="" class="w920"> -->
                    </div>
                    <div class="d_l_m">
                        <div class="d_l_m_t flex1">
                            <div v-if="house_stats == 1">
                                <div class="price" v-if="$i18n.locale == 'en'">
                                    £{{ housedetal.en_house_price }}

                                </div>
                                <div class="price" v-else>
                                    ￥{{ housedetal.house_price }}

                                </div>
                            </div>
                            <div v-else>
                                <div class="price" v-if="$i18n.locale == 'en'">
                                    £{{ housedetal.en_week_rent }}/week

                                </div>
                                <div class="price" v-else>
                                    ￥{{ housedetal.week_rent }}/week

                                </div>

                                <div class="price" v-if="$i18n.locale == 'en'">
                                    £{{ housedetal.en_month_rent }}/month

                                </div>
                                <div class="price" v-else>
                                    ￥{{ housedetal.month_rent }}/month

                                </div>
                            </div>
                            <div class="bottons flex1">
                                <div class="botton1 flex1" @click="save(1)" v-if="housedetal.is_collect == 0">
                                    <img src="../static//index/like.png" alt="" class="w20">
                                    {{ $t('Save') }}
                                </div>
                                <div class="botton1 flex1" @click="save(2)" v-else>
                                    <img src="../static//index/like1.png" alt="" class="w20">
                                    {{ $t('Save') }}
                                </div>
                                <div class="botton1 flex1" @click="share()">
                                    <img src="../static/index/share.png" alt="" class="w20">

                                    {{ $t('Share') }}
                                </div>

                            </div>
                        </div>
                        <div class="d_l_m_t1">
                            <div class="name"> {{ $i18n.locale == 'en' ? housedetal.en_house_name :
            housedetal.house_name }}
                            </div>
                        </div>
                        <div class="d_l_m_t2">
                            {{ $t('ABOUT_THIS_PROPERTY') }}
                        </div>
                        <div v-html="$i18n.locale == 'en' ? housedetal.en_house_content : housedetal.house_content">
                        </div>
                        <!-- <div class="d_l_m_t2">
                            {{ $t('Map') }}
                        </div> -->
                        <div class="types" v-if="house_stats == 1">
                            <div class="type">
                                <div class="type_name">
                                    {{ $t('PROPERTY_TYPE') }}
                                </div>
                                <div class="type_num flex-box">
                                    <img src="../static/home.png" class="w20" alt="">
                                    {{ $i18n.locale == 'en' ? housedetal.hp_en_name :
            housedetal.hp_name }}
                                </div>
                            </div>
                            <div class="type">
                                <div class="type_name">
                                    {{ $t('BEDROOMS') }}
                                </div>
                                <div class="type_num flex-box">
                                    <img src="../static/home1.png" class="w20" alt="">
                                    {{ $i18n.locale == 'en' ? housedetal.room_number :
            housedetal.room_number }}

                                </div>

                            </div>

                            <div class="type">
                                <div class="type_name">
                                    {{ $t('BATHROOMS') }}
                                </div>
                                <div class="type_num flex-box">
                                    <img src="../static/home2.png" class="w20" alt="">
                                    {{ $i18n.locale == 'en' ? housedetal.lavatory_number :
            housedetal.lavatory_number }}

                                </div>

                            </div>

                            <div class="type">
                                <div class="type_name">
                                    {{ $t('SIZE') }}
                                </div>
                                <div class="type_num flex-box">
                                    <img src="../static/home3.png" class="w20" alt="">
                                    {{ $i18n.locale == 'en' ? housedetal.en_area :
            housedetal.zh_area }}

                                </div>

                            </div>
                        </div>
                        <div class="types" v-else>
                            <div class="type">
                                <div class="type_name">
                                    {{ $t('PROPERTY_TYPE') }}
                                </div>
                                <div class="type_num flex-box">
                                    <img src="../static/home.png" class="w20" alt="">
                                    {{ $i18n.locale == 'en' ? housedetal.hp_en_name :
            housedetal.hp_name }}
                                </div>
                            </div>
                            <div class="type">
                                <div class="type_name">
                                    {{ $t('BEDROOMS') }}
                                </div>
                                <div class="type_num flex-box">
                                    <img src="../static/home1.png" class="w20" alt="">
                                    {{ $i18n.locale == 'en' ? housedetal.room_number :
            housedetal.room_number }}

                                </div>

                            </div>

                            <div class="type">
                                <div class="type_name">
                                    {{ $t('BATHROOMS') }}
                                </div>
                                <div class="type_num flex-box">
                                    <img src="../static/home2.png" class="w20" alt="">
                                    {{ $i18n.locale == 'en' ? housedetal.lavatory_number :
            housedetal.lavatory_number }}

                                </div>

                            </div>

                            <div class="type">
                                <div class="type_name">
                                    {{ $t('SIZE') }}
                                </div>
                                <div class="type_num flex-box">
                                    <img src="../static/home3.png" class="w20" alt="">
                                    {{ $i18n.locale == 'en' ? housedetal.en_area :
            housedetal.zh_area }}

                                </div>

                            </div>
                        </div>
                        <!-- <div class="d_l_m_t2">
                            {{ $t('Map') }}
                        </div> -->
                        <div class="filess ">
                            <div class="files11">
                                <div class="files" v-if="housedetal.file_arr.length < 4">
                                    <div class="flie flex-box" v-for="(item, index) in housedetal.file_arr"
                                        :key="index">
                                        <!-- <div class="flie flex-box" v-for="(item, index) in 3" :key="index" > -->
                                        {{ item.name }}

                                        <img src="../assets/downlond.png" class="w21" alt=""
                                            @click="downloadFile(item.url, item.name)">
                                    </div>
                                    <!-- <div class="flie1 "  @click="downloadFile1()">
                                        {{ $t('DOWNLOAD_ALL') }}
                                    </div> -->
                                </div>
                                <div class="files" v-else>
                                    <div class="flie flex-box" v-for="(item, index) in housedetal.file_arr.slice(0, 3)"
                                        :key="index">
                                        <!-- <div class="flie flex-box" v-for="(item, index) in 3" :key="index" > -->
                                        {{ item.name }}

                                        <img src="../assets/downlond.png" class="w21" alt=""
                                            @click="downloadFile(item.url, item.name)">
                                    </div>
                                    <div class="flie1 " @click="downloadFile1()">
                                        {{ $t('DOWNLOAD_ALL') }}
                                    </div>
                                </div>
                            </div>
                            <div class="images " v-if="housedetal.house_image_text.length < 8">
                                <div class="image1" v-for="(item, index) in housedetal.house_image_text" :key="index">
                                    <el-image style="width: 88px; height:88px" class="w88" :src="item"
                                        :preview-src-list="housedetal.house_image_text">
                                    </el-image>

                                </div>


                            </div>
                            <div class="images " v-else>
                                <div class="image1" v-for="(item, index) in housedetal.house_image_text.slice(0, 7)"
                                    :key="index">
                                    <el-image style="width: 88px; height:88px" class="w88" :src="item"
                                        :preview-src-list="housedetal.house_image_text">
                                    </el-image>

                                </div>


                                <div class="image1">

                                    +{{ housedetal.house_image_text.length }}
                                </div>
                            </div>

                        </div>
                        <div class="d_l_m_t2">
                            {{ $t('Map') }}
                        </div>
                        <div class="map">
                            <GmapMap :center="{ lat: Number(housedetal.lat), lng: Number(housedetal.lng) }" :zoom="12"
                                style="width: 100%; height: 100%;">
                                <GmapMarker :position="{ lat: Number(housedetal.lat), lng: Number(housedetal.lng) }" />
                            </GmapMap>

                        </div>
                        <!-- <div class="d_l_m_t2">
                            {{ $t('TRAIN_STATIONS') }}
                        </div>
                        <div class="fujinlist" v-for="(item, index) in fujinlist" :key="index">
                            <li>{{ item.name }}
                                <span class="miles"> ({{ item.miles }})miles</span>
                            </li>
                        </div> -->

                    </div>
                    <div class="d_l_d mt20" v-if="house_stats == 1">
                        <div class="d_l_d_box mb20 p20">
                            <div class="box_t flex1 flex-between ">
                                {{ $t('Mortgage.Mortgage_calculator') }}
                                <img src="../static/index/add.png" alt="" class="w28" v-if="show1"
                                    @click="show1 = !show1">
                                <img src="../static/index/remove.png" alt="" class="w28" v-else @click="show1 = !show1">
                            </div>
                            <div class="box_b mt20" v-if="show1">
                                <div class="box_b_t mt20 flex-between flex1">
                                    <div class="box_input">
                                        <div class="input_title mb10">
                                            {{ $t('Mortgage.Price') }}
                                        </div>
                                        <div class="input_input">
                                            <el-input v-model="Mortgage.Price" :placeholder="$t('Mortgage.Price')"
                                                type="number" @input="handlerChange" class="input_input1"> <template
                                                    slot="append">£</template></el-input>
                                        </div>
                                    </div>
                                    <div class="box_input">
                                        <div class="input_title mb10">
                                            {{ $t('Mortgage.Deposit') }}
                                        </div>
                                        <div class="input_input">
                                            <el-input v-model="Mortgage.Deposit" :placeholder="$t('Mortgage.Deposit')"
                                                type="number" @input="handlerChange" class="input_input1"> <template
                                                    slot="append">£</template></el-input>
                                        </div>

                                    </div>

                                </div>
                                <div class="box_b_t mt20 flex-between flex1">
                                    <div class="box_input">
                                        <div class="input_title mb10">
                                            {{ $t('Mortgage.Loan_term') }}
                                        </div>
                                        <div class="input_input">
                                            <el-input v-model="Mortgage.Loan_term"
                                                :placeholder="$t('Mortgage.Loan_term')" type="number"
                                                @input="handlerChange" class="input_input1">
                                                <template slot="append">years</template></el-input>
                                        </div>
                                        <!-- <div class="input_input">
                                            <el-select v-model="Mortgage.Loan_term" placeholder="活动区域"
                                                class="input_input1">
                                                <el-option label="区域一" value="shanghai"></el-option>
                                                <el-option label="区域二" value="beijing"></el-option>
                                            </el-select>

                                        </div> -->
                                    </div>
                                    <div class="box_input">
                                        <div class="input_title mb10">
                                            {{ $t('Mortgage.Interest_rate') }}
                                        </div>
                                        <div class="input_input">
                                            <el-input v-model="Mortgage.Interest_rate"
                                                :placeholder="$t('Mortgage.Interest_rate')" type="number"
                                                @input="handlerChange" class="input_input1"><template
                                                    slot="append">%</template></el-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box_bottom mt20" v-if="show1">
                                <div class="box_bottom_t mb20 flex1">
                                    <div class="box_bottom_text1 mr20">£{{ Mortgageprice ? Mortgageprice : 0 }}</div>
                                    <div class="box_bottom_text2"> {{ $t('per_month') }}</div>
                                </div>
                                <div class="btn" @click="zixun()">
                                    <el-button type="primary">{{ $t('Mortgage.GET_MORTGAGE_ADVICE') }}</el-button>
                                </div>
                            </div>

                        </div>
                        <div class="d_l_d_box mb20 p20">
                            <div class="box_t flex1 flex-between ">
                                {{ $t('Yield.Yield_calculator') }}
                                <img src="../static/index/add.png" alt="" class="w28" v-if="show2"
                                    @click="show2 = !show2">
                                <img src="../static/index/remove.png" alt="" class="w28" v-else @click="show2 = !show2">
                            </div>

                            <div class="box_b mt20" v-if="show2">
                                <div class="box_b_t mt20 flex-between flex1">
                                    <div class="box_input">
                                        <div class="input_title mb10">
                                            {{ $t('Yield.Property_value') }}
                                        </div>
                                        <div class="input_input">
                                            <el-input v-model="Yield.Property_value"
                                                :placeholder="$t('Yield.Property_value')" class="input_input1"><template
                                                    slot="append">£</template></el-input>
                                        </div>
                                    </div>
                                    <div class="box_input">
                                        <div class="input_title mb10">
                                            {{ $t('Yield.Annual_costs') }}
                                        </div>
                                        <div class="input_input">
                                            <el-input v-model="Yield.Annual_costs"
                                                :placeholder="$t('Yield.Annual_costs')" class="input_input1"><template
                                                    slot="append">£</template></el-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="box_b_t mt20 flex-between flex1">

                                    <div class="box_input">
                                        <div class="input_title mb10">
                                            {{ $t('Yield.Monthly_rent') }}
                                        </div>
                                        <div class="input_input">
                                            <el-input v-model="Yield.Monthly_rent"
                                                :placeholder="$t('Yield.Monthly_rent')" class="input_input1"><template
                                                    slot="append">£</template></el-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box_bottom mt20" v-if="show2">
                                <div class="box_bottom_t mb20 flex1">
                                    <div class="box_bottom_text1 mr20"> {{ $t('Yield.Gross') }}: {{ Gross }}%</div>
                                    <div class="box_bottom_text1 mr20"> {{ $t('Yield.Net') }}: {{ Net }}%</div>

                                    <!-- <div class="box_bottom_text2">per month</div> -->
                                </div>
                                <div class="btn" @click="zixun()">
                                    <el-button type="primary">{{ $t('Mortgage.GET_MORTGAGE_ADVICE') }}</el-button>
                                </div>
                            </div>

                        </div>
                        <div class="d_l_d_box mb20 p20">
                            <div class="box_t flex1 flex-between ">
                                {{ $t('Stamp.Stamp_duty_calculator') }}
                                <img src="../static/index/add.png" alt="" class="w28" v-if="show3"
                                    @click="show3 = !show3">
                                <img src="../static/index/remove.png" alt="" class="w28" v-else @click="show3 = !show3">
                            </div>

                            <div class="box_bottom mt20" v-if="show3">
                                <div class="box_input mb20 flex-box">
                                    <div class="input_title mb10 mr40">
                                        {{ $t('Stamp.Property_value') }}:
                                    </div>
                                    <div class="input_input">
                                        <el-input v-model="Stamp.Property_value"
                                            :placeholder="$t('Stamp.Property_value')" type="number" class="input_input1"
                                            @input="handlerChange1"></el-input>
                                    </div>
                                </div>
                                <div class="box_bottom_t mb20 ">
                                    <el-checkbox v-model="Stamp.q1" @change="handlerChange1">{{ $t('Stamp.q1')
                                        }}</el-checkbox>
                                    <el-checkbox v-model="Stamp.q2" @change="handlerChange1">{{ $t('Stamp.q2')
                                        }}</el-checkbox>
                                </div>

                                <div class="box_bottom_t mb20 flex1">

                                    <div class="box_bottom_text2 mr15">{{ $t('Stamp.YOU_WILL_PAY') }}</div>
                                    <div class="box_bottom_text1 mr20">£{{ Stampprice }}</div>
                                </div>
                                <div class="btn" @click="zixun()">
                                    <el-button type="primary">{{ $t('Stamp.GET_MORTGAGE_ADVICE') }}</el-button>
                                </div>
                            </div>

                        </div>

                    </div>


                </div>
                <div class="detail_r ptb30 plr15" v-if="!putenquiryshow">
                    <!-- <div class="image">
                            <img src="../static/index/close.png" class="w28" alt="">
                        </div> -->
                    <div class="detail_r_t flex1">

                        <div class="d_title">
                            {{ $t('enquiry.Make_enquiry') }}
                        </div>

                    </div>
                    <div class="detail_r_m  mtb15 flex1">
                        <img :src="housedetal.sellpeople.image_text" class="d_image" alt="">
                        <div class="Enquiry_p ml20 ">
                            <div class="name1">
                                {{ housedetal.sellpeople.name }}
                                {{ housedetal.sellpeople.surname }}
                            </div>
                            <div class="name2 mtb10"> {{ housedetal.sellpeople.position ?
            housedetal.sellpeople.position
            : '' }}</div>
                            <!-- <div class="name3">  {{housedetal.sellpeople.telephone}}</div> -->
                        </div>
                    </div>
                    <div class="detail_r_m1  mtb15 flex1 flex-center" @click="putenquiry()">
                        <el-button type="primary" class="prl30"> {{ $t('enquiry.Make_An_Enquiry') }}</el-button>

                    </div>
                    <div class="detail_r_m1 flex1 mtb15 flex-center">
                        <div class="call">
                            {{ $t('enquiry.Call') }}
                        </div>
                        <div class="tell " @click="gettel"> {{ housedetal.sellpeople.telephone }}</div>
                    </div>

                </div>
                <div class="detail_r1 ptb30 plr15" v-else>
                    <div class="image" @click="putenquiryshow = false">
                        <img src="../static/index/close.png" class="w28" alt="">
                    </div>
                    <div class="detail_r_t flex-between flex1">

                        <div class="d_title">
                            {{ $t('enquiry.Make_enquiry') }}
                        </div>
                        <div class="image">
                            <img :src="housedetal.sellpeople.image_text" class="d_image1" alt="">
                        </div>
                    </div>
                    <div class="detail_r_m  mtb15 ">
                        <div class="flex1 mtb15 flex-between">
                            <div class="d_input_input">
                                <el-input v-model="enquiry.First_name" :placeholder="$t('enquiry.First_name')"
                                    class="input_input3"></el-input>
                            </div>
                            <div class="d_input_input">
                                <el-input v-model="enquiry.Last_name" :placeholder="$t('enquiry.Last_name')"
                                    class="input_input3"></el-input>
                            </div>
                        </div>

                        <div class=" mtb15 flex-between">
                            <div class="d_input_input">
                                <el-input v-model="enquiry.Phone" :placeholder="$t('enquiry.Phone')"
                                    class="input_input2"></el-input>
                            </div>

                        </div>
                        <div class=" mtb15 flex-between">
                            <div class="d_input_input">
                                <el-input v-model="enquiry.Email" :placeholder="$t('enquiry.Email')"
                                    class="input_input2"></el-input>
                            </div>

                        </div>
                        <div class=" mtb15 flex-between">
                            <div class="d_input_input">
                                <el-input v-model="enquiry.Message" :placeholder="$t('enquiry.Message')"
                                    class="input_input2"></el-input>
                            </div>

                        </div>

                    </div>
                    <div class="box_bottom_t mb20 input_input2">
                        <el-checkbox-group v-model="enquiry.agree1">
                            <el-checkbox :label="$t('enquiry.agree1')" name="type"></el-checkbox>

                        </el-checkbox-group>
                        <el-checkbox-group v-model="enquiry.agree2">
                            <el-checkbox :label="$t('enquiry.agree2')" name="type"></el-checkbox>


                        </el-checkbox-group>

                    </div>
                    <div class="detail_r_m1  mtb15 flex1 flex-center" @click="putenquiry1()">
                        <el-button type="primary" class="prl30"> {{ $t('enquiry.Make_An_Enquiry') }}</el-button>

                    </div>
                    <div class=" flex-box mtb15 flex-center">
                        <div class="call">
                            {{ $t('enquiry.Call') }}
                        </div>
                        <div class="tell " @click="gettel(housedetal.sellpeople.telephone)">{{
            housedetal.sellpeople.telephone }}
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div v-else>
            <div class="detail ">
                <div class="detail_l">
                    <div class="d_l_t">
                        <el-carousel trigger="click" height="30vh" :autoplay="false" indicator-position="none">
                            <el-carousel-item v-for="item in housedetal.house_image_text" :key="item">
                                <img :src="item" class="w920" alt="">
                            </el-carousel-item>
                        </el-carousel>
                        <!-- <img :src="housedetal.house_image_text" alt="" class="w920"> -->
                    </div>
                    <div class="d_l_m">
                        <div class="d_l_m_t ">
                            <div v-if="house_stats == 1">
                                <div class="price" v-if="$i18n.locale == 'en'">
                                    £{{ housedetal.en_house_price }}

                                </div>
                                <div class="price" v-else>
                                    ￥{{ housedetal.house_price }}

                                </div>
                            </div>
                            <div v-else>
                                <div class="price" v-if="$i18n.locale == 'en'">
                                    £{{ housedetal.en_week_rent }}/week

                                </div>
                                <div class="price" v-else>
                                    ￥{{ housedetal.week_rent }}/week

                                </div>
                                <div>
                                    <div class="price" v-if="$i18n.locale == 'en'">
                                        £{{ housedetal.en_month_rent }}/month

                                    </div>
                                    <div class="price" v-else>
                                        ￥{{ housedetal.month_rent }}/month

                                    </div>
                                </div>
                            </div>
                            <div class="bottons flex1">
                                <div class="botton1 flex1" @click="save(1)" v-if="housedetal.is_collect == 0">
                                    <img src="../static//index/like.png" alt="" class="w20">
                                    {{ $t('Save') }}
                                </div>
                                <div class="botton1 flex1" @click="save(2)" v-else>
                                    <img src="../static//index/like1.png" alt="" class="w20">
                                    {{ $t('Save') }}
                                </div>
                                <div class="botton1 flex1" @click="share()">
                                    <img src="../static//index/share.png" alt="" class="w20">

                                    {{ $t('Share') }}
                                </div>
                            </div>
                        </div>
                        <div class="d_l_m_t1">
                            {{ housedetal.alias_name }}
                        </div>
                        <div class="d_l_m_t2">
                            {{ $t('ABOUT_THIS_PROPERTY') }}
                        </div>
                        <div v-html="housedetal.content"></div>
                        <div class="types" v-if="house_stats == 1">
                            <div class="type">
                                <div class="type_name">
                                    {{ $t('PROPERTY_TYPE') }}
                                </div>
                                <div class="type_num flex-box">
                                    <img src="../static/home.png" class="w20" alt="">
                                    {{ $i18n.locale == 'en' ? housedetal.hp_en_name :
            housedetal.hp_name }}
                                </div>
                            </div>
                            <div class="type">
                                <div class="type_name">
                                    {{ $t('BEDROOMS') }}
                                </div>
                                <div class="type_num flex-box">
                                    <img src="../static/home1.png" class="w20" alt="">
                                    {{ $i18n.locale == 'en' ? housedetal.room_number :
            housedetal.room_number }}

                                </div>

                            </div>

                            <div class="type">
                                <div class="type_name">
                                    {{ $t('BATHROOMS') }}
                                </div>
                                <div class="type_num flex-box">
                                    <img src="../static/home2.png" class="w20" alt="">
                                    {{ $i18n.locale == 'en' ? housedetal.lavatory_number :
            housedetal.lavatory_number }}

                                </div>

                            </div>

                            <div class="type">
                                <div class="type_name">
                                    {{ $t('SIZE') }}
                                </div>
                                <div class="type_num flex-box">
                                    <img src="../static/home3.png" class="w20" alt="">
                                    {{ $i18n.locale == 'en' ? housedetal.en_area :
            housedetal.zh_area }}

                                </div>

                            </div>
                        </div>
                        <div class="types" v-else>
                            <div class="type">
                                <div class="type_name">
                                    {{ $t('PROPERTY_TYPE') }}
                                </div>
                                <div class="type_num flex-box">
                                    <img src="../static/home.png" class="w20" alt="">
                                    {{ $i18n.locale == 'en' ? housedetal.hp_en_name :
            housedetal.hp_name }}
                                </div>
                            </div>
                            <div class="type">
                                <div class="type_name">
                                    {{ $t('BEDROOMS') }}
                                </div>
                                <div class="type_num flex-box">
                                    <img src="../static/home1.png" class="w20" alt="">
                                    {{ $i18n.locale == 'en' ? housedetal.room_number :
            housedetal.room_number }}

                                </div>

                            </div>

                            <div class="type">
                                <div class="type_name">
                                    {{ $t('BATHROOMS') }}
                                </div>
                                <div class="type_num flex-box">
                                    <img src="../static/home2.png" class="w20" alt="">
                                    {{ $i18n.locale == 'en' ? housedetal.lavatory_number :
            housedetal.lavatory_number }}

                                </div>

                            </div>

                            <div class="type">
                                <div class="type_name">
                                    {{ $t('SIZE') }}
                                </div>
                                <div class="type_num flex-box">
                                    <img src="../static/home3.png" class="w20" alt="">
                                    {{ $i18n.locale == 'en' ? housedetal.en_area :
            housedetal.zh_area }}

                                </div>

                            </div>
                        </div>
                        <!-- <div class="d_l_m_t2">
                            {{ $t('Map') }}
                        </div> -->
                        <div class="fliess ">
                            <div class="files11">
                                <div class="files" v-if="housedetal.file_arr.length < 4">
                                    <div class="flie flex-box" v-for="(item, index) in housedetal.file_arr"
                                        :key="index">
                                        <!-- <div class="flie flex-box" v-for="(item, index) in 3" :key="index" > -->
                                        {{ item.name }}

                                        <img src="../assets/downlond.png" class="w21" alt=""
                                            @click="downloadFile(item.url, item.name)">
                                    </div>
                                    <!-- <div class="flie1 "  @click="downloadFile1()">
                                        {{ $t('DOWNLOAD_ALL') }}
                                    </div> -->
                                </div>
                                <div class="files" v-else>
                                    <div class="flie flex-box" v-for="(item, index) in housedetal.file_arr.slice(0, 3)"
                                        :key="index">
                                        <!-- <div class="flie flex-box" v-for="(item, index) in 3" :key="index" > -->
                                        {{ item.name }}

                                        <img src="../assets/downlond.png" class="w21" alt=""
                                            @click="downloadFile(item.url, item.name)">
                                    </div>
                                    <div class="flie1 " @click="downloadFile1()">
                                        {{ $t('DOWNLOAD_ALL') }}
                                    </div>
                                </div>
                            </div>

                            <div class="images " v-if="housedetal.house_image_text.length < 8">
                                <div class="image1" v-for="(item, index) in housedetal.house_image_text" :key="index">
                                    <el-image style="width: 88px; height:88px" class="w88" :src="item"
                                        :preview-src-list="housedetal.house_image_text">
                                    </el-image>

                                </div>


                            </div>
                            <div class="images " v-else>
                                <div class="image1" v-for="(item, index) in housedetal.house_image_text.slice(0, 7)"
                                    :key="index">
                                    <el-image style="width: 88px; height:88px" class="w88" :src="item"
                                        :preview-src-list="housedetal.house_image_text">
                                    </el-image>

                                </div>


                                <div class="image1">

                                    +{{ housedetal.house_image_text.length }}
                                </div>
                            </div>

                        </div>
                        <div class="d_l_m_t2">
                            {{ $t('Map') }}
                        </div>
                        <div class="map">
                            <GmapMap :center="{ lat: Number(housedetal.lat), lng: Number(housedetal.lng) }" :zoom="12"
                                style="width: 100%; height: 100%;">
                                <GmapMarker :position="{ lat: Number(housedetal.lat), lng: Number(housedetal.lng) }" />
                            </GmapMap>

                        </div>
                        <!-- <div class="d_l_m_t2">
                            {{ $t('TRAIN_STATIONS') }}
                        </div>
                        <div class="fujinlist" v-for="(item, index) in fujinlist" :key="index">
                            <li>{{ item.name }}
                                <span class="miles"> ({{ item.miles }})miles</span>
                            </li>
                        </div> -->

                    </div>
                    <div class="d_l_d mt20" v-if="house_stats == 1">
                        <div class="d_l_d_box mb20 p20">
                            <div class="box_t flex1 flex-between ">
                                {{ $t('Mortgage.Mortgage_calculator') }}
                                <img src="../static/index/add.png" alt="" class="w28" v-if="show1"
                                    @click="show1 = !show1">
                                <img src="../static/index/remove.png" alt="" class="w28" v-else @click="show1 = !show1">
                            </div>
                            <div class="box_b mt20" v-if="show1">
                                <div class="box_b_t mtb10 ">
                                    <div class="box_input">
                                        <div class="input_title mb10">
                                            {{ $t('Mortgage.Price') }}
                                        </div>
                                        <div class="input_input">
                                            <el-input v-model="Mortgage.Price" :placeholder="$t('Mortgage.Price')"
                                                type="number" @input="handlerChange" class="input_input1"> <template
                                                    slot="append">£</template></el-input>

                                        </div>
                                    </div>
                                    <div class="box_input">
                                        <div class="input_title mb10">
                                            {{ $t('Mortgage.Deposit') }}
                                        </div>
                                        <div class="input_input">
                                            <el-input v-model="Mortgage.Deposit" :placeholder="$t('Mortgage.Deposit')"
                                                type="number" @input="handlerChange" class="input_input1"> <template
                                                    slot="append">£</template></el-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="box_b_t mtb10 ">
                                    <div class="box_input mtb10">
                                        <div class="input_title mb10">
                                            {{ $t('Mortgage.Loan_term') }}
                                        </div>

                                        <div class="input_input">
                                            <el-input v-model="Mortgage.Loan_term" :placeholder="Mortgage.Loan_term"
                                                type="number" @input="handlerChange" class="input_input1"> <template
                                                    slot="append">£</template></el-input>
                                        </div>
                                    </div>
                                    <div class="box_input mtb10">
                                        <div class="input_title mb10">
                                            {{ $t('Mortgage.Interest_rate') }}
                                        </div>
                                        <div class="input_input">
                                            <el-input v-model="Mortgage.Interest_rate"
                                                :placeholder="$t('Mortgage.Interest_rate')" type="number"
                                                @input="handlerChange" class="input_input1"> <template
                                                    slot="append">%</template></el-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box_bottom mt20" v-if="show1">
                                <div class="box_bottom_t mb20 flex1">
                                    <div class="box_bottom_text1 mr20">£{{ Mortgageprice ? Mortgageprice : 0 }}</div>
                                    <div class="box_bottom_text2"> {{ $t('per_month') }}</div>
                                </div>
                                <div class="btn" @click="zixun()">
                                    <el-button type="primary">{{ $t('Mortgage.GET_MORTGAGE_ADVICE') }}</el-button>
                                </div>
                            </div>

                        </div>
                        <div class="d_l_d_box mb20 p20">
                            <div class="box_t flex1 flex-between ">
                                {{ $t('Yield.Yield_calculator') }}
                                <img src="../static/index/add.png" alt="" class="w28" v-if="show2"
                                    @click="show2 = !show2">
                                <img src="../static/index/remove.png" alt="" class="w28" v-else @click="show2 = !show2">
                            </div>
                            <div class="box_b mt20" v-if="show2">
                                <div class="box_b_t mtb10 ">
                                    <div class="box_input mtb10">
                                        <div class="input_title mb10">
                                            {{ $t('Yield.Property_value') }}
                                        </div>
                                        <div class="input_input">
                                            <el-input v-model="Yield.Property_value"
                                                :placeholder="$t('Yield.Property_value')" class="input_input1"><template
                                                    slot="append">£</template></el-input>
                                        </div>
                                    </div>
                                    <div class="box_input mtb10">
                                        <div class="input_title mb10">
                                            {{ $t('Yield.Annual_costs') }}
                                        </div>
                                        <div class="input_input">
                                            <el-input v-model="Yield.Annual_costs"
                                                :placeholder="$t('Yield.Annual_costs')" class="input_input1"><template
                                                    slot="append">£</template></el-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="box_b_t mtb10">

                                    <div class="box_input mtb10">
                                        <div class="input_title mb10">
                                            {{ $t('Yield.Monthly_rent') }}
                                        </div>
                                        <div class="input_input">
                                            <el-input v-model="Yield.Monthly_rent"
                                                :placeholder="$t('Yield.Monthly_rent')" class="input_input1"><template
                                                    slot="append">£</template></el-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box_bottom mt20" v-if="show2">
                                <div class="box_bottom_t mtb10 flex1">
                                    <div class="box_bottom_text1 mr20"> {{ $t('Yield.Gross') }}: {{ Gross }}%</div>
                                    <div class="box_bottom_text1 mr20"> {{ $t('Yield.Net') }}:{{ Net }}%</div>

                                    <!-- <div class="box_bottom_text2">per month</div> -->
                                </div>
                                <div class="btn" @click="zixun()">
                                    <el-button type="primary">{{ $t('Mortgage.GET_MORTGAGE_ADVICE') }}</el-button>
                                </div>
                            </div>

                        </div>
                        <div class="d_l_d_box mb20 p20">
                            <div class="box_t flex1 flex-between ">
                                {{ $t('Stamp.Stamp_duty_calculator') }}
                                <img src="../static/index/add.png" alt="" class="w28" v-if="show3"
                                    @click="show3 = !show3">
                                <img src="../static/index/remove.png" alt="" class="w28" v-else @click="show3 = !show3">
                            </div>

                            <div class="box_bottom mt20" v-if="show3">
                                <div class="box_input mtb10">
                                    <div class="input_title mb10">
                                        {{ $t('Stamp.Property_value') }}
                                    </div>
                                    <div class="input_input">
                                        <el-input v-model="Stamp.Property_value"
                                            :placeholder="$t('Stamp.Property_value')" @input="handlerChange1"
                                            class="input_input1"></el-input>
                                    </div>
                                </div>
                                <div class="box_bottom_t mb20 ">
                                    <el-checkbox v-model="Stamp.q1" @change="handlerChange1">{{ $t('Stamp.q1')
                                        }}</el-checkbox>
                                    <el-checkbox v-model="Stamp.q2" @change="handlerChange1">{{ $t('Stamp.q2')
                                        }}</el-checkbox>
                                </div>

                                <div class="box_bottom_t mb20 flex1">
                                    <div class="box_bottom_text2 mr15">{{ $t('Stamp.YOU_WILL_PAY') }}</div>
                                    <div class="box_bottom_text1 mr20">£{{ Stampprice }}</div>
                                </div>
                                <div class="btn" @click="zixun()">
                                    <el-button type="primary">{{ $t('Stamp.GET_MORTGAGE_ADVICE') }}</el-button>
                                </div>
                            </div>

                        </div>

                    </div>


                </div>
                <div class="detail_r1 ptb30 plr15" v-if="!putenquiryshow">
                    <div class="detail_r_t flex1">
                        <!-- <div class="image">
                            <img src="../static/index/close.png" class="w28" alt="">
                        </div> -->
                        <div class="d_title">
                            {{ $t('enquiry.Make_enquiry') }}
                        </div>

                    </div>
                    <div class="detail_r_m  mtb15 flex1">
                        <img :src="housedetal.sellpeople.image_text" class="d_image" alt="">
                        <div class="Enquiry_p ml20 ">
                            <div class="name1">
                                {{ housedetal.sellpeople.name }}
                                {{ housedetal.sellpeople.surname }}
                            </div>
                            <div class="name2 mtb10"> {{ housedetal.sellpeople.position ?
            housedetal.sellpeople.position
            : '' }}
                            </div>
                            <!-- <div class="name3">  {{housedetal.sellpeople.telephone}}</div> -->
                        </div>

                    </div>
                    <div class="detail_r_m1  mtb15 flex1 flex-center" @click="putenquiry()">
                        <el-button type="primary" class="prl30"> {{ $t('enquiry.Make_An_Enquiry') }}</el-button>

                    </div>
                    <div class="flex-box  mtb15 flex-center">
                        <div class="call">
                            {{ $t('enquiry.Call') }}：
                        </div>
                        <div class="tell " @click="gettel(housedetal.sellpeople.telephone)">{{
            housedetal.sellpeople.telephone }}</div>
                    </div>

                </div>
                <div class="detail_r1 ptb30 plr15" v-else>
                    <div class="image" @click="putenquiryshow = false">
                        <img src="../static/index/close.png" class="w28" alt="">
                    </div>
                    <div class="detail_r_t flex-between flex1">

                        <div class="d_title">
                            {{ $t('enquiry.Make_enquiry') }}
                        </div>
                        <div class="image">
                            <img :src="housedetal.sellpeople.image_text" class="d_image1" alt="">
                        </div>
                    </div>
                    <!-- <div class="detail_r_t flex-between flex1">
                        <div class="d_title">
                            {{ $t('enquiry.Make_enquiry') }}
                        </div>
                        <div class="image">
                            <img :src="housedetal.sellpeople.image_text" class="d_image1" alt="">
                        </div>
                    </div> -->
                    <div class="detail_r_m  mtb15 ">
                        <div class=" mtb15 flex-between">
                            <div class="d_input_input mtb15">
                                <el-input v-model="enquiry.First_name" :placeholder="$t('enquiry.First_name')"
                                    class="input_input3"></el-input>
                            </div>
                            <div class="d_input_input mtb15">
                                <el-input v-model="enquiry.Last_name" :placeholder="$t('enquiry.Last_name')"
                                    class="input_input3"></el-input>
                            </div>
                        </div>

                        <div class=" mtb15 flex-between">
                            <div class="d_input_input">
                                <el-input v-model="enquiry.Phone" :placeholder="$t('enquiry.Phone')"
                                    class="input_input2"></el-input>
                            </div>

                        </div>
                        <div class=" mtb15 flex-between">
                            <div class="d_input_input">
                                <el-input v-model="enquiry.Email" :placeholder="$t('enquiry.Email')"
                                    class="input_input2"></el-input>
                            </div>

                        </div>
                        <div class=" mtb15 flex-between">
                            <div class="d_input_input">
                                <el-input v-model="enquiry.Message" :placeholder="$t('enquiry.Message')"
                                    class="input_input2"></el-input>
                            </div>

                        </div>

                    </div>
                    <div class="box_bottom_t mb20 input_input2">
                        <el-checkbox-group v-model="enquiry.agree1">
                            <el-checkbox :label="$t('enquiry.agree1')" name="type"></el-checkbox>

                        </el-checkbox-group>
                        <el-checkbox-group v-model="enquiry.agree2">
                            <el-checkbox :label="$t('enquiry.agree2')" name="type"></el-checkbox>


                        </el-checkbox-group>

                    </div>
                    <div class="detail_r_m1  mtb15 flex1 flex-center" @click="putenquiry1()">
                        <el-button type="primary" class="prl30"> {{ $t('enquiry.Make_An_Enquiry') }}</el-button>

                    </div>
                    <div class="flex-box  mtb15 flex-center">
                        <div class="call">
                            {{ $t('enquiry.Call') }}：
                        </div>
                        <div class="tell " @click="gettel(housedetal.sellpeople.telephone)">{{
                            housedetal.sellpeople.telephone }}</div>
                    </div>

                </div>
            </div>
        </div>
        <div class="tablist">
            <!-- <div class="texttitle mb30">
                {{ $t('LATEST_PROPERTIES') }}
            </div> -->
            <div class="texttitle mb30">
                {{ $t('SIMILAR_PROPERTIES') }}
            </div>
            <HouseList :hourelist="list" @addpage="getpage" @HouseId="getHouseId"></HouseList>
            <SeverePageVue :newdata="sell_house" :buttontitle="'sell_house'" :type="1"></SeverePageVue>
            <!-- <NewList></NewList> -->

        </div>
        <el-dialog :title="$t('GET_MORTGAGE_ADVICE')" :visible.sync="zixunshow">

            <div class=" mtb15 flex-between">
                <div class="d_input_input">
                    <el-input v-model="enquiry.First_name" :placeholder="$t('enquiry.First_name')"
                        class="input_input2"></el-input>
                </div>

            </div>
            <div class=" mtb15 flex-between">
                <div class="d_input_input">
                    <el-input v-model="enquiry.Last_name" :placeholder="$t('enquiry.Last_name')"
                        class="input_input2"></el-input>
                </div>

            </div>

            <div class=" mtb15 flex-between">
                <div class="d_input_input">
                    <el-input v-model="enquiry.Phone" :placeholder="$t('enquiry.Phone')"
                        class="input_input2"></el-input>
                </div>

            </div>
            <div class=" mtb15 flex-between">
                <div class="d_input_input">
                    <el-input v-model="enquiry.Email" :placeholder="$t('enquiry.Email')"
                        class="input_input2"></el-input>
                </div>

            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="zixunshow = false">取 消</el-button>
                <el-button type="primary" @click="zixun1">{{ $t('GET_MORTGAGE_ADVICE') }}</el-button>
            </div>
        </el-dialog>
    </div>

</template>

<script>
// import { gmapApi } from 'vue2-google-maps';
import { houseDetail, houseSimi, renthouseDetail, renthouseSimi, houseCollect, houseConsult, houseCompute, imgText } from '../request/api'

import HouseList from '@/components/HouseList.vue';
// import NewList from '@/components/NewList.vue';

import SeverePageVue from '../components/SeverePage.vue';


export default {
    components: {
        HouseList,
        SeverePageVue,

        // NewList
    },
    data() {
        return {
            // Gross: "0",
            // Net: '0',
            screenWidth: document.body.clientWidth,//初始化宽度
            show1: true,
            show2: true,
            show3: true,
            zixunshow: false,
            houseid: 0,
            house_stats: '',
            housedetal: {

            },
            center: { lat: 37.7749, lng: -122.4194 },
            zoom: 12,
            mapOptions: {
                // mapTypeId: gmapApi.MapTypeId.ROADMAP,
                streetViewControl: false,
            },
            fujinlist: [
                { name: 'Caterham', miles: '0.3' },
                { name: 'Whyteleafe South', miles: '1.2' },
                { name: 'Woldingham', miles: '1.2' },
                { name: 'Whyteleafe South- Main Road', miles: '1.2' },
                { name: 'Upper Warlingham', miles: '1.2' },
                // {name:'Woldingham',miles:'1.2'},

            ],
            list: [],
            Mortgage: {
                Price: '',
                Deposit: '',
                Loan_term: '',
                Interest_rate: '',

            },
            Yield: {

                Property_value: 0,
                Annual_costs: 0,
                Monthly_rent: 0,

            },
            Stamp: {

                Property_value: '',
                q1: true,
                q2: false

            },
            Stampprice: 0,
            // Mortgageprice:0,
            putenquiryshow: false,
            enquiry: {
                First_name: '',
                Last_name: '',
                Phone: '',
                Email: '',


            },
            page: 1,
            our_service: {},
            contact_us: {},
            about_us: {},
            our_team: {},
            join_team: {},
            tenement: {},

            buy_house: {},
            rent_house: {},
            flat: {},
            fitment: {},
            sell_house: {},
            loan: {},
            team_member: {},

        }
    },
    computed: {
        Net() {
            return (this.Yield.Monthly_rent * 12 - this.Yield.Annual_costs*100) / this.Yield.Property_value ? (this.Yield.Monthly_rent * 12 - this.Yield.Annual_costs*100) / this.Yield.Property_value : 0
        },
        Gross() {
            return (this.Yield.Monthly_rent * 12) / this.Yield.Property_value ? (this.Yield.Monthly_rent * 12) / this.Yield.Property_value : 0
        },
        Mortgageprice() {
            return Math.floor((this.Mortgage.Price - this.Mortgage.Deposit) * (((this.Mortgage.Interest_rate * 0.01) / 12)) * Math.pow((1 + (((this.Mortgage.Interest_rate * 0.01) / 12))), this.Mortgage.Loan_term * 12) / (Math.pow((1 + (((this.Mortgage.Interest_rate * 0.01) / 12))), this.Mortgage.Loan_term * 12) - 1))
        }
    }
    ,

    mounted() {
        const that = this
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                that.screenWidth = window.screenWidth
            })()

        }
        console.log(that.$route.params.houseid, 'that.$route.paramsthat.$route.paramsthat.$route.paramshhhhhhhhhhhhhhhhhhhhhhhhhhhhh'); // 输出路由参数
        that.houseid = that.$route.params.houseid
        that.house_stats = that.$route.params.houseid.house_stats
        console.log(that.$route.params.houseid.house_stats, 'house_statshouse_statshouse_statshouse_statshhhhhhhhhhhhhhhhhhhhhhhhhhhhh'); // 输出路由参数

        let newContent = this.housedetal.content.replace(/<img[^>]*>/gi, function (match, capture) {
            match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
            match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
            match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
            console.log(capture)
            return match;
        });
        newContent = newContent.replace(/style="[^"]+"/gi, function (match, capture) {
            match = match.replace(/width:[^;]+;/gi, 'width:100%;').replace(/width:[^;]+;/gi, 'width:100%;');
            console.log(capture)

            return match;
        });
        newContent = newContent.replace(/<br[^>]*\/>/gi, '');
        newContent = newContent.replace(/<img/gi, '<img style="width:100%;height:auto;"');
        this.housedetal.content = newContent
    },

    // props: {
    //     houseid: Number,

    // },
    watch: {
        // 监听message数据的变化
        houseid(newValue, oldValue) {
            console.log(`message changed from ${oldValue} to ${newValue}`);
            // 在这里可以根据新的message值执行相应的操作
            this.getdatail(newValue.houseid, newValue.house_stats)
            this.gethouseSimi(newValue.houseid, newValue.house_stats)

        },
        Yield: {
            handler: function (newValue, oldValue) {
                // 当nestedData或其内部属性变化时，会调用这个函数
                console.log('oldValue', oldValue)
                console.log('newValue', newValue)
                this.$store.commit('updateformInline', this.formInline)
            },
            deep: true // 开启深度监听
        },
        screenWidth(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            this.screenWidth = val
            this.timer = true
        },
        Stampprice(value) {
            console.log('value', value)
            // 截取当前数据到小数点后两位
            let realVal = parseFloat(value).toFixed(2)
            console.log('realVal', realVal)
            this.Stampprice = realVal
        }
    },
    methods: {
        async getimgtext() {
            await imgText().then(res => {
                if (res.data) {
                    this.our_service = res.data.our_service
                    this.contact_us = res.data.contact_us
                    this.about_us = res.data.about_us
                    this.our_team = res.data.our_team
                    this.join_team = res.data.join_team
                    this.tenement = res.data.tenement
                    this.buy_house = res.data.buy_house
                    this.rent_house = res.data.rent_house
                    this.flat = res.data.flat
                    this.fitment = res.data.fitment
                    this.sell_house = res.data.sell_house
                    this.loan = res.data.loan
                    this.team_member = res.data.team_member



                }

            })
        },
        async save(type) {
            var action
            if (type == 1) {
                action = 'collect'
            }
            else {
                action = 'uncollect'
            }
            await houseCollect({
                action: action,
                house_stats: this.house_stats,
                house_id_arr: this.housedetal.id
            }).then(res => {
                if (type == 1) {
                    this.$message({
                        message: 'Collection successful',
                        type: 'success'
                    });
                }
                else {
                    this.$message({
                        message: 'Collection canceled',
                        type: 'success'
                    });
                }

                this.getdatail(this.housedetal.id, this.house_stats)
                console.log(res, 'resresresres')
            })
        },
        zixun() {
            this.zixunshow = true
        },
        async zixun1() {
            if (this.enquiry.First_name && this.enquiry.Last_name && this.enquiry.Phone && this.enquiry.Email) {
                await houseCompute({
                    name: this.enquiry.First_name, //名
                    surname: this.enquiry.Last_name, //姓
                    mobile: this.enquiry.Phone, //手机号
                    email: this.enquiry.Email, //邮箱
                    house_id: this.housedetal.id, //房源ID

                    total_price: this.Mortgage.Price, //房源总价
                    down_payment: this.Mortgage.Deposit, //首付金额

                    loan_life: this.Mortgage.Loan_term, //贷款年限

                    interest: this.Mortgage.Interest_rate, //利息


                    annual_costs: this.Yield.Annual_costs, //年度成本

                    monthly_rent: this.Yield.Monthly_rent, //每月租金

                    first_buy: this.Stamp.q1 ? 1 : 2,//是否第一次买房
                    is_native: this.Stamp.q2 ? 1 : 2 //是否第一次买房
                }).then(res => {

                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.zixunshow = false
                    this.enquiry = {
                        First_name: '',
                        Last_name: '',
                        Phone: '',
                        Email: '',
                    }
                    console.log(res, 'resresresres')
                })
            }
            else {
                if (this.$i18n.locale == 'en') {
                    this.$message({
                        message: 'Required fields cannot be empty',
                        type: 'success'
                    });
                }
                else {
                    this.$message({
                        message: '必填项不能为空',
                        type: 'success'
                    });
                }

            }

        },
        getHouseId(data) {
            console.log('父组件接收到的数据：' + data)
            this.houseid = data
            this.$router.push({ name: 'HouseDetail', params: { houseid: this.houseid.houseid, house_stats: this.houseid.house_stats } })
        },
        getpage(data) {
            console.log('父组件接收到的数据：' + data)
            console.log('first', this.housedetal.id, this.houseid.house_stats)
            if (data) {
                this.page = this.page + 1

                this.gethouseSimi(this.housedetal.id, this.houseid.house_stats)
            }

        },
        open1() {
            this.$notify({
                title: '成功',
                message: '这是一条成功的提示消息',
                type: 'success'
            });
        },
        share() {

            var fullUrl = window.location.href;
            console.log(fullUrl);
            var input = document.createElement('input') // 创建input标签
            input.value = fullUrl; // 将input的值设置为需要复制的内容
            document.body.appendChild(input) // 添加input标签
            input.select()  // 选中input标签
            document.execCommand('copy') // 执行复制
            // this.$message.success('success!') // 成功提示信息
            document.body.removeChild(input) // 移除input标签

            this.$notify({
                title: 'Copy successful',
                message: fullUrl,
                type: 'success'
            });
        },
        gettel(tel) {


            var input = document.createElement('input') // 创建input标签
            input.value = tel; // 将input的值设置为需要复制的内容
            document.body.appendChild(input) // 添加input标签
            input.select()  // 选中input标签
            document.execCommand('copy') // 执行复制
            // this.$message.success('success!') // 成功提示信息
            document.body.removeChild(input) // 移除input标签

            this.$notify({
                title: 'Copy successful',
                message: tel,
                type: 'success'
            });
        },
        async getdatail(id, house_stats) {
            this.house_stats = house_stats
            if (house_stats == 1) {
                console.log('1111111111111111111111111111', house_stats)
                await houseDetail({
                    house_id: id
                }).then(res => {
                    this.housedetal = res.data
                    console.log(this.housedetal)
                })
            }
            else {
                console.log('2222222222222222222222222222', house_stats)

                await renthouseDetail({
                    house_id: id
                }).then(res => {
                    this.housedetal = res.data
                    console.log(this.housedetal)
                })
            }

        },
        async gethouseSimi(id, house_stats) {
            if (house_stats == 2) {
                await renthouseSimi({
                    page: this.page,
                    house_id: id
                }).then(res => {
                    var pagelist = []
                    pagelist = res.data.house_list
                    if (this.page == 1) {
                        this.list = pagelist
                    }
                    else {
                        this.list = this.list.concat(pagelist)
                    }

                    console.log(this.housedetal)
                })

            }
            else {
                await houseSimi({
                    page: this.page,
                    house_id: id
                }).then(res => {
                    var pagelist = []
                    pagelist = res.data.house_list
                    if (this.page == 1) {
                        this.list = pagelist
                    }
                    else {
                        this.list = this.list.concat(pagelist)
                    }

                    console.log(this.housedetal)
                })
            }

        },
        async downloadFile(url, name) {
            // 创建一个隐藏的<a>标签
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.download = name + '.pdf'; // 下载的文件名
            // 将<a>标签添加DOM中
            document.body.appendChild(link);
            // 模拟点击下载链接
            link.click();
            // 删除临时的<a>标签
            document.body.removeChild(link);
        },
        async downloadFile1() {
            var that = this
            this.housedetal.file_arr.forEach(element => {
                that.downloadFile(element.url, element.name)
            });
            // // 创建一个隐藏的<a>标签
            // const link = document.createElement('a');
            // link.href = url;
            // link.target = '_blank';
            // link.download = name + '.pdf'; // 下载的文件名
            // // 将<a>标签添加DOM中
            // document.body.appendChild(link);
            // // 模拟点击下载链接
            // link.click();
            // // 删除临时的<a>标签
            // document.body.removeChild(link);
        },

        async putenquiry() {
            this.putenquiryshow = true
        },
        async putenquiry1() {
            console.log(this.enquiry)

            if (this.enquiry.First_name && this.enquiry.Last_name && this.enquiry.Phone && this.enquiry.Email) {
                await houseConsult({
                    house_stats: this.house_stats,
                    house_id: this.housedetal.id,
                    name: this.enquiry.First_name, //名
                    surname: this.enquiry.Last_name, //姓
                    mobile: this.enquiry.Phone, //手机号

                    email: this.enquiry.Email, //邮箱
                    content: this.enquiry.Message //咨询内容
                }).then(res => {
                    console.log('res', res)
                    if (res.code == 1) {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        // this.zixunshow = false
                        this.enquiry = {
                            First_name: '',
                            Last_name: '',
                            Phone: '',
                            Email: '',
                        }
                        console.log(res, 'resresresres')
                    }

                    console.log('res', res)
                })

            }
            else {
                if (this.$i18n.locale == 'en') {
                    this.$message({
                        message: 'Required fields cannot be empty',
                        type: 'success'
                    });
                }
                else {
                    this.$message({
                        message: '必填项不能为空',
                        type: 'success'
                    });
                }

            }
        },
        handlerChange(e) {
            console.log(e)
            // this.Mortgage.Price = this.Mortgage.Price ? this.Mortgage.Price : 1
            // this.Mortgage.Loan_term = this.Mortgage.Loan_term ? this.Mortgage.Loan_term : 1
            // this.Mortgage.Deposit = this.Mortgage.Deposit ? this.Mortgage.Deposit : 1
            // this.Mortgage.Interest_rate = this.Mortgage.Interest_rate ? this.Mortgage.Interest_rate : 1
            // var value
            // this.Mortgageprice = ((this.Mortgage.Price * (1 - this.Mortgage.Loan_term * 0.01)) * (1 + this.Mortgage.Interest_rate)) / this.Mortgage.Deposit * 12 ? ((this.Mortgage.Price * (1 - this.Mortgage.Loan_term * 0.01)) * (1 + this.Mortgage.Interest_rate)) / this.Mortgage.Deposit * 12 : 0
        },
        handlerChange1(e) {

            console.log(e)
            console.log('this.q1', this.Stamp.q1)
            console.log('this.q1', this.Stamp.q2)
            if (this.Stamp.Property_value <= 425000 && this.Stamp.q1) {

                if (this.Stamp.q2) {
                    this.Stampprice = 0
                }
                else {
                    this.Stampprice = this.Stamp.Property_value * 0.02
                }

            }
            else if (this.Stamp.Property_value <= 625000 && this.Stamp.Property_value > 425000 && this.Stamp.q1) {
                if (this.Stamp.q2) {
                    console.log('11')
                    this.Stampprice = (this.Stamp.Property_value - 425000) * 0.05

                }
                else {
                    this.Stampprice = (this.Stamp.Property_value - 425000) * 0.05 + this.Stamp.Property_value * 0.02
                }

            }
            else if (this.Stamp.Property_value <= 925000 && this.Stamp.Property_value > 625000 && this.Stamp.q1) {
                if (this.Stamp.q2) {
                    this.Stampprice = 7500 + (this.Stamp.Property_value - 250000) * 0.08

                }
                else {
                    this.Stampprice = 7500 + (this.Stamp.Property_value - 250000) * 0.08 + this.Stamp.Property_value * 0.02
                }
            }
            else if (this.Stamp.Property_value <= 1500000 && this.Stamp.Property_value > 925000 && this.Stamp.q1) {
                if (this.Stamp.q2) {
                    this.Stampprice = 61500 + (this.Stamp.Property_value - 925000) * 0.13

                }
                else {
                    this.Stampprice = 61500 + (this.Stamp.Property_value - 925000) * 0.13 + this.Stamp.Property_value * 0.02
                }
            }
            else if (this.Stamp.Property_value > 1500000 && this.Stamp.q1) {
                if (this.Stamp.q2) {
                    this.Stampprice = 134250 + (this.Stamp.Property_value - 1500000) * 0.15

                }
                else {
                    this.Stampprice = 134250 + (this.Stamp.Property_value - 1500000) * 0.15 + this.Stamp.Property_value * 0.02
                }
            }
            else if (this.Stamp.Property_value <= 250000 && !this.Stamp.q1) {

                if (this.Stamp.q2) {
                    this.Stampprice = this.Stamp.Property_value * 0.03
                }
                else {
                    this.Stampprice = this.Stamp.Property_value * 0.03 + this.Stamp.Property_value * 0.02
                }

            }
            else if (this.Stamp.Property_value <= 925000 && this.Stamp.Property_value > 250000 && !this.Stamp.q1) {
                if (this.Stamp.q2) {
                    this.Stampprice = 7500 + (this.Stamp.Property_value - 250000) * 0.08

                }
                else {
                    this.Stampprice = 7500 + (this.Stamp.Property_value - 250000) * 0.08 + this.Stamp.Property_value * 0.02
                }
            }
            else if (this.Stamp.Property_value <= 1500000 && this.Stamp.Property_value > 925000 && !this.Stamp.q1) {
                if (this.Stamp.q2) {
                    this.Stampprice = 61500 + (this.Stamp.Property_value - 925000) * 0.13

                }
                else {
                    this.Stampprice = 61500 + (this.Stamp.Property_value - 925000) * 0.13 + this.Stamp.Property_value * 0.02
                }
            }
            else if (this.Stamp.Property_value > 1500000 && !this.Stamp.q1) {
                if (this.Stamp.q2) {
                    this.Stampprice = 134250 + (this.Stamp.Property_value - 1500000) * 0.15

                }
                else {
                    this.Stampprice = 134250 + (this.Stamp.Property_value - 1500000) * 0.15 + this.Stamp.Property_value * 0.02
                }
            }





            // this.isRewiriteEditor = true;
        },




    },

    filters: {

    },
    async created() {
        console.log(this.houseid, 'this.houseidthis.houseidthis.houseidthis.houseid')
        this.getimgtext()
        // await Gethoursedetail({
        //     id: this.houseid
        // }).then(res => {
        //     this.housedetal = res.data.data
        //     console.log(this.housedetal)
        // })



    }
}

</script>

<style scoped>
.w20{
    width: 20px;
    height: 20px;
}

.flie1 {
    width: 205px;
    height: 42px;
    border: 1px solid #DDDDDD;
    text-align: center;
    line-height: 42px;
    margin-top: 20px;
    cursor: pointer;
}

.files11 {

    width: 55%;
    margin-right: 20px;
    margin-top: 40px;
    margin-bottom: 40px;

}

.types {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px auto;
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
    padding: 40px auto;

}

.type {
    margin-top: 20px;
    margin-bottom: 20px;
}

.type_num {
    margin-top: 10px;

    font-weight: 500;
    font-size: 18px;
    color: #111111;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.w20 {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.w21 {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.w88 {
    width: 88px;
    height: 88px;
}

.image1 {
    width: 88px;
    height: 88px;
    text-align: center;
    line-height: 88px;
    background: #F3F4F6;
    margin-bottom: 20px;
    border-radius: 4px 4px 4px 4px;
}


.image1 {
    cursor: pointer;
    margin-right: 10px;
}

@media (min-width: 801px) {
    .tablist {
        /* margin: 20px 280px; */
    }
    .filess {
display: flex;
align-items: center;
border-bottom: 1px solid #D8D8D8;
}

    .w32 {
        width: 32px;
        height: 32px;
    }
    
.images {
    /* margin-bottom: 20px; */
    display: grid;
    width: 100%;
    text-align: center;
    grid-template-columns: repeat(4, 1fr);

}

}

@media (max-width: 800px) {
    .filess {

border-bottom: 1px solid #D8D8D8;
}
.images {
    /* margin-bottom: 20px; */
    display: grid;
    width: 100%;
    text-align: center;
    grid-template-columns: repeat(3, 1fr);

}
    .w32 {
        width: 32px;
        height: 32px;
    }

    .detail {
        margin: 20px;
    }

    .w920 {
        width: 100%;
        height: 100%;
    }

    .map {
        width: 100%;
        height: 306px;
        border-radius: 0px 0px 0px 0px;
        background: #DDDDDD;
    }

    .price {
        margin-top: 20px;
        margin-bottom: 20px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size:20px;
        color: #111111;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .d_l_m_t {
        margin-top: 10px;
        margin-bottom: 10px;
        justify-content: space-between;
        width: 100%;
    }

    .d_l_m_t1 {
        margin: 26px 0;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #333333;
        line-height: 26px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .bottons {
        justify-content: space-between;
    }

    .botton1 {
        width: 30%;
        padding: 10px;
        cursor: pointer;
        text-align: center;
        margin-right: 20px;
        background: #F5F5F5;
        border-radius: 8px 8px 8px 8px;
        align-items: center;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 500;
        font-size: 20px;
        color: #111111;
        /* line-height: 48px; */

        font-style: normal;
        text-transform: none;
    }

    .box_t {
        width: 100%;
        align-items: center;
        justify-content: space-between;

        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 18px;
        color: #111111;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .d_l_m_t2 {
        margin-top: 40px;
        margin-bottom: 18px;
        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 18px;
        color: #111111;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .d_l_d_box {
        width: 85%;
        /* height: 419px; */
        background: #F7FBFF;
        border: 1px solid #DDDDDD;
    }

    .input_input1 {
        width: 70%;

    }

    .input_input2 {
        width: 90%;

    }

    .input_input3 {
        width:100%;


    }

    .box_bottom {
        text-align: center;
    }

    .box_bottom_t {
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .box_bottom_text1 {

        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 40px;
        color: #111111;
        line-height: 40px;
        /* text-align: left; */
        font-style: normal;
        text-transform: none;
    }

    .box_bottom_text2 {

        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #888888;
        line-height: 24px;
        /* text-align: left; */
        font-style: normal;
        text-transform: none;
    }

    .detail_r {
        /* width: 380px; */
        height: 100%;

        background: #F7FBFF;
        border: 1px solid #E5E5E5;
    }

    .detail_r1 {
        /* width: 380px; */
        height: 100%;

        background: #F7FBFF;
        border: 1px solid #E5E5E5;
    }

    .d_title {

        font-family: Alibaba PuHuiTi 3.0, Alibaba PuHuiTi 30;
        font-weight: 800;
        font-size: 14px;
        color: #111111;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .d_image {
        width: 100px;
        height: 123px;
        border-radius: 0px 0px 0px 0px;
    }

    .d_image1 {
        width: 60px;
        height: 74px;
    }

    .name1 {

        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 16px;
        color: #111111;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .name2 {

        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .name3 {

        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #888888;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .tell {
        font-weight: 600;
        font-size: 16px;
        color: #1495FF;

        letter-spacing: 1px;
        text-align: left;
    }

    .call {
        font-weight: 600;
        font-size: 16px;
        /* color: #1495FF; */
        letter-spacing: 1px;
        text-align: left;
    }

    :deep .el-checkbox__label {
        white-space: pre-wrap !important;
    }

    .texttitle {
        margin: 20px auto;
        width: 217px;
        height: 24px;
        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 20px;
        color: #111111;
        /* line-height: 24px; */
        text-align: center;
        font-style: normal;
        text-transform: none;
    }

}

/* 针对桌面端的样式 */
@media (min-width: 801px) {
    ::v-deep .el-dialog {
        width: 80% !important;
    }

    .texttitle {
        margin: 20px auto;
        width: 217px;
        height: 24px;
        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 20px;
        color: #111111;
        /* line-height: 24px; */
        text-align: center;
        font-style: normal;
        text-transform: none;
    }

    .w1000 {
        width: 65%;
        height: auto;
    }

    .box_input {
        width: 45%;
    }

    .detail {
        margin: 20px 100px;
    }

    .w920 {
        width: 95%;
        height: 100%;
    }

    .map {
        width: 100%;
        height: 306px;
        border-radius: 0px 0px 0px 0px;
        background: #DDDDDD;
    }

    .price {
        margin-top: 10px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 20px;
        color: #111111;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .d_l_m_t {
        margin-top: 20px;
        justify-content: space-between;
        width: 100%;
    }

    .d_l_m_t1 {
        margin: 26px 0;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #333333;
        line-height: 26px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .bottons {
        justify-content: space-between;
    }

    .botton1 {
        cursor: pointer;
        padding: 10px;
        text-align: center;
        margin-right: 20px;
        background: #F5F5F5;
        border-radius: 8px 8px 8px 8px;
        align-items: center;
        font-style: normal;
        text-transform: none;
        margin-top: 10px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 20px;
        color: #111111;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .box_t {

        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 18px;
        color: #111111;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .d_l_m_t2 {
        margin-top: 40px;
        margin-bottom: 18px;
        font-family: Montserrat, Montserrat;
        font-weight: 700;
        font-size: 18px;
        color: #111111;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .d_l_d_box {
        width: 100%;

        /* height: 419px; */
        background: #F7FBFF;
        border: 1px solid #DDDDDD;
    }

    .input_input1 {
        /* width: 45%; */

    }

    .input_input2 {
        width: 100%;

    }

    .input_input3 {
        width: 90%;


    }

    .box_bottom {
        text-align: center;
    }

    .box_bottom_t {
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .box_bottom_text1 {

        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 40px;
        color: #111111;
        line-height: 40px;
        /* text-align: left; */
        font-style: normal;
        text-transform: none;
    }

    .box_bottom_text2 {

        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #888888;
        line-height: 24px;
        /* text-align: left; */
        font-style: normal;
        text-transform: none;
    }

    .detail_l {
        width: 70%;
    }

    .detail_r {
        width: 25%;
        height: 100%;

        background: #F7FBFF;
        border: 1px solid #E5E5E5;
    }

    .detail_r1 {
        width: 26%;
        height: 100%;

        background: #F7FBFF;
        border: 1px solid #E5E5E5;
    }

    .d_title {

        font-family: Alibaba PuHuiTi 3.0, Alibaba PuHuiTi 30;
        font-weight: 800;
        font-size: 18px;
        color: #111111;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .d_image {
        width: 100px;
        height: 123px;
        border-radius: 0px 0px 0px 0px;
    }

    .d_image1 {
        width: 60px;
        height: 74px;
    }

    .name1 {

        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 16px;
        color: #111111;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .name2 {

        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .name3 {

        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #888888;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .tell {
        font-weight: 600;
        font-size: 16px;
        color: #1495FF;

        letter-spacing: 1px;
        text-align: left;
    }

    .call {
        font-weight: 600;
        font-size: 16px;
        /* color: #1495FF; */

        letter-spacing: 1px;
        text-align: left;
    }

    :deep .el-checkbox__label {
        white-space: pre-wrap !important;
    }


}
</style>
